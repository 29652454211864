import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-rating-reason-dialog',
  templateUrl: './rating-reason-dialog.component.html',
  styleUrls: ['./rating-reason-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RatingReasonDialogComponent implements OnInit {
  forWhom;
  ratingData = [];
  alias;
  reason;
  constructor(public mdRef: MatDialogRef<RatingReasonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public md : MatDialog) { 
    this.forWhom = this.data.forWhom;
    this.sortingThroughRatingDate(this.data.ratingList);
    // this.ratingData = this.data.ratingList.reverse();
    this.alias = this.data.userAlias;
    this.reason = this.data.reason;
  }

  ngOnInit(): void {
  }

  sortingThroughRatingDate(ratingArray){
    this.ratingData = ratingArray.sort((b,a) => {
      if(a.timestamp < b.timestamp){
        return -1;
      } if(a.timestamp > b.timestamp){
        return 1;
      } 
      return 0;
    });
  
}

closeDialog() {
  this.mdRef.close();
}

}
