import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PreferencesComponent implements OnInit {
  displayData = [];
  prefsData: any = {
    age: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    marital: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    location: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    height: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
      high : null,
      low : null
    },
    educationLevel: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    language: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    religion: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    approchToFood: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    smoking: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    drinking: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    managedby: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    withPhotos: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    degrees: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    occupation: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    socioEconomic: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    hasChildren: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    wantMoreChildren: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    bodyType: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    bodyPiercings: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    tattoos: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    communityCaste: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    ethnicNationalOrigin: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    horoscopeMatching: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    grewUpIn: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    dietary: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    exerciseHabits: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    },
    profileManagedBy: {
      acceptable: {},
      ideal: {},
      importance: null,
      strict: null,
    }

  };
  skelentonView = true;
  constructor(private apiservice: ApiService, private sharedservice: SharedService) { }

  ngOnInit(): void {
    const apiparams = Number(window.location.search.substr(1).split('userid=')[1]);
    this.apiservice.getuserpreferences(apiparams).subscribe(async result => {
      if (result) {

        await this.prepareData(result.preferences);
        this.skelentonView = false;
      }

    })
  }

  navigateToUserview() {
    const url = '/userview/' + this.sharedservice.userId + '/preferences';
    window.open(url, '_blank');

  }

  prepareData(apiData) {
    // age
    if (apiData.age) {
      this.prefsData.age.acceptable.high = apiData.age.acceptable ? apiData.age.acceptable.high : null;
      this.prefsData.age.acceptable.low = apiData.age.acceptable ? apiData.age.acceptable.low : null;
      this.prefsData.age.ideal.high = apiData.age.ideal ? apiData.age.ideal.high : null;
      this.prefsData.age.ideal.low = apiData.age.ideal ? apiData.age.ideal.low : null;
      this.prefsData.age.importance = apiData.age.importance;
      this.prefsData.age.strict = apiData.age.strict;
    } else {
      this.prefsData.age.acceptable.high = 60; //this is default age just for data showing
      this.prefsData.age.acceptable.low = 20;
      this.prefsData.age.strict = false;
    }


    // marital
    if (apiData.marital) {
      this.prefsData.marital.acceptable = apiData.marital.acceptable ? apiData.marital.acceptable : null;
      this.prefsData.marital.ideal = apiData.marital.ideal ? apiData.marital.ideal : null;
      this.prefsData.marital.importance = apiData.marital.importance;
      this.prefsData.marital.strict = apiData.marital.strict;
    } else {
      this.prefsData.marital.ideal = ['Doesn’t matter'];
      this.prefsData.marital.strict = false;
    }

    // location
    if (apiData.location) {
      this.prefsData.location.acceptable = apiData.location.acceptable ? apiData.location.acceptable : null;
      this.prefsData.location.ideal = apiData.location.ideal ? apiData.location.ideal : null;
      this.prefsData.location.importance = apiData.location.importance;
      this.prefsData.location.strict = apiData.location.strict;
    } else {
      this.prefsData.location.ideal = ['Doesn’t matter'];
      this.prefsData.location.strict = false;
    }

    // height
    if (apiData.height) {
      this.prefsData.height.acceptable.high = apiData.height.acceptable ? apiData.height.acceptable.high : null;
      this.prefsData.height.acceptable.low = apiData.height.acceptable ? apiData.height.acceptable.low : null;
      this.prefsData.height.ideal.high = apiData.height.ideal ? apiData.height.ideal.high : null;
      this.prefsData.height.ideal.low = apiData.height.ideal ? apiData.height.ideal.low : null;
      if (this.prefsData.height.ideal.high != null) {
        this.prefsData.height.ideal.high = this.sharedservice.getConvertedHeight(this.prefsData.height.ideal.high);
      }
      if (this.prefsData.height.ideal.low != null) {
        this.prefsData.height.ideal.low = this.sharedservice.getConvertedHeight(this.prefsData.height.ideal.low);
      }
      if (this.prefsData.height.acceptable.high != null) {
        this.prefsData.height.acceptable.high = this.sharedservice.getConvertedHeight(this.prefsData.height.acceptable.high);
        this.prefsData.height.high = apiData.height.acceptable ? apiData.height.acceptable.high : null;
      }
      if (this.prefsData.height.acceptable.low != null) {
        this.prefsData.height.acceptable.low = this.sharedservice.getConvertedHeight(this.prefsData.height.acceptable.low);
        this.prefsData.height.low = apiData.height.acceptable ? apiData.height.acceptable.low : null;
      }
      this.prefsData.height.importance = apiData.height.importance;
      this.prefsData.height.strict = apiData.height.strict;
    } else {
      this.prefsData.height.acceptable.high = '7ft – 213cm or more';
      this.prefsData.height.acceptable.low = '4ft 6in – 137cm or less';
      this.prefsData.height.low = 137;
      this.prefsData.height.high = 213;
      this.prefsData.height.strict = false;
    }

    // education level
    if (apiData.edulevel) {
      this.prefsData.educationLevel.acceptable = apiData.edulevel.acceptable ? apiData.edulevel.acceptable : null;
      this.prefsData.educationLevel.ideal = apiData.edulevel.ideal ? apiData.edulevel.ideal : null;
      this.prefsData.educationLevel.importance = apiData.edulevel.importance;
      this.prefsData.educationLevel.strict = apiData.edulevel.strict;
    } else {
      this.prefsData.educationLevel.ideal = 'Doesn’t matter';
      this.prefsData.educationLevel.strict = false;
    }

    // language
    if (apiData.language) {
      this.prefsData.language.acceptable = apiData.language.acceptable ? apiData.language.acceptable : null;
      this.prefsData.language.ideal = apiData.language.ideal ? apiData.language.ideal : null;
      this.prefsData.language.importance = apiData.language.importance;
      this.prefsData.language.strict = apiData.language.strict;
    } else {
      this.prefsData.language.ideal = ['Doesn’t matter'];
      this.prefsData.language.strict = false;
    }

    // religion
    if (apiData.religion) {
      this.prefsData.religion.acceptable = apiData.religion.acceptable ? apiData.religion.acceptable : null;
      this.prefsData.religion.ideal = apiData.religion.ideal ? apiData.religion.ideal : null;
      this.prefsData.religion.importance = apiData.religion.importance;
      this.prefsData.religion.strict = apiData.religion.strict;
    } else {
      this.prefsData.religion.ideal = ['Doesn’t matter'];
      this.prefsData.religion.strict = false;
    }

    // approchToFood
    if (apiData.foodapproach) {
      this.prefsData.approchToFood.acceptable = apiData.foodapproach.acceptable ? apiData.foodapproach.acceptable : null;
      this.prefsData.approchToFood.ideal = apiData.foodapproach.ideal ? apiData.foodapproach.ideal : null;
      this.prefsData.approchToFood.importance = apiData.foodapproach.importance;
      this.prefsData.approchToFood.strict = apiData.foodapproach.strict;
    } else {
      this.prefsData.approchToFood.ideal = ['Doesn’t matter'];
      this.prefsData.approchToFood.strict = false;
    }

    // smoking
    if (apiData.smoking) {
      this.prefsData.smoking.acceptable = apiData.smoking.acceptable ? apiData.smoking.acceptable : null;
      this.prefsData.smoking.ideal = apiData.smoking.ideal ? apiData.smoking.ideal : null;
      this.prefsData.smoking.importance = apiData.smoking.importance;
      this.prefsData.smoking.strict = apiData.smoking.strict;
    } else {
      this.prefsData.smoking.ideal = ['Doesn’t matter'];
      this.prefsData.smoking.strict = false;
    }

    // drinking
    if (apiData.drinking) {
      this.prefsData.drinking.acceptable = apiData.drinking.acceptable ? apiData.drinking.acceptable : null;
      this.prefsData.drinking.ideal = apiData.drinking.ideal ? apiData.drinking.ideal : null;
      this.prefsData.drinking.importance = apiData.drinking.importance;
      this.prefsData.drinking.strict = apiData.drinking.strict;
    } else {
      this.prefsData.drinking.ideal = ['Doesn’t matter'];
      this.prefsData.drinking.strict = false;
    }

    // managedby
    if (apiData.managedby) {
      this.prefsData.managedby.acceptable = apiData.managedby.acceptable ? apiData.managedby.acceptable : null;
      this.prefsData.managedby.ideal = apiData.managedby.ideal ? apiData.managedby.ideal : null;
      this.prefsData.managedby.importance = apiData.managedby.importance;
      this.prefsData.managedby.strict = apiData.managedby.strict;
    } else {
      this.prefsData.managedby.ideal = ['Doesn’t matter'];
      this.prefsData.managedby.strict = false;
    }

    
    // socioEconomic
    if (apiData.socieoeconomic) {
      this.prefsData.socioEconomic.acceptable = apiData.socieoeconomic.acceptable ? apiData.socieoeconomic.acceptable : null;
      this.prefsData.socioEconomic.ideal = apiData.socieoeconomic.ideal ? apiData.socieoeconomic.ideal : null;
      this.prefsData.socioEconomic.importance = apiData.socieoeconomic.importance;
      this.prefsData.socioEconomic.strict = apiData.socieoeconomic.strict;
    } else {
      this.prefsData.socioEconomic.ideal = ['Doesn’t matter'];
      this.prefsData.socioEconomic.strict = false;
    }

    
    
    

    this.displayData.push(this.prefsData);

  }

}
