<div class="row mx-0">
    <div class="col-12 px-0">


        <div class="row mx-0 float-right" style="position: relative; right: 36px !important;">
            <button class="" (click)="imageViewCLose()" mat-icon-button style=" color: #06d6a0; "><i
                    class="fa fa-times fa-lg " style="color: #06d6a0; "></i> CLOSE</button>
        </div>
    </div>

    <div class="col-12 px-0 ">
        <div class="row mx-0 ">
            <div class="col-12 px-0 ">
                <div class="text-center " style="margin-top: 8% !important; ">
                    <img id="rotated" class="img-fluid " [src]="fulImageView " alt=" ">
                </div>
                <div class="text-center oswald b-0"
                    style="position: fixed;color:white; font-size: 18px;margin-left:25%;margin-bottom:1%"><span
                        class="mr-2">&#9432;</span>Rotate only happens in the view, doesn't change for the user.</div>
                <div class="row mx-0 float-right b-0" style="position: fixed; right: 9% !important;margin-bottom:1%">
                    <button id="rotate" mat-stroked-button color="accent" (click)="rotateImage()"
                        class="px-4 py-1 j-sec-border c-btn-secondary mr-2 mt-4">ROTATE</button>
                </div>
            </div>
        </div>
    </div>



</div>