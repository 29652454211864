import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements HttpInterceptor {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authService: AuthService
  ) { }

  private static geturl(action: string) {
    return environment.baseUrl + action;
  }

  getToken() {
    if (this.localStorageService.getFromLocal('user')) {
      return this.localStorageService.getFromLocal('user').access_token;
    } else {
      return '';
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        authorization: this.getToken()
      }
    });

    return next.handle(request).pipe(
      // retry on failure
      // retry(2),
      // handle errors
      // tslint:disable-next-line: no-shadowed-variable
      catchError(error => {
        // Checking if it is an Authentication Error (401)
        if (error.status === 401) {
          // <Log the user out of your application code>
          this.authService.logOut();
          this.router.navigate(['/login']);
          return throwError(error);
        } else if (error.status >= 500) {
          this.authService.gettingServerError();
          return throwError(error);
        }

        // If it is not an authentication error, just throw it
        return throwError(error);
      }));
  }

  // login Api from js code
  supportuserlogin = (params: any) => {
    return this.http.post(ApiService.geturl('supportuserlogin'), params);
  }
  // Get User Information
  getUserProfile = (params: any) => {
    return this.http.get(ApiService.geturl('getuserprofile?userid=') + params.userid + '&skip=0');
  }

  // for user table information
  activeUser = (params: any) => {
    return this.http.get(ApiService.geturl('activeUser?skip=' + params.skip + '&orderby=' + params.orderby + '&type=' + params.type + '&from=' + params.from + '&to=' + params.to));
  }

  // for call to user 
  clicktocall = (params: any) => {
    return this.http.get(ApiService.geturl('clicktocall?userid=') + params);
  }

  // for searching user based node/email/alias/phoneNumber/firstName
  getspecificuserdetail = (params: any) => {
    return this.http.get(ApiService.geturl('getspecificuserdetail' + params));
  }

  // Get User Unmoderated/moderated Data
  getunmoderatedprofilesdata = (params: any) => {
    //  console.log(params);

    if (params.userids) {
      return this.http.get(ApiService.geturl('getunmoderatedprofilesdata?limit=' + params.limit + '&skip=' + params.skip + '&type=' + params.type + '&orderby=' + params.orderby + '&userids=[' + params.userids + ']'));
    } else {
      return this.http.get(ApiService.geturl('getunmoderatedprofilesdata?limit=' + params.limit + '&skip=' + params.skip + '&type=' + params.type + '&orderby=' + params.orderby));
    }
  }

  // searching university name
  searchforuniversity = function (params) {
    return this.http.get(ApiService.geturl('searchforuniversity?searchfragment=') + params.searchfragment + '&sequenceno=' + params.sequenceno);
  };

  // getprofilescannerpoints 
  getprofilescannerpoints = function (params) {
    return this.http.get(ApiService.geturl('getprofilescannerpoints?userid=' + params.userid));
  }

  // get survey Question
  getSurveyAnswers = (params: any) => {
    return this.http.get(ApiService.geturl('getsurveyanswers?userid=') + params);
  }

  //Moderation Notes
  getusernotes = (params: any) => {
    return this.http.post(ApiService.geturl('getusernotes'), params);
  }

  // support Notes 
  makeusernotes = (params: any) => {
    return this.http.post(ApiService.geturl('makeusernotes'), params);
  }

  // npsRating 
  getnpsrating = function (params) {
    if (params.limit) {
      return this.http.get(ApiService.geturl('getnpsrating?skip=' + params.skip + '&limit=' + params.limit));
    } else {
      return this.http.get(ApiService.geturl('getnpsrating?userid=') + params.userid);
    }

  }

  // Moderated University
  unversityModeration = (params: any) => {
    return this.http.post(ApiService.geturl('universityModeration'), params);
  }

    // getsupportuserlist
    getsupportuserlist = () => {
      return this.http.get(ApiService.geturl('getsupportuserlist'));
    }

  // supportuseredit
  supportuseredit = function(params){
    return this.http.post(ApiService.geturl('supportuseredit'), params);
  }

    // kickoutsupportuser
    kickoutsupportuser = function(params){
      return this.http.post(ApiService.geturl('kickoutsupportuser'), params);
    }
  
  // kickoutsupportuser supportuseredit
  supportuseradd = function(params){
    return this.http.post(ApiService.geturl('supportuseradd'), params);
  }

  // edit first name
  editfirstname = function (params) {
    return this.http.post(ApiService.geturl('editfirstname'), params);
  }

  // Moderated Company
  companyModeration = (params: any) => {
    return this.http.post(ApiService.geturl('profileQuestionModeration'), params);
  }


  // Moderated Image and Caption
  imagemoderation = (params: any) => {
    return this.http.post(ApiService.geturl('imagemoderation'), params);
  }

  videoModeration = (params: any) => {
    return this.http.post(ApiService.geturl('videomoderation'), params);
  }

  // is talked user 
  adminverifyphone = (param: any) => {
    return this.http.get(ApiService.geturl('adminverifyphone?userid=') + param);
  }

  // Set Profile Quality
  setProfileQuality = (params: any) => {
    return this.http.post(ApiService.geturl('setProfileQuality'), params);
  }

  // User isAssisted Connect
  isAssistedConnectUser = (params: any) => {
    return this.http.post(ApiService.geturl('isAssistedConnectUser'), params);
  }

  // Probablity to upgrade
  probabilityToUpgrade = (params: any) => {
    return this.http.post(ApiService.geturl('probabilitytoupgrade'), params);
  }

  // User Profile Screening Status
  profileScreeningStatus = (params: any) => {
    return this.http.post(ApiService.geturl('setprofileasfake'), params);
  }

  //Generate Match API
  generatematch = (params: any) => {
    return this.http.post(ApiService.geturl('generatematch'), params);
  }

  // profile deletion 
  profiledeletionaccept = (params: any) => {
    return this.http.delete(ApiService.geturl('profiledeletionaccept?userid=') + params);
  }

  // kickoutuser
  kickoutuser = (params: any) => {
    return this.http.post(ApiService.geturl('kickoutuser'), params);
  }

  // getreportlist
  getreportlist = function (params) {
    return this.http.get(ApiService.geturl('getreportlist?userid=' + params.userid + '&skip=' + params.skip + '&limit=' + params.limit + '&reportedbyother=' + params.reportedbyother));
  }

  // getsupportedits
  getsupportedits = function (params) {
    return this.http.get(ApiService.geturl('getsupportedits?userid=' + params.userid + '&skip=' + params.skip + '&limit=' + params.limit));
  }

  // getsupportlocation
  getsupportlocation = (params: any) => {
    return this.http.post(ApiService.geturl('getsupportlocation'), params);
  }

  // for email template 
  sendemailtemplate = (params: any) => {
    return this.http.post(ApiService.geturl('sendemailtemplate'), params);
  }

  // getuserpreferences
  getuserpreferences = function (params) {
    return this.http.get(ApiService.geturl('getuserpreferences?userid=') + params);
  }

  // getmembershipdetails
  getmembershipdetails = function (params) {
    return this.http.get(ApiService.geturl('getmembershipdetails?userid=' + params.userid));
  }

  // Email Exist
  doesemailexist = function (params: any) {
    return this.http.post(ApiService.geturl('doesemailexist'), params);
  };

  // Phone number Exist
  doesphoneexist = function (params: any) {
    return this.http.post(ApiService.geturl('doesphoneexist'), params);
  };

  // updateuserdetails
  updateuserdetails = function (params: any) {
    return this.http.post(ApiService.geturl('updateuserdetails'), params);
  }

  // get other user details Info
  getmatcheduser = (params: any) => {
    return this.http.get(ApiService.geturl('getmatcheduser?userid=' + params.userId + '&type=' + params.type + '&skip=' + params.skip + '&whom=self'));
  }

  getonlinusers = function() {
    return this.http.get(ApiService.geturl('dashboard'));
  }
}