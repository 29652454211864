<div class="row mx-0">
    <div class="col-11 px-0 mx-auto pt-4" *ngIf="!skelentonView">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <span class="oswald j-h3 c-darkgray float-left pt-2">Preferences with free strict filters</span>
                <button mat-button class="float-right edit-button-class" (click)="navigateToUserview()">EDIT</button>
            </div>
            <div class="col-12 px-0 pt-4" *ngFor="let item of displayData">
                <!-- age -->
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Age</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0">
                            <div class="col-12 px-0 pl-3 py-2 text-left c-darkgray">
                                <mat-slider class="min-slider custom-slider-low" [value]="item.age.acceptable.low"
                                    [disabled]="true" min="18" max="item.age.acceptable.high" step="1">
                                </mat-slider>
                                <mat-slider class="min-slider custom-slider-high" style="position: absolute; left: 0%;"
                                    [value]="item.age.acceptable.high"
                                    [disabled]="true" min="item.age.acceptable.low" max="88" step="1">
                                </mat-slider>
                            </div>
                            <div class="col-12 px-0 opensans-light body-subtext c-darkgray" style="margin-top: -65px">
                                <div class="float-left">
                                    {{item.age.acceptable.low}}
                                </div>
                                <div class="float-right">
                                    {{item.age.acceptable.high}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class"
                                style="padding-top: 0px;"><i class="pb-1">Your age preferences will always be exactly
                                    matched.</i> <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.age.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>



                <!-- location -->
                <div class="row mx-0 pt-3">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Location</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <div *ngIf="item.location.ideal == 'Doesn’t matter'">
                                    {{item.location.ideal}}
                                </div>
                                <div *ngIf="item.location.ideal != 'Doesn’t matter'">
                                    <span *ngFor="let locationItem of item.location.ideal; let last = last">
                                        <span *ngIf="locationItem.city">{{locationItem.city}}<span
                                                *ngIf="locationItem.state">,</span> </span>
                                        <span *ngIf="locationItem.state"> {{locationItem.state}}<span
                                                *ngIf="locationItem.country">,</span> </span>
                                        <span> {{locationItem.country}}<span *ngIf="locationItem.city && !last">,
                                            </span></span>
                                    </span>
                                </div>
                                

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.location.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Religious affiliation  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Religious affiliation </span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let religionItem of item.religion.ideal; let last = last">
                                    {{religionItem}}<span *ngIf="!last">, </span>
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.religion.strict"></mat-slide-toggle>
                            </div>
                        </div>

                    </div>
                </div>


                <!-- Language/Community  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Language/Community</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let languageItem of item.language.ideal; let last = last">
                                    {{languageItem}}<span *ngIf="!last">, </span>
                                </span>


                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.language.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Approach to food  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Approach to food</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let foodItem of item.approchToFood.ideal; let last = last">
                                    {{foodItem}}<span *ngIf="!last">, </span>
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.approchToFood.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Preferences with paid strict filters -->
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <span class="oswald j-h3 c-darkgray float-left pt-4">Preferences with paid strict filters</span>
                    </div>
                </div>


                <!-- marital -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Marital status</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let maritalItem of item.marital.ideal; let last = last">
                                    {{maritalItem}}<span *ngIf="!last">, </span>
                                </span>


                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.marital.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- height -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Height</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0">
                            <div class="col-12 px-0 pl-3 py-2 text-left c-darkgray">
                                <mat-slider class="min-slider custom-slider-low" [value]="item.height.low"
                                    [disabled]="true" min="137" max="213" step="1">
                                </mat-slider>
                                <mat-slider class="min-slider custom-slider-high" 
                                    [value]="item.height.high" [disabled]="true"
                                    min="137" max="213" step="1" style="position: absolute; left: 0%;">
                                </mat-slider>
                            </div>
                            <div class="col-12 px-0 opensans-light body-subtext c-darkgray" style="margin-top: -63px">
                                <div class="float-left">
                                    {{item.height.acceptable.low}}
                                </div>
                                <div class="float-right">
                                    {{item.height.acceptable.high}}
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class"
                                style="padding-top: 0px;"><i class="pb-1">Show only matches that exactly meet my
                                    preferences.</i> <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.height.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- edulevel -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Min. education level</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span>
                                    {{item.educationLevel.ideal}}
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.educationLevel.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- socioEconomic -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Socio-economic
                            status</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let socioEconomicItem of item.socioEconomic.ideal; let last = last">
                                    {{socioEconomicItem}}<span *ngIf="!last">, </span>
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.socioEconomic.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Smoking habit  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Smoking habit</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let smokingItem of item.smoking.ideal; let last = last">
                                    {{smokingItem}}<span *ngIf="!last">, </span>
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.smoking.strict"></mat-slide-toggle>
                            </div>
                        </div>

                    </div>
                </div>


                <!-- Drinking habit  -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Drinking habit</span>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let drinkingItem of item.drinking.ideal; let last = last">
                                    {{drinkingItem}}<span *ngIf="!last">, </span>
                                </span>

                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.drinking.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Profile created by -->
                <div class="row mx-0 pt-4">
                    <div class="col-12 px-0">
                        <span class="oswald body-text c-darkgray float-left pt-2">Profile created by
                        </span>
                    </div>
                </div>
                <div class="row mx-0 pb-3">
                    <div class="col-12 px-0">
                        <div class="row mx-0 pt-3">
                            <div
                                class="col-12 px-0 text-left opensans-light body-subtext c-darkgray p-2 data-display-box">
                                <span *ngFor="let managedbyItems of item.managedby.ideal; let last = last">
                                    {{managedbyItems}}<span *ngIf="!last">, </span>
                                </span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 opensans-light c-darkgray small-text text-left toggle-wording-class">
                                <i class="pb-1">Show only matches that exactly meet my preferences.</i>
                                <mat-slide-toggle class="pl-3 pt-1 toggle-class" [disabled]="true"
                                    [(ngModel)]="item.managedby.strict"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- skelenton -->
    <div class="col-12 px-3 pb-5" *ngIf="skelentonView">
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>