import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.css']
})
export class RemoveUserDialogComponent implements OnInit {
  userName;
  userId;
  constructor(public mdRef: MatDialogRef<RemoveUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService, private localService: LocalStorageService, private router: Router, private authService: AuthService, private sharedService: SharedService) {
    this.userName = data.userDetails;
    this.userId = data.userId;
   }

  ngOnInit(): void {
  }

  onCancel() {
    this.mdRef.close();
  }

  kickedOutUser() {
    const params = {
      userid: this.userId
    }
    this.apiService.kickoutsupportuser(params).subscribe(res => {
      this.sharedService.supportUserData = null;
      this.mdRef.close('userkicked');
    }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localService.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.authService.gettingServerError();
      }
    });
  }

}
