import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../services/shared.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  @ViewChild('recaptcha',{static: true}) recaptchaElement: ElementRef | any;
  loginForm: any;
  eyeVisible = false;
  awaitResponse = false;
  myTimerCount = 0;
  timerCheck = false;
  timerStop: any;
  recaptchaResponse = '';
  errorRecaptcha = false;
  checkEmailpattern = false;
  constructor(private fb: UntypedFormBuilder, private apiservice: ApiService, private localservice: LocalStorageService, private sharedservice: SharedService, private router: Router, private authService: AuthService, private http: HttpClient,) { }

  ngOnInit(): void {
   // Create formgroup and formcontrol through form Builder form
   this.loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9!#$%&'*/=?^_`{|}~\+\-]+(?:\.[a-zA-Z0-9!#$%&'*/=?^_`{|}~\+\-]+)*@[a-zA-Z0-9]+([\.][a-z]{2,})+(\.([a-z]{2,})+)*$")]],
    password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(20)])],
    howdidyouhear: ['']
  });
  this.addRecaptchaScript();
  
}


  renderReCaptch() {

    let windowTemp: any = window;
    windowTemp['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : environment.recaptcha_key,
      'callback': (response: any) => {
          // console.log(response);
          this.recaptchaResponse = response;
      }
    });
  }

  timeStart() {
    if (this.myTimerCount === 0) {
      this.timerCheck = true;
    }
    if (this.timerCheck) {
      this.timerStop = setInterval(() => { this.myTimerCount++; }, 800);
    }
  }

  addRecaptchaScript() {
    let windowTemp: any = window;
    windowTemp['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
    (function(d, s, id, obj){
      var js: any, fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
   
  }

  loginSubmit() {

    if (this.loginForm.controls.email.value) {
      this.loginForm.patchValue({
        email: (this.loginForm.controls.email.value).trim()
      });
    } 
    if(this.recaptchaResponse == '' ){
        this.errorRecaptcha = true;
    } else {
      this.errorRecaptcha = false;
    }
    const apiparams = {
      username: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
      recapcha: this.recaptchaResponse,  // howdidyouhear value
      // timer: this.myTimerCount,
    };
    this.apiservice.supportuserlogin(apiparams).subscribe(result => {
      // this.localservice.setToLocal('user', result);
      const res: any = result;
      res.email = apiparams.username;
      this.localservice.setToLocal('user', res);
      if(res.supportUserType == 'supervisor' || res.supportUserType == 'reviewer'){
          this.router.navigate(['/admin/dashboard']);
      } else {
        this.sharedservice.userType.isAdmin = true; 
        this.router.navigate(['/admin-console']);
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      //  console.log(err)
        this.awaitResponse = false;
        if (err.status === 401) {
          console.log('test');
          
          this.loginForm.setErrors({ unauthorised: true });
        } else if (err.status >= 500) {
          this.authService.gettingServerError();
        }
    });
     
  }
  
  onBlurEmailMethod() {
    this.checkEmailpattern = true;
    if (this.loginForm.controls.email.value) {
      this.loginForm.patchValue({
        email: (this.loginForm.controls.email.value).trim()
      });
    }
  }

  patternCheck(){
    this.checkEmailpattern = false;
  }

  onSubmit() {
  //  console.log(this.loginForm.controls.howdidyouhear.value);

    if (this.loginForm.status === 'VALID' && (!this.loginForm.controls.howdidyouhear.value) ) {
      this.awaitResponse = true;
      this.loginSubmit();
    } else {
      this.awaitResponse = false;
    }
  }
}
