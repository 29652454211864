import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedService } from 'src/app/services/shared.service';
declare const $;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {
  queryField: UntypedFormControl = new UntypedFormControl();
  @ViewChild('companyName') newCompanyName;
  @ViewChildren('universityName') newUniversityName;
  @ViewChild('firstname') newFirstName;
  filteredOptions = [];
  //////////////////////////////////////////////////
  ////////////////////////////////////////////
  ////// Reviewer Action:
  ////  1. Declined: Gray
  ////  2. Approved: Purple
  //// Supervisior Action
  ////  1. Approved: Green
  ////  2. Rejectd: Red
  //// No Action : Orange
  ////
  ////////////////////////////////////////////
  ///////////////////////////////////////////////////

  activeTab;
  numeralToWords = ['None', 'One', 'Two', 'Three', '4 or more'];
  assetImagePath = '../../../../assets/images/static/';
  userData: any = {
    basics: [
      {
        icon: '../../../../assets/images/static/age.svg',
        label: 'Age',
        info: null
      },
      {
        icon: '../../../../assets/images/static/seeking-for.svg',
        label: 'Seeking/Ready for',
        info: null
      },
      {
        icon: '../../../../assets/images/static/marital-status.svg',
        label: 'Marital status',
        info: null
      },
      {
        icon: '../../../../assets/images/static/divorced-since.svg',
        label: 'Since',
        info: null
      },
      {
        icon: '../../../../assets/images/static/location.svg',
        label: 'Location',
        info: null
      },
      {
        icon: '../../../../assets/images/static/sun-sign.svg',
        label: 'Sun-sign',
        info: null
      },
      {
        icon: '../../../../assets/images/static/profile-created-by.svg',
        label: 'Profile created by',
        info: null
      },
      {
        icon: '../../../../assets/images/static/height.svg',
        label: 'Height',
        info: null
      },
      {
        icon: '../../../../assets/images/static/highest-level-of-education.svg',
        label: 'Highest level of education',
        info: null
      },
      {
        icon: '../../../../assets/images/static/employment-status.svg',
        label: 'Employment status',
        info: null
      },
      {
        icon: '../../../../assets/images/static/occupation.svg',
        label: 'Occupation',
        info: null
      },
      {
        icon: '../../../../assets/images/static/income.svg',
        label: 'Personal income',
        info: null
      },
      {
        icon: '../../../../assets/images/static/socio-economic-status.svg',
        label: 'Socio-economic status',
        info: null
      },
      {
        icon: '../../../../assets/images/static/religion.svg',
        label: 'Religious affiliation/views',
        info: null
      },
      {
        icon: '../../../../assets/images/static/language.svg',
        label: 'Languages & fluency',
        info: null
      },
      {
        icon: '../../../../assets/images/static/community.svg',
        label: 'Community/caste',
        info: null
      },
      {
        icon: '../../../../assets/images/static/approach-to-food.svg',
        label: 'Approach to food',
        info: null
      },
      {
        icon: '../../../../assets/images/static/smoking-habit.svg',
        label: 'Smoking habit',
        info: null
      },
      {
        icon: '../../../../assets/images/static/drinking-habit.svg',
        label: 'Drink habit',
        info: null
      },
      {
        icon: '../../../../assets/images/static/describe-me.svg',
        label: 'Words that best describe me',
        info: []
      },
      {
        icon: '../../../../assets/images/static/hobbies.svg',
        label: 'My hobbies/Interests',
        info: []
      }





      // {
      //   icon: '../../../../assets/images/static/willing-to-relocate.svg',
      //   label: 'Willing to re-locate',
      //   info: null
      // },
      
      // {
      //   icon: '../../../../assets/images/static/residency-status.svg',
      //   label: 'Residency status',
      //   info: null
      // },
      // {
      //   icon: '../../../../assets/images/static/time-frame.svg',
      //   label: 'Time frame for marriage',
      //   info: null
      // },
      // {
      //   icon: '../../../../assets/images/static/visa-validity.svg',
      //   label: 'Visa Validity',
      //   info: null
      // },
      
      // {
      //   icon: '../../../../assets/images/static/citizen.svg',
      //   label: 'Citizen of',
      //   info: null
      // },
      
      // {
      //   icon: '../../../../assets/images/static/intend-to-reside-permanently.svg',
      //   label: 'Intend to reside permanently in',
      //   info: null
      // },
      // {
      //   icon: '../../../../assets/images/static/number-of-kids.svg',
      //   label: 'Number of kids',
      //   info: null
      // },
      
      // {
      //   icon: '../../../../assets/images/static/kids-living-with-me.svg',
      //   label: 'Kids living with me',
      //   info: null
      // },
      
      
      // {
      //   icon: '../../../../assets/images/static/profile-managed-by.svg',
      //   label: 'Profile managed by',
      //   info: null
      // }
    ],
    appearance: [
      {
        icon: '../../../../assets/images/static/height.svg',
        label: 'Height',
        info: null
      },
      {
        icon: '../../../../assets/images/static/piercing.svg',
        label: 'Piercings',
        info: null
      },
      {
        icon: '../../../../assets/images/static/body-type.svg',
        label: 'Body type',
        info: null
      },
      {
        icon: '../../../assets/images/static/tattos.svg',
        label: 'Tattoos',
        info: null
      }
    ],
    education: [
      {
        icon: '../../../../assets/images/static/highest-level-of-education.svg',
        label: 'Highest level of education',
        info: null
      },
      {
        icon: '../../../../assets/images/static/degree.svg',
        label: 'Degree(s)',
        degreeNCollegeData: [
          {
            degree: null,
            college: null,
          },
          {
            degree: null,
            college: null,
          }
        ],
        // info: null,
        // clgName: null
      },
      // {
      //   icon: '../../../../assets/images/static/degree.svg',
      //   label: 'College(s) / University(s)',
      //   info: null
      // },
      {
        icon: '../../../../assets/images/static/employment-status.svg',
        label: 'Employment status',
        info: null
      },
      {
        icon: '../../../../assets/images/static/occupation.svg',
        label: 'Occupation',
        info: null
      },
      {
        icon: '../../../../assets/images/static/company.svg',
        label: 'Company',
        info: null
      },
      {
        icon: '../../../../assets/images/static/income.svg',
        label: 'Personal income',
        info: null
      },
      {
        icon: '../../../../assets/images/static/socio-economic-status.svg',
        label: 'Socio-economic status',
        info: null
      }
    ],
    socio: [
      {
        icon: '../../../../assets/images/static/grew-up.svg',
        label: 'Grew up in',
        info: null
      },
      {
        icon: '../../../../assets/images/static/religion.svg',
        label: 'Religious affiliation/views',
        info: null
      },
      {
        icon: '../../../../assets/images/static/praying-habit.svg',
        label: 'Praying habit',
        info: null
      },
      {
        icon: '../../../../assets/images/static/language.svg',
        label: 'Languages & fluency',
        info: null
      },
      {
        icon: '../../../../assets/images/static/community.svg',
        label: 'Community/caste',
        info: null
      },
      {
        icon: '../../../../assets/images/static/ethnic-origin.svg',
        label: 'Ethnic/ national origin',
        info: null
      },
      {
        icon: '../../../../assets/images/static/horoscope-matching.svg',
        label: 'Views on horoscope matching',
        info: null
      }
    ],
    lifestyle: [
      {
        icon: '../../../../assets/images/static/desired-more-kids.svg',
        label: 'Desire to have more kids',
        info: null
      },
      {
        icon: '../../../../assets/images/static/planned-living-situation.svg',
        label: 'Planned living situation',
        info: null
      },
      {
        icon: '../../../../assets/images/static/approach-to-food.svg',
        label: 'Approach to food',
        info: null
      },
      {
        icon: '../../../../assets/images/static/dietary-pref.svg',
        label: 'Dietary preferences',
        info: null
      },
      {
        icon: '../../../../assets/images/static/smoking-habit.svg',
        label: 'Smoking habit',
        info: null
      },
      {
        icon: '../../../../assets/images/static/drinking-habit.svg',
        label: 'Drink habit',
        info: null
      },
      {
        icon: '../../../../assets/images/static/exercise-habit.svg',
        label: 'Exercise habit',
        info: null
      }
    ],
    family: [
      {
        icon: '../../../../assets/images/static/family-involvement.svg',
        label: 'Family involvement',
        info: null
      },
      {
        icon: '../../../../assets/images/static/father-occupation.svg',
        label: 'Father\'s occupation',
        info: null
      },
      {
        icon: '../../../../assets/images/static/mother-occupation.svg',
        label: 'Mother\'s occupation',
        info: null
      },
      {
        icon: '../../../../assets/images/static/siblings.svg',
        label: 'Number of siblings',
        info: null
      },
      {
        icon: '../../../../assets/images/static/family-based-in.svg',
        label: 'Family based in',
        info: null
      }
    ],
    aboutMyself: [
      {
        icon: '../../../../assets/images/static/describe-me.svg',
        label: 'Words that best describe me.',
        info: []
      },
      {
        icon: '../../../../assets/images/static/like-accept-or-tolerate.svg',
        label: 'Someone would have to like/accept/tolerate',
        info: []
      },
      {
        icon: '../../../../assets/images/static/hobbies.svg',
        label: 'My hobbies/Interests',
        info: []
      }
    ],
    aboutPatner: [
      {
        icon: '../../../../assets/images/static/looking-for-in-patner.svg',
        label: 'I am looking for someone who is',
        info: []
      },
      {
        icon: '../../../../assets/images/static/incompatible-in-patner.svg',
        label: 'Qualities that would make someone incompatible with me',
        info: []
      },
      {
        icon: '../../../../assets/images/static/qualities-do-not-matter.svg',
        label: 'Quality that does not matter',
        info: []
      }
    ]
  };
  btnObj = {
    directionBtn: this.assetImagePath + 'moderation-direction-arrow.svg',
    edit: this.assetImagePath + 'edit.svg',
    info: this.assetImagePath + 'info.svg',
    tick: {
      orange: this.assetImagePath + 'tick-orange.svg',
      purple: this.assetImagePath + 'tick-purple.svg',
      white: this.assetImagePath + 'tick-white.svg',
      gray: this.assetImagePath + 'tick-gray.svg',
      red: this.assetImagePath + 'tick-red.svg',
      bgGreen: this.assetImagePath + 'tick-bg-green.svg'
    },
    cross: {
      orange: this.assetImagePath + 'cross-orange.svg',
      purple: this.assetImagePath + 'cross-purple.svg',
      white: this.assetImagePath + 'cross-white.svg',
      gray: this.assetImagePath + 'cross-gray.svg',
      green: this.assetImagePath + 'cross-green.svg',
      bgRed: this.assetImagePath + 'cross-bg-red.svg'
    }
  };
  dateFlag = null;
  visaFlag = null;
  moderationFirstname = {
    userFirstName: '',
    approvedBySupervisor: false,
    leftBtnColor: 'orange',
    valueAcceptedBy: null,
    moderationTime: null,
    singleTimemoderation: true,
    firstNameButtonDisable: false
  };
  college = {
    degree: null,
    college: null,
    id: null,
    approvedByReviewer: false,
    declineByReviewer: false,
    approvedBySupervisor: false,
    declineBySupervisior: false,
    leftBtnColor: 'orange',
    rightBtnColor: 'orange',
    valueAcceptedRejectedBy: null,
    moderationTime: null,
    editUniversity: -1,
    isPreApproved: false,
    universityunmoderated: null,
  };
  objTraits = {
    name: '',
    approvedByReviewer: false,
    declineByReviewer: false,
    approvedBySupervisor: false,
    declineBySupervisior: false,
    customized: false,
    color: 'orange'
  };
  moderationData = {
    academics: [],
    company: {
      name: '',
      approvedByReviewer: false,
      declineByReviewer: false,
      approvedBySupervisor: false,
      declineBySupervisior: false,
      leftBtnColor: 'orange',
      rightBtnColor: 'orange',
      valueAcceptedRejectedBy: null,
      moderationTime: null,
    },
    moreAboutMyself: {
      wordsDescribeMe: [],
      myTraits: [],
      myHobbies: [],
    },
    moreAboutPatner: {
      lookingFor: [],
      incompatibleQualites: [],
      doesntMatterQualities: []
    }
  };
  surveyQuestions = [
    {
      question: 'How did you hear about Jodi365? What was your first impression?',
      answer: ''
    },
    {
      question: 'Have you used any other matchmaking site/app/service? What has been your experience, good and bad, with them?',
      answer: ''
    },
    {
      question: 'What is one thing that Jodi365 could do to WOW you?',
      answer: ''
    },
    {
      question: ' We also offer personalized assistance from start to finish. Would you like to learn more?',
      answer: ''
    }
  ];
  ratings = [];
  isReviewer;
  sharedData;
  editCompany = false;
  editFirstName = false;
  editUniversity = -1;
  moderationPanelOpen = true;
  surveyQuestion = false;
  reportConcern = false;
  ratingGivenByUser = false;
  activated = '1';
  allowScroll = true;
  reportListDataByOthers = [];
  reportListDataByME = [];
  skipValueForReportByOther = Number(0);
  skipValueForReportByMe = Number(0);
  showMoreForByOther = false;
  showMoreForByMe = false;
  loadMoreUsedForByOthers = false;
  firstnameLenghtExceed = false;

  constructor(private sharedservice: SharedService, private apiservice: ApiService, private sessionstorage: SessionStorageService, private localservice: LocalStorageService, private router: Router) {
    const user = this.localservice.getFromLocal('user');
    this.sharedservice.userType.isReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.isReviewer = this.sharedservice.userType.isReviewer;
    this.sharedservice.userType.name = user?.firstname;
    this.activeTab = 0;
  }

  ngOnInit(): void {
    this.sharedData = this.sessionstorage.getFromSession('user-' + this.sharedservice.userId + '-unmoderatedData');
    this.getUserProfile();



    this.queryField.valueChanges.pipe(
      debounceTime(100),
    ).subscribe(value => {
      if (value.length == 0) {
        this.filteredOptions = [];
      }
      if (value.length > 2) {
        const apiparams = {
          searchfragment: value,
          sequenceno: 1
        };
        this.apiservice.searchforuniversity(apiparams).subscribe(result => {
          result[0].searchlist.pop();
          this.filteredOptions = result[0].searchlist;
        }, (err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
      }
    });


  }

  activationOnTab(section) {
    $('#t-button').removeClass('active');
    $('#s-button').removeClass('active');
    if (section === 'others') {
      this.showMoreForByOther = false;
      this.reportListDataByOthers = [];
      this.skipValueForReportByOther = 0;
      this.activated = '1';
    } else if (section === 'me') {
      this.showMoreForByMe = false;
      this.skipValueForReportByMe = 0;
      this.reportListDataByME = [];
      this.activated = '2';
    }

  }


  // userview navigation
  navigateToUserview(data) {
    console.log(this.sharedservice.userId);
    const url = '/userview/' + this.sharedservice.userId + '/edit-profile';
    window.open(url, '_blank');


    // this.router.navigate([]).then((result) => {
    //   window.open(url,'_blank');
    // })
  }

  getUserProfile() {
    if (this.sessionstorage.getFromSession('user' + this.sharedservice.userId)) {
      const result = this.sessionstorage.getFromSession('user' + this.sharedservice.userId);
      this.setDataToView(result);
      this.getModerationData();
    } else {
      this.sharedservice.userProfileData.subscribe((result) => {
        if (result) {
          this.setDataToView(result);
          this.getModerationData();
        }
      });
    }
  }

  setDataToView(result) {
    
    // basic
    const userData = result.userdata[0];
    const basic = userData.basics;
    this.userData.basics[0].info = basic.age ? basic.age + ' years' : 'N.A.';
    this.userData.basics[1].info = basic.marriagetimeframe ? basic.marriagetimeframe : 'N.A.';
    this.userData.basics[2].info = basic.maritalstatus ? basic.maritalstatus : 'N.A.';
    this.userData.basics[3].info = basic.maritalsince ? basic.maritalsince <= 9999999999 ? basic.maritalsince : basic.maritalsince : 'Not Applicable';
    this.dateFlag = this.userData.basics[3].info === 'Not Applicable' ? null : this.userData.basics[3].info;
    this.userData.basics[4].info = basic.location?.currentloc ? basic.location.currentloc : 'N.A.';
    // Socio
    const socio = userData.background;
    this.userData.basics[5].info = socio.sunsign ? socio.sunsign : 'N.A.';


    if (basic.whom) {
      if (basic.whom === 'Son/Daughter') {
        this.userData.basics[6].info = 'Parent';
      } else if (userData.basics.whom === 'Nephew/Niece') {
        this.userData.basics[6].info = 'Uncle/Aunt';
      } else if (userData.basics.whom === 'Self') {
        this.userData.basics[6].info = basic.whom;
      } else {
        this.userData.basics[6].info = basic.whom;
      }
    } else {
      this.userData.basics[6].info = 'N.A.';
    }

    // Appearnace
    const app = userData.appearance;
    const height = this.sharedservice.heightData.filter(ele => {
      if (ele.id === app.height) {
        return ele.value;
      }
    });
    this.userData.basics[7].info = height.length > 0 ? height[0].value : 'N.A.';

    // Education & Career
    const edu = userData.education;
    this.userData.basics[8].info = edu.highestedulevel ? edu.highestedulevel : 'N.A.';
    this.userData.basics[9].info = edu.natureofemployment ? edu.natureofemployment : 'N.A';
    this.userData.basics[10].info = edu.currentoccupation ? edu.currentoccupation : 'N.A';
    this.userData.basics[11].info = edu.income ? edu.income : 'N.A';
    this.userData.basics[12].info = edu.socioeconomicstatus ? edu.socioeconomicstatus : 'N.A';
    this.userData.basics[13].info = socio.religion ? socio.religion : 'N.A.';
    this.userData.basics[14].info = socio.languages ? socio.languages : 'N.A.';
    this.userData.basics[15].info = socio.caste ? socio.caste : 'N.A.';

    // lifeStyle
    const lifeStyle = userData.lifestyle;
    this.userData.basics[16].info = lifeStyle.approachtofood ? lifeStyle.approachtofood : 'N.A.';
    this.userData.basics[17].info = lifeStyle.smokefrequency ? lifeStyle.smokefrequency : 'N.A.';
    this.userData.basics[18].info = lifeStyle.drinkfrequency ? lifeStyle.drinkfrequency : 'N.A.';

    // More About Myself
    const aboutYou = userData.hashTags.aboutYou;
    // Words that describe me
    this.userData.basics[19].info = aboutYou?.q1;
    // My hobbies/Interests
    this.userData.basics[20].info = aboutYou?.q3;
  } 
  getModerationData() {
    const apiparams = {
      limit: 1,
      skip: 0,
      type: 12,
      orderby: true,
      userids: this.sharedservice.userId,
    }
    //  console.log(apiparams);

    this.apiservice.getunmoderatedprofilesdata(apiparams).subscribe((result: any) => {
      this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', result);
      this.sharedData = result;
      const mySelf = result?.userdata[0]?.hashTags?.aboutMyself;
      const Patner = result?.userdata[0]?.hashTags?.aboutPartner;
      const company = result?.userdata[0]?.profiledata?.moreabout;
      const university = result?.userdata[0]?.universities;
      const userFirstName = result?.userdata[0]?.user?.prospectfirstname ? result?.userdata[0]?.user?.prospectfirstname : result?.userdata[0]?.user?.firstname;
      if (mySelf) {
        // mySelf.unmoderatedq1.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutMyself.wordsDescribeMe.push({ ...this.objTraits });
        // });
        mySelf.approvedq1.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutMyself.wordsDescribeMe.push({ ...this.objTraits });
        });
        // mySelf.unmoderatedq2.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutMyself.myTraits.push({ ...this.objTraits });
        // });
        mySelf.approvedq2.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutMyself.myTraits.push({ ...this.objTraits });
        });
        // mySelf.unmoderatedq3.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutMyself.myHobbies.push({ ...this.objTraits });
        // });
        mySelf.approvedq3.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutMyself.myHobbies.push({ ...this.objTraits });
        });
      }
      if (Patner) {
        // Patner.unmoderatedq1.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutPatner.lookingFor.push({ ...this.objTraits });
        // });
        Patner.approvedq1.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutPatner.lookingFor.push({ ...this.objTraits });
        });
        // Patner.unmoderatedq2.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutPatner.incompatibleQualites.push({ ...this.objTraits });
        // });
        Patner.approvedq2.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutPatner.incompatibleQualites.push({ ...this.objTraits });
        });
        // Patner.unmoderatedq3.forEach(traits => {
        //   this.objTraits.name = traits;
        //   this.objTraits.customized = true;
        //   this.moderationData.moreAboutPatner.doesntMatterQualities.push({ ...this.objTraits });
        // });
        Patner.approvedq3.forEach(traits => {
          this.objTraits.name = traits;
          this.objTraits.customized = false;
          this.moderationData.moreAboutPatner.doesntMatterQualities.push({ ...this.objTraits });
        });
      }
      if (company.companyname) {
        this.moderationData.company.name = company.companyname;
        if (company.companynameModeratedBy && !company.companynameunmoderated && !company.companynamemoderationfailed) {
          this.moderationData.company.leftBtnColor = 'green';
          this.moderationData.company.rightBtnColor = 'white';
        } else if (company.companynameModeratedBy && !company.companynameunmoderated && company.companynamemoderationfailed) {
          this.moderationData.company.leftBtnColor = 'white';
          this.moderationData.company.rightBtnColor = 'red';
        } else if (company.companynamereviewedBy && company.companynameaction) {
          this.moderationData.company.leftBtnColor = 'purple';
          this.moderationData.company.rightBtnColor = 'white';
        } else if (company.companynamereviewedBy && company.companynameaction === false) {
          this.moderationData.company.leftBtnColor = 'white';
          this.moderationData.company.rightBtnColor = 'gray';
        } else {
          this.moderationData.company.leftBtnColor = 'orange';
          this.moderationData.company.rightBtnColor = 'orange';
        }
        // for supervisor approved
        if (company.companynameModeratedBy && company.companynamemoderatedtime && company.companynamemoderationfailed == null) {
          this.moderationData.company.approvedBySupervisor = company.companynameModeratedBy;
          this.moderationData.company.moderationTime = company.companynamemoderatedtime;
          // const time = new Date(this.moderationData.company.moderationTime);
          this.moderationData.company.valueAcceptedRejectedBy = 'Approved,' + ' By ' + this.moderationData.company.approvedBySupervisor + ' ' + new Date(this.moderationData.company.moderationTime).toLocaleString(undefined, { year: 'numeric', month: 'short', day: '2-digit', weekday: "short", hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });
          this.moderationData.company.valueAcceptedRejectedBy = this.parsedText(this.moderationData.company.valueAcceptedRejectedBy);
        }
        // for superwiser rejected
        else if (company.companynameModeratedBy && company.companynamemoderatedtime && company.companynamemoderationfailed == true) {
          this.moderationData.company.declineBySupervisior = company.companynameModeratedBy;
          this.moderationData.company.moderationTime = company.companynamemoderatedtime;
          // const time = new Date(this.moderationData.company.moderationTime);
          this.moderationData.company.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + this.moderationData.company.declineBySupervisior + ' ' + new Date(this.moderationData.company.moderationTime).toLocaleString(undefined, { year: 'numeric', month: 'short', day: '2-digit', weekday: "short", hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });
          this.moderationData.company.valueAcceptedRejectedBy = this.parsedText(this.moderationData.company.valueAcceptedRejectedBy);
        }
        // for reviewer approved
        else if (company.companynamereviewedBy && company.companynamereviewedtime && company.companynameReviewerReason == null) {
          this.moderationData.company.approvedByReviewer = company.companynamereviewedBy;
          this.moderationData.company.moderationTime = company.companynamereviewedtime;
          // const time = new Date(this.moderationData.company.moderationTime);
          this.moderationData.company.valueAcceptedRejectedBy = 'Approved,' + ' By ' + this.moderationData.company.approvedByReviewer + ' ' + new Date(this.moderationData.company.moderationTime).toLocaleString(undefined, { year: 'numeric', month: 'short', day: '2-digit', weekday: "short", hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });
          this.moderationData.company.valueAcceptedRejectedBy = this.parsedText(this.moderationData.company.valueAcceptedRejectedBy);
        }
        // for reviewer rejected
        else if (company.companynamereviewedBy && company.companynamereviewedtime && company.companynameReviewerReason) {
          this.moderationData.company.declineByReviewer = company.companynamereviewedBy;
          this.moderationData.company.moderationTime = company.companynamereviewedtime;
          // const time = new Date(this.moderationData.company.moderationTime);
          this.moderationData.company.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + this.moderationData.company.declineByReviewer + ' ' + new Date(this.moderationData.company.moderationTime).toLocaleString(undefined, { year: 'numeric', month: 'short', day: '2-digit', weekday: "short", hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });
          this.moderationData.company.valueAcceptedRejectedBy = this.parsedText(this.moderationData.company.valueAcceptedRejectedBy);
        }
      }
      // else {
      //   this.moderationData.company.name = 'N.A.';
      // }
      if (userFirstName) {
        this.moderationFirstname.userFirstName = userFirstName;
        if (result?.userdata[0]?.user?.firstnameunmoderated == null) {
          this.moderationFirstname.singleTimemoderation = result?.userdata[0]?.user?.firstnameunmoderated;
          this.moderationFirstname.approvedBySupervisor = result?.userdata[0]?.user?.firstnamemoderatedby;
          this.moderationFirstname.moderationTime = result?.userdata[0]?.user?.firstnamemoderatedtime;
          this.moderationFirstname.leftBtnColor = 'green';
          this.moderationFirstname.firstNameButtonDisable = true;
          // const time = new Date(this.moderationFirstname.moderationTime);
          this.moderationFirstname.valueAcceptedBy = 'Approved,' + ' By ' + this.moderationFirstname.approvedBySupervisor + ' ' + new Date(this.moderationFirstname.moderationTime).toLocaleString(undefined, { year: 'numeric', month: 'short', day: '2-digit', weekday: "short", hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' });
          this.moderationFirstname.valueAcceptedBy = this.parsedText(this.moderationFirstname.valueAcceptedBy);
        } else {
          this.moderationFirstname.firstNameButtonDisable = false;
          this.moderationFirstname.leftBtnColor = 'orange';
          this.moderationFirstname.singleTimemoderation = result?.userdata[0]?.user?.firstnameunmoderated;
        }
      } else {
        this.moderationFirstname.userFirstName = 'N.A.';
      }
      if (university) {
        university.forEach(clg => {
          //  console.log(clg);
          this.college.degree = clg.educationdegree;
          this.college.college = clg.university;
          this.college.id = clg.relid;
          this.college.editUniversity = -1;
          this.college.universityunmoderated = clg.universityunmoderated;
          if (clg.ModeratedBy == null && clg.ReviewedBy == null && clg.universityunmoderated == null) {
            this.college.isPreApproved = true;
          } else {
            this.college.isPreApproved = false;
          }
          if (clg.ModeratedBy && !clg.universityunmoderated && !clg.universitymoderationfailed) {
            this.college.leftBtnColor = 'green';
            this.college.rightBtnColor = 'white';
          } else if (clg.ModeratedBy && !clg.universityunmoderated && clg.universitymoderationfailed) {
            this.college.leftBtnColor = 'white';
            this.college.rightBtnColor = 'red';
          } else if (clg.ReviewedBy && clg.universityaction) {
            this.college.leftBtnColor = 'purple';
            this.college.rightBtnColor = 'white';
          } else if (clg.ReviewedBy && clg.universityaction === false) {
            this.college.leftBtnColor = 'white';
            this.college.rightBtnColor = 'gray';
          } else {
            this.college.leftBtnColor = 'orange';
            this.college.rightBtnColor = 'orange';
          }
          //  console.log(this.moderationData.academics);
          // for superwiser approved
          if (clg.ModeratedBy && clg.moderatetime && clg.universitymoderationfailed == null) {
            this.college.approvedBySupervisor = clg.ModeratedBy;
            this.college.moderationTime = clg.moderatetime;
            const time = new Date(this.college.moderationTime);
            this.college.valueAcceptedRejectedBy = 'Approved,' + ' By ' + this.college.approvedBySupervisor + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
            this.college.valueAcceptedRejectedBy = this.parsedText(this.college.valueAcceptedRejectedBy);
            // console.log(this.college.valueAcceptedRejectedBy);

          }
          // for superwiser rejected
          else if (clg.ModeratedBy && clg.moderatetime && clg.universitymoderationfailed == true) {
            this.college.declineBySupervisior = clg.ModeratedBy;
            this.college.moderationTime = clg.moderatetime;
            const time = new Date(this.college.moderationTime);
            this.college.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + this.college.declineBySupervisior + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
            this.college.valueAcceptedRejectedBy = this.parsedText(this.college.valueAcceptedRejectedBy);
            // console.log(this.college.valueAcceptedRejectedBy);
          }
          // for reviewer approved
          else if (clg.ReviewedBy && clg.reviewedtime && !clg.universityreason) {
            this.college.approvedByReviewer = clg.ReviewedBy;
            this.college.moderationTime = clg.reviewedtime;
            const time = new Date(this.college.moderationTime);
            this.college.valueAcceptedRejectedBy = 'Approved,' + ' By ' + this.college.approvedByReviewer + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
            this.college.valueAcceptedRejectedBy = this.parsedText(this.college.valueAcceptedRejectedBy);
            // console.log(this.college.valueAcceptedRejectedBy);
          }
          //  for reviewer rejected
          else if (clg.ReviewedBy && clg.reviewedtime && clg.universityreason) {
            this.college.declineByReviewer = clg.ReviewedBy;
            this.college.moderationTime = clg.reviewedtime;
            const time = new Date(this.college.moderationTime);
            this.college.valueAcceptedRejectedBy = 'Rejected,' + ' By ' + this.college.declineByReviewer + ' ' + time.toString().slice(0, 3) + ' , ' + time.toLocaleString();
            this.college.valueAcceptedRejectedBy = this.parsedText(this.college.valueAcceptedRejectedBy);
            console.log(this.college.valueAcceptedRejectedBy);
          }
          this.moderationData.academics.push({ ...this.college });
        });
      }
    });
  }

  parsedText(text) {
    const dom = new DOMParser().parseFromString(
      '<!doctype html><body>' + text, 'text/html');
    const decodedString = dom.body.textContent;
    return decodedString;
  }

  getSurveyAnswers() {
    this.surveyQuestion = !this.surveyQuestion;
    let answers;
    if (this.sessionstorage.getFromSession('user' + this.sharedservice.userId + '-survey')) {
      answers = this.sessionstorage.getFromSession('user' + this.sharedservice.userId + '-survey');
      this.setSurveyAnswer(answers[0]);
    } else {
      this.apiservice.getSurveyAnswers(this.sharedservice.userId).subscribe(result => {
        answers = result[0];
        this.sessionstorage.setToSession('user' + this.sharedservice.userId + '-survey', result);
        this.setSurveyAnswer(answers);
      });
    }
  }

  setSurveyAnswer(answers) {
    const personalassist = answers?.personalassist;
    this.surveyQuestions[0].answer = answers?.howdidyouhear ? answers.howdidyouhear : 'N.A.';
    this.surveyQuestions[1].answer = answers?.experience ? answers.experience : 'N.A.';
    this.surveyQuestions[2].answer = answers?.wowquestion ? answers.wowquestion : 'N.A.';
    this.surveyQuestions[3].answer = personalassist != null ? (personalassist === 'false' || !personalassist ? 'No' : 'Yes') : 'N.A';

  }

  navigateToProfileView(userId) {
    const url = '/admin/profile?userid=' + userId;
    window.open(url, '_blank');

  }

  getReportedConcerns(reportedBy, type) {
    // console.log(this.reportConcern);
    if (type == '1') {
      this.reportConcern = !this.reportConcern;
      this.reportListDataByOthers = [];
      this.reportListDataByME = [];
      this.skipValueForReportByOther = 0;
      this.skipValueForReportByMe = 0;
      this.showMoreForByMe = false;
      this.showMoreForByOther = false;
    }
    const apiParams = {
      userid: this.sharedservice.userId,
      skip: reportedBy == '1' ? this.skipValueForReportByOther : this.skipValueForReportByMe,
      limit: Number(10),
      reportedbyother: reportedBy == '1' ? true : false,
    }
    if (this.reportConcern) {


      this.apiservice.getreportlist(apiParams).subscribe(async result => {
        if (result) {
          if (reportedBy == '1') {
            await this.setReportList(result, reportedBy);
            if (result.length % 10 == 0 && result.length > 0) {
              this.showMoreForByOther = true;
            } else {
              this.showMoreForByOther = false;
            }
          } else {
            await this.setReportList(result, reportedBy);
            if (result.length % 10 == 0 && result.length > 0) {
              this.showMoreForByMe = true;
            } else {
              this.showMoreForByMe = false;
            }
          }

        }
      },
        (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localservice.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            //  this.sharedservice.gettingServerError();
          }
        });
    }



  }

  loadMoreData(reportedBy, type) {
    if (reportedBy == '1') {
      this.skipValueForReportByOther = this.skipValueForReportByOther + 10;
    } else {
      this.skipValueForReportByMe = this.skipValueForReportByMe + 10;
    }
    this.getReportedConcerns(reportedBy, type);
  }

  setReportList(apiData, type) {
    let tempData: any = {};
    for (const i in apiData) {
      tempData = {};
      tempData.reasonSplit = [];
      tempData.reason = apiData[i].reason;
      for (const i in tempData.reason) {
        tempData.reasonSplit = tempData.reason.split(' - ').join('\n').split('\n');
      }
      tempData.reportedId = apiData[i].reportedBy;
      tempData.reportedOnUser = apiData[i].reportedOnUser;
      tempData.age = apiData[i].reportedByUserAge;
      tempData.alias = apiData[i].reportedByUserAlias;
      tempData.location = apiData[i].reportedByUserLoc;
      tempData.time = new Date(apiData[i].timestamp);
      tempData.day = tempData.time.toString().slice(0, 3);
      if (type == '1') {
        this.reportListDataByOthers.push(tempData);
      } else {
        this.reportListDataByME.push(tempData);
      }
    }


  }


  getNPSrating() {
    this.ratingGivenByUser = !this.ratingGivenByUser;
    let userRating;
    if (this.sessionstorage.getFromSession('user' + this.sharedservice.userId + '-rating')) {
      // console.log(this.sessionstorage.getFromSession('user' + this.sharedservice.userId + '-rating'));
      userRating = this.sessionstorage.getFromSession('user' + this.sharedservice.userId + '-rating').ratings;
      this.setUserRating(userRating);
    } else {
      if (this.ratingGivenByUser) {
        this.apiservice.getnpsrating({ userid: Number(this.sharedservice.userId) }).subscribe(result => {
          if (result) {
            // let userRating;
            const userRating = result.ratings;
            this.sessionstorage.setToSession('user' + this.sharedservice.userId + '-rating', result);
            this.setUserRating(userRating);
          }
        },
          (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
            if (err.status === 401) {
              this.localservice.removeToken('user');
              this.router.navigate(['/login']);
            } else if (err.status >= 500) {
              //  this.sharedservice.gettingServerError();
            }
          });
      }

    }

  }

  setUserRating(userRating) {
    this.ratings = userRating.reverse();
  }

  selectUniversity(universityName, i) {
    this.moderationData.academics[i].college = universityName;
  }

  moderateCollege(index, item, status) {
    const params: any = {
      relid: item.id,
      decision: {
        universities: {
          message: status ? null : 'This is testing',
          collegename: status ? item.college : null,
          approved: status ? true : false
        }
      }
    };

    if (status) {
      // params.decision.universities.collegename = this.newUniversityName._results[index].nativeElement.innerText;
      params.decision.universities.collegename = params.decision.universities.collegename?.trim();
    } else if (status === false) {
      // this.newUniversityName._results[index].nativeElement.innerText = item.college;
      this.moderationData.academics[index].college = item.college;
    }
    const colleges = this.sharedData.userdata[0].universities;
    // console.log(this.newUniversityName);
    this.apiservice.unversityModeration(params).subscribe(res => {
      if (res) {
        this.moderationData.academics[index].editUniversity = -1;
        this.editUniversity = -1;
        if (!this.isReviewer && status) {
          colleges.ModeratedBy = true;
          colleges.universityunmoderated = false;
          colleges.universitymoderationfailed = false;
          this.moderationData.academics[index].leftBtnColor = 'green';
          this.moderationData.academics[index].rightBtnColor = 'white';
        } else if (!this.isReviewer && status === false) {
          colleges.ModeratedBy = true;
          colleges.universityunmoderated = false;
          colleges.universitymoderationfailed = false;
          this.moderationData.academics[index].leftBtnColor = 'white';
          this.moderationData.academics[index].rightBtnColor = 'red';
        } else if (this.isReviewer && status) {
          colleges.ReviewedBy = true;
          colleges.universityaction = true;
          this.moderationData.academics[index].leftBtnColor = 'purple';
          this.moderationData.academics[index].rightBtnColor = 'white';
        } else {
          colleges.ReviewedBy = true;
          colleges.universityaction = false;
          this.moderationData.academics[index].leftBtnColor = 'white';
          this.moderationData.academics[index].rightBtnColor = 'gray';
        }
        this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', this.sharedData);
      }
    });

  }
  goToEditTab() {
    this.sharedservice.tabSwitch.next("EDITS");
  }
  moderateFirstName(status) {
    if (this.newFirstName.nativeElement.innerText.length > 20) {
      // this.newFirstName.nativeElement.innerText = this.newFirstName.nativeElement.innerText.slice(0,20);
      this.firstnameLenghtExceed = true;
    } else {
      this.firstnameLenghtExceed = false;
    }
    if (!this.firstnameLenghtExceed) {
      const params: (any) = {}

      if (this.moderationFirstname.singleTimemoderation) {
        params.userid = this.sharedservice.userId,
          params.approve = status;
        params.firstname = this.newFirstName.nativeElement.innerText.trim();
      } else {
        params.userid = this.sharedservice.userId,
          params.firstname = this.newFirstName.nativeElement.innerText.trim();
      }


      this.apiservice.editfirstname(params).subscribe(result => {
        if (result) {
          this.moderationFirstname.firstNameButtonDisable = true;
          this.editFirstName = false;
          this.moderationFirstname.userFirstName = params.firstname;
          this.moderationFirstname.leftBtnColor = 'green';
          this.moderationFirstname.singleTimemoderation = null;
        }

      }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
    }




  }

  modeateCompany(status) {
    const params: (any) = {
      userid: this.sharedservice.userId,
      decision: {
        companyname: {
          approved: status,
          message: status ? null : 'This is for testing'
        }
      }
    };
    // if (this.editCompany) {
    params.newvalue = this.newCompanyName.nativeElement.innerText;
    params.newvalue = params.newvalue?.trim();
    // }
    const company = this.sharedData.userdata[0].profiledata.moreabout;
    if (company.companynameunmoderated || company.companynameunmoderated == null) {
      this.apiservice.companyModeration(params).subscribe(res => {
        if (res) {
          this.editCompany = false;
          if (!this.isReviewer && status) {
            company.companynameModeratedBy = true;
            company.companynameunmoderated = false;
            company.companynamemoderationfailed = false;
            this.moderationData.company.leftBtnColor = 'green';
            this.moderationData.company.rightBtnColor = 'white';
          } else if (!this.isReviewer && status === false) {
            company.companynameModeratedBy = true;
            company.companynameunmoderated = false;
            company.companynamemoderationfailed = true;
            this.moderationData.company.leftBtnColor = 'white';
            this.moderationData.company.rightBtnColor = 'red';
          } else if (this.isReviewer && status) {
            company.companynamereviewedBy = true;
            company.companynameaction = true;
            this.moderationData.company.leftBtnColor = 'purple';
            this.moderationData.company.rightBtnColor = 'white';
          } else {
            company.companynamereviewedBy = true;
            company.companynameaction = false;
            this.moderationData.company.leftBtnColor = 'white';
            this.moderationData.company.rightBtnColor = 'gray';
          }

          this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', this.sharedData);
        }
      });
    }
  }

  editFirstNameFunction() {
    this.editFirstName = !this.editFirstName;
    this.moderationFirstname.firstNameButtonDisable = false;
    this.moderationFirstname.leftBtnColor = 'orange';
    if (this.newFirstName.nativeElement.innerText.length < 20) {
      this.firstnameLenghtExceed = false;
    }
  }

}
