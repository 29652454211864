<div class="row mx-0 mb-3">
    <div class="col-12 px-0 pt-3" *ngIf="!skelentonView">
        <div class="row mx-0 mb-2" *ngIf="apiresponse">
            <div class="col-12 px-3" *ngFor="let item of displayArray;let i = index">

                



                <!-- (Preferences) -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.isPersonalInfo && item.domName != 'Height (Preferences)' && item.domName != 'Height (basic)' && item.domName != 'Min. education level (Preferences)' && item.domName != 'Age (Preferences)' && item.domName != 'Location (Preferences)' && item.domName != 'Profiles with photos only (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div class="row mx-0 mt-2">
                            <div
                                class="col-12 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let plusIdeal of item.plusData.ideal">
                                        {{plusIdeal}}
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="col-12 px-0 pl-3 py-2 mt-2 text-left box-height c-graycolor ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" style="color:#979797"
                                        *ngFor="let minusIdeal of item.minusData.ideal">
                                        {{minusIdeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Location (Preferences) -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.isPersonalInfo && item.domName == 'Location (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-9 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-3 px-0 pt-2">
                        <img class="float-right clickable" height="18" style="top: 10px;"
                            src="/assets/images/static/reload-icon.svg"
                            (click)="locationData(i,item.plusData.ideal, item.plusData.acceptable ,item.minusData.ideal, item.minusData.acceptable)"
                            alt="">
                    </div>
                    
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div class="row mx-0 mt-2">
                            <div
                                class="col-12 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" *ngFor="let plusIdeal of item.plusData.ideal">
                                        {{plusIdeal}}
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="col-12 px-0 pl-3 py-2 mt-2 text-left box-height c-graycolor ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" style="color:#979797"
                                        *ngFor="let minusIdeal of item.minusData.ideal">
                                        {{minusIdeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- "Min. education level (Preferences) -->
                <div class="row mx-0 text-left pt-4" *ngIf="item.domName == 'Min. education level (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div class="row mx-0 mt-2">
                            <div
                                class="col-12 px-0 pl-3 py-2 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item">
                                        {{item.plusData.ideal}}
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="col-12 px-0 pl-3 py-2 mt-2 text-left box-height c-graycolor ideal-acceptable-box-height">
                                <ul class="list-inline list-parent text-left mb-0">
                                    <li class="list-inline-item" style="color:#979797">
                                        {{item.minusData.ideal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>


                <!-- Height -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Height (Preferences)' && item.domName != 'Profiles with photos only (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div class="row mx-0">
                            <div
                                class="col-12 px-0 px-0 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <div class="small-text c-darkgray opensans-light">
                                    {{item.plusData.ideal.low}} To {{item.plusData.ideal.high}}

                                </div>
                               
                            </div>
                            <div
                                class="col-12 px-0 pt-2 text-left box-height c-graycolor ideal-acceptable-box-height">
                                <div class="small-text c-darkgray opensans-light" style="color:#979797">
                                    {{item.minusData.ideal.low}} To {{item.minusData.ideal.high}}

                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Profiles with photos only (Preferences) -->
                <div class="row mx-0 text-left pt-4" *ngIf="item.domName == 'Profiles with photos only (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    

                </div>

                <!-- age (Preferences)-->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Age (Preferences)' && item.domName != 'Profiles with photos only (Preferences)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    

                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div class="row mx-0">
                            
                            <div
                                class="col-12 px-0 text-left box-height c-darkgray ideal-acceptable-box-height">
                                <div class="small-text c-darkgray opensans-light">
                                    {{item.plusData.ideal.low}}-{{item.plusData.ideal.high}} years

                                </div>
                                <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                                    {{item.minusData.ideal.low}}-{{item.minusData.ideal.high}} years
                                </div>
                               
                            </div>
                           
                        </div>
                        
                    </div>
                </div>


                <!-- edit profile -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="!item.isPersonalInfo && item.domName != 'Height (basic)' && item.domName != 'Piercings (Appearance)' && item.domName != 'College(s) / University(s) (Education & Career)' && item.domName != 'Degree(s) (Education & Career)' && item.domName != 'Reveal Company (Education & Career)' && item.domName != 'Languages & fluency (Socio-Cultural Background)' && item.domName != 'Ethnic/ national origin (Socio-Cultural Background)' && item.domName != 'Community/ caste (Socio-Cultural Background)' &&  item.domName != 'Religious affiliation/views (Socio-Cultural Background)' && item.domName != 'Dietary preferences (Lifestyle)' && item.domName != 'Reveal income (Education & Career)' && item.domName != 'Drink reduce (Lifestyle)' && item.domName != 'Smoking reduce (Lifestyle)' && item.domName != 'Reveal drinking (Lifestyle)' && item.domName != 'Reveal college (Education & Career)' && item.domName != 'Reveal smoking (Lifestyle)' && item.domName != 'Mobile number' && item.domName != 'Grew up in (Socio-Cultural Background)' && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)' && item.domName != 'Family based in (Family Background)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light"
                        *ngIf="item.domName == 'Sex (Basics)'">
                        <div *ngIf="item.valuePlus">Male</div>
                        <div *ngIf="!item.valuePlus">Female</div>
                    </div>

                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light"
                        *ngIf="item.domName == 'Age (Basics)' || item.domName == 'Since (Basics)' || item.domName == 'Visa validity (Basics)'">
                        <span *ngIf="item.domName == 'Since (Basics)'">
                            <span *ngIf="item.valuePlus > 9999999999 && item.valuePlus != null">{{item.valuePlus | date:
                                'LLL yyyy'}}</span>
                            <span *ngIf="item.valuePlus <= 9999999999 && item.valuePlus != null">{{item.valuePlus +
                                '000' | date: 'LLL yyyy'}}</span>
                        </span>
                        <span *ngIf="item.domName == 'Visa validity (Basics)'">
                            <span *ngIf="item.valuePlus > 9999999999 && item.valuePlus != null">{{item.valuePlus | date:
                                'LLL yyyy'}}</span>
                            <span *ngIf="item.valuePlus <= 9999999999 && item.valuePlus != null">{{item.valuePlus +
                                '000' | date: 'LLL yyyy'}}</span>
                            <span *ngIf="item.valuePlus == null">
                                NULL
                            </span>
                        </span>
                        <span *ngIf="item.domName == 'Age (Basics)'">
                            {{item.valuePlus}} years
                        </span>
                    </div>

                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light"
                        *ngIf="item.domName != 'Sex (Basics)' && item.domName != 'Age (Basics)' && item.domName != 'Since (Basics)' && item.domName != 'Visa validity (Basics)'">
                        {{item.valuePlus}}
                    </div>
                    <div class="col-12 px-0 pt-2 small-text opensans-light c-graycolor"
                        *ngIf="item.domName == 'Sex (Basics)'">
                        <div *ngIf="item.valueMinus">Male</div>
                        <div *ngIf="!item.valueMinus">Female</div>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light"
                        *ngIf="item.domName == 'Age (Basics)' || item.domName == 'Since (Basics)' || item.domName == 'Visa validity (Basics)'">
                        <span *ngIf="item.domName == 'Since (Basics)'">
                            <span *ngIf="item.valueMinus > 9999999999 && item.valueMinus != null">{{item.valueMinus |
                                date: 'LLL yyyy'}}</span>
                            <span *ngIf="item.valueMinus <= 9999999999 && item.valueMinus != null">{{item.valueMinus +
                                '000' | date: 'LLL yyyy'}}</span>
                            <span *ngIf="item.valueMinus == null">
                                NULL
                            </span>
                        </span>
                        <span *ngIf="item.domName == 'Visa validity (Basics)'">
                            <span *ngIf="item.valueMinus > 9999999999 && item.valueMinus != null">{{item.valueMinus |
                                date: 'LLL yyyy'}}</span>
                            <span *ngIf="item.valueMinus <= 9999999999 && item.valueMinus != null">{{item.valueMinus +
                                '000' | date: 'LLL yyyy'}}</span>
                            <span *ngIf="item.valueMinus == null">
                                NULL
                            </span>
                        </span>
                        <span *ngIf="item.domName == 'Age (Basics)'">
                            {{item.valueMinus}} years
                        </span>

                    </div>
                    <div class="col-12 px-0 pt-2 small-text opensans-light c-graycolor"
                        *ngIf="item.domName != 'Sex (Basics)' && item.domName != 'Age (Basics)' && item.domName != 'Since (Basics)' && item.domName != 'Visa validity (Basics)'">
                        {{item.valueMinus}}
                    </div>

                </div>


                <!-- -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Height (basic)' && item.domName != 'College(s) / University(s) (Education & Career)' && item.domName != 'Degree(s) (Education & Career)' && item.domName != 'Reveal Company (Education & Career)' && item.domName != 'Reveal income (Education & Career)' && item.domName != 'Reveal college (Education & Career)' && !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)' && item.domName != 'Family based in (Family Background)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 small-text c-darkgray opensans-light">
                        {{item.valuePlus}}
                    </div>
                    <div class="col-12 px-0 small-text c-graycolor opensans-light">
                        {{item.valueMinus}}
                    </div>

                </div>

                <!--  -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Piercings (Appearance)' && item.domName != 'Degree(s) (Education & Career)' && item.domName != 'College(s) / University(s) (Education & Career)' && item.domName != 'Reveal income (Education & Career)' && item.domName != 'Reveal Company (Education & Career)' && item.domName != 'Reveal college (Education & Career)' && !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)' && item.domName != 'Family based in (Family Background)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <span *ngFor="let item of item.valuePlus; let last = last">{{item}}<span *ngIf="!last">,
                            </span></span>

                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        <span *ngFor="let item of item.valueMinus; let last = last">{{item}}<span *ngIf="!last">,
                            </span></span>
                    </div>

                </div>

                <!-- College -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'College(s) / University(s) (Education & Career)' &&  !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div *ngFor="let item of item.valuePlus; let last = last">{{item.college}}</div>

                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        <div *ngFor="let item of item.valueMinus; let last = last">{{item.college}}</div>
                    </div>

                </div>

                <!-- Degree -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="(item.domName == 'Degree(s) (Education & Career)' || item.domName == 'Languages & fluency (Socio-Cultural Background)' || item.domName == 'Community/ caste (Socio-Cultural Background)' || item.domName == 'Ethnic/ national origin (Socio-Cultural Background)' || item.domName == 'Dietary preferences (Lifestyle)' ) && !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div *ngFor="let item of item.valuePlus; let last = last">{{item}}</div>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        <div *ngFor="let item of item.valueMinus; let last = last">{{item}}</div>
                    </div>

                </div>

                <!-- Religious affiliation/views -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Religious affiliation/views (Socio-Cultural Background)' && !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)'">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <span *ngFor="let item of item.valuePlus; let last = last">{{item}}<span *ngIf="!last">,
                            </span></span>

                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        <span *ngFor="let item of item.valueMinus; let last = last">{{item}}<span *ngIf="!last">,
                            </span></span>
                    </div>

                </div>

                <!-- Grew up in (Socio-Cultural Background) -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Grew up in (Socio-Cultural Background)' && !item.isPersonalInfo && item.domName != 'Profiles with photos only (Preferences)' && item.domName != 'Location (Basics)'">
                    <div class="col-12 px-0  oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-9 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-3 px-0 pt-2">
                        <img class="float-right clickable" height="18" src="/assets/images/static/reload-icon.svg"
                            (click)="grewUpInData(i,item.valuePlus,item.valueMinus)" alt="">

                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        <div *ngFor="let item of item.valuePlus; let last = last">{{item.nodeid}}<span>,
                                {{item.grewupinliveagain}}</span></div>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        <div *ngFor="let item of item.valueMinus; let last = last">{{item.nodeid}}<span>,
                                {{item.grewupinliveagain}}</span></div>
                    </div>

                </div>

                <!-- Location (Basics) -->
                <div class="row mx-0 text-left pt-4"
                    *ngIf="item.domName == 'Location (Basics)' || item.domName == 'Family based in (Family Background)'">
                    <div class="col-12 px-0  oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-9 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-3 px-0 pt-2" *ngIf="item.domName == 'Location (Basics)'">
                        <img class="float-right clickable" height="18" src="/assets/images/static/reload-icon.svg"
                            (click)="locationEditSection(i,item.valuePlus,item.valueMinus)" alt="">
                    </div>
                    <div class="col-3 px-0 pt-2" *ngIf="item.domName == 'Family based in (Family Background)'">
                        <img class="float-right clickable" height="18" src="/assets/images/static/reload-icon.svg"
                            (click)="familyLocation(i,item.valuePlus,item.valueMinus)" alt="">
                    </div>
                    <div *ngIf="item.domName == 'Location (Basics)'">
                        <div class="col-12 px-0 small-text pt-2 c-darkgray opensans-light">
                            {{item.valuePlus}}
                        </div>
                        <div class="col-12 px-0 small-text pt-2 c-graycolor opensans-light">
                            {{item.valueMinus}}
                        </div>
                    </div>
                    <div *ngIf="item.domName == 'Family based in (Family Background)'">
                        <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                            <div *ngFor="let item of item.valuePlus; let last = last">{{item}}<span
                                    *ngIf="last != last">, </span></div>
                        </div>
                        <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                            <div *ngFor="let item of item.valueMinus; let last = last">{{item}}<span
                                    *ngIf="last != last">, </span></div>
                        </div>
                    </div>


                </div>

                <!-- Mobile number -->
                <div class="row mx-0 text-left pt-4" *ngIf="item.domName == 'Mobile number' && !item.isPersonalInfo">
                    <div class="col-12 px-0 oswald body-text c-darkgray">
                        {{item.domName}}
                    </div>
                    <div class="col-12 px-0 pt-2 c-graycolor small-text opensans-regular">
                        By {{item.editedBy}} <span class="pl-2 body-subtext">{{item.timestamp | date : 'medium'}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-darkgray opensans-light">
                        +{{item.valuePlus.countrycode}} {{item.valuePlus.phonenumber}}
                    </div>
                    <div class="col-12 px-0 pt-2 small-text c-graycolor opensans-light">
                        +{{item.valueMinus.countrycode}} {{item.valueMinus.phonenumber}}
                    </div>
                </div>

            </div>

        </div>
        <div class="row mx-0 vh-100" *ngIf="!apiresponse">
            <div class="col-12 px-0 pt-4 oswald j-h3 c-darkgray">
                No edits is available at the moment
            </div>
        </div>
        <div class="row mx-0 pt-4 pb-3" *ngIf="showMore">
            <div class="col-12 px-0 text-center">
                <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData()">SHOW MORE</button>
            </div>
        </div>
    </div>
    <!-- skelenton -->
    <div class="col-12 px-3 pb-5" *ngIf="skelentonView">
        <div class="row mx-0 ">
            <div class="pt-4">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-6 px-0 mat-elevation-z5 skelenton-class-left"></div>
                </div>
            </div>
            <div class="pt-5">
                <div class="row mx-0 ph-item">
                    <div class="col-4 px-0 mat-elevation-z5 skelenton-class"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-9 px-0 mat-elevation-z5 skelenton-class" style="height: 20px;"></div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4 ph-item">
                    <div class="col-3 px-0 mat-elevation-z5 skelenton-class-left"></div>
                    <div class="col-7 px-0 pl-5">
                        <div class="mat-elevation-z5 skelenton-class-width"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>