import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { RatingReasonDialogComponent } from './rating-reason-dialog/rating-reason-dialog.component';

@Component({
  selector: 'app-nps-rating',
  templateUrl: './nps-rating.component.html',
  styleUrls: ['./nps-rating.component.css']
})
export class NpsRatingComponent implements OnInit {
  ratingArray = [];
  skipValue = 0;
  sortingDateToggle = false;
  sortingNoofRatingToggle = false;
  sortingRatingToggle = false;
  showMoreButton = true;
  apiresponce = false;
  afterSearchResultData = false;
  afterSearchResultEmpty = false;
  searchStringType = false;
  disableShowMoreButton = false;
  loaderDailog;
  constructor(private apiservice : ApiService,private md: MatDialog, private localstorage: LocalStorageService, private router: Router, private sharedservice: SharedService) { }

  ngOnInit(): void {
    const params = {
      skip : this.skipValue,
      limit: 10,
    }
    this.apiservice.getnpsrating(params).subscribe(async result =>{
      if(result){
        
        if(result.length < 10){
          this.showMoreButton = false;
        }
        this.disableShowMoreButton = false;
        this.apiresponce = true;
        const apiData = result;
        for(const i in apiData){
          await this.prepareData(apiData[i]);
        }
        if(this.skipValue >= 10){
          this.loaderDailog.close();
        }
        
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        this.loaderDailog.close();
      if (err.status === 401) {
        this.localstorage.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        // this.sharedservice.gettingServerError();
      }
    });
  }

  navigateToProfileView(userId){
    // if(environment.production){
    //   const url = 'https://admin.jodi365.com/#!/userprofile?userid=' + userId;
    //   window.open(url,'_blank');
    // } else{
    //   const url = 'https://admin.testing.jodi365.com/#!/userprofile?userid=' + userId;
    //   window.open(url,'_blank');
    // }
    const url = '/admin/profile?userid=' + userId;
    window.open(url,'_blank');
    
  }

    // show more
    showMoreData(){
      this.skipValue = this.skipValue + 10;
      this.disableShowMoreButton = true;
      this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
      
      this.ngOnInit();
  }

    // search result
    searchByUserIdData(userID){
      userID = userID.trim();
      if(userID === ""){
      } else if(isNaN(userID)){
        this.ratingArray = [];
        this.searchStringType = true;
        this.showMoreButton = false; 
        this.afterSearchResultEmpty = false;
      } else if(!isNaN(userID)){
        this.searchStringType = false;
          this.afterSearchResultData = true;
          let tempRatingData = [];
          const index = this.ratingArray.findIndex(e => e.userid === Number(userID) );
          if(index > -1){
            this.showMoreButton = false;
            tempRatingData = this.ratingArray[index];
            this.ratingArray = [];
            this.ratingArray.push(tempRatingData);
          } else{
            this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
            this.showMoreButton = false;
            this.apiservice.getnpsrating({userid: userID}).subscribe(result => {
              this.loaderDailog.close();
              if(result){
                const apiData: any = result;
                this.ratingArray = [];
                this.prepareData(apiData);
              } else{
                this.ratingArray = [];
                this.afterSearchResultEmpty = true;
              }
            },
              (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
                this.loaderDailog.close();
              if (err.status === 401) {
                this.localstorage.removeToken('user');
                this.router.navigate(['/login']);
              } else if (err.status >= 500) {
                // this.sharedservice.gettingServerError();
              }
            });
          }
      } 
      
    }

    
  viewAlltheRatingsAndReasons(forWhom, ratingList, userAlias, comment ){
    const userRatingData =  this.md.open(RatingReasonDialogComponent, { 
      panelClass: 'dialog-container-rating',
      minHeight: '30vh',
      width: '50vw',
      maxHeight: '50vh',
      autoFocus: false,  
      data: {forWhom: forWhom, ratingList : ratingList, userAlias : userAlias, reason: comment}
    });
    userRatingData.afterClosed().subscribe(result => {
      if(result) {
        console.log('successful');
      }
    });
  }

  
  sorting(type, key){
    if(key == 'Date'){
      this.sortingDateToggle = !this.sortingDateToggle;
      if(type == 'asce'){
        this.ratingArray = this.ratingArray.sort((a,b) => {
          if(a.lastUpdate < b.lastUpdate){
            return -1;
          } if(a.lastUpdate > b.lastUpdate){
            return 1;
          } 
          return 0;
        });
      } else {
        this.ratingArray = this.ratingArray.sort((b,a) => {
          if(a.lastUpdate < b.lastUpdate){
            return -1;
          } if(a.lastUpdate > b.lastUpdate){
            return 1;
          } 
          return 0;
        });
      }
    } if(key == 'noOfRating'){
      this.sortingNoofRatingToggle = !this.sortingNoofRatingToggle;
      if(type == 'asce'){
        this.ratingArray = this.ratingArray.sort((a,b) => {
          if(a.numberofRating < b.numberofRating){
            return -1;
          } if(a.numberofRating > b.numberofRating){
            return 1;
          } 
          return 0;
        });
      } else {
        this.ratingArray = this.ratingArray.sort((b,a) => {
          if(a.numberofRating < b.numberofRating){
            return -1;
          } if(a.numberofRating > b.numberofRating){
            return 1;
          } 
          return 0;
        });
      }
    } if(key == 'rating'){
      this.sortingRatingToggle = !this.sortingRatingToggle;
      if(type == 'asce'){
        this.ratingArray = this.ratingArray.sort((a,b) => {
          if(a.lastRating < b.lastRating){
            return -1;
          } if(a.lastRating > b.lastRating){
            return 1;
          } 
          return 0;
        });
      } else {
        this.ratingArray = this.ratingArray.sort((b,a) => {
          if(a.lastRating < b.lastRating){
            return -1;
          } if(a.lastRating > b.lastRating){
            return 1;
          } 
          return 0;
        });
      }
    }
  }

  prepareData(apiData){
    const tableData: any = {};
    tableData.userid = apiData.userid;
    tableData.alias = apiData.alias;
    tableData.lastUpdate = apiData.lastupdated;
    tableData.ratingList = apiData.ratings;
    tableData.numberofRating = apiData.ratings.length;
    tableData.lastRating = apiData.ratings[tableData.numberofRating-1].rating;
    tableData.lastComment = apiData.ratings[tableData.numberofRating-1].reason;
    tableData.shortReason = null;
    if(tableData.lastComment){
      tableData.shortReason = tableData.lastComment.substring(0,300);
    }
    this.ratingArray.push(tableData);
    
  }
  








}
