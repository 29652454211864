<div class="row mx-0" *ngIf="forWhom == 'emailPhoneNumber'">
    <div class="col-12 px-0 p-4 opensans-light c-darkgray subheading text-center ">
        <span class="text-center oswald c-darkgray j-h3">Account Details</span>
        <img class="position-absolute clickable cross-icon-position" (click)="closeDialog()"
            src="/assets/images/static/close-cross-icon.svg" alt="" height="20" width="20">
    </div>

    <div class="col-12 px-0">
        <!-- email update -->
        <form [formGroup]="emailUpdateForm" (ngSubmit)="onSubmit()" *ngIf="type == 'email'">
            <mat-form-field appearance="outline" class="px-3 w-100 pt-2">
                <mat-label>
                    Email address
                </mat-label>
                <input type="text" matInput email formControlName="email" (click)="changeStatus()" maxlength="50"
                    autocomplete="off" style="font-size:18px;">
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="emailUpdateForm.get('email').hasError('hasEmail')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span class="smaller-text"> Email address already exists</span>
                </mat-error>
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="emailUpdateForm.get('email').hasError('email')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span class="smaller-text"> Email address is invalid</span>
                </mat-error>
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="emailUpdateForm.get('email').hasError('required')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span> Email is required </span>
                </mat-error>
            </mat-form-field>

            <div class="px-2 pt-4 pb-3">
                <div class="row mx-0">
                    <div class="col-12 px-0 text-center">
                        <span class="pr-3 c-graycolor opensans-regular clickable" (click)="closeDialog()">CANCEL</span>
                        <button *ngIf="dbEmail != emailUpdateForm.controls.email.value" mat-raised-button
                            class="bg-btn-primary text-white opensans-regular clickable" [disabled]="disableSaveButton"
                            [ngClass]="emailUpdateForm.get('email').hasError('required') ? 'bg-disabled-primary' : 'bg-btn-primary'">SAVE</button>

                        <button *ngIf="dbEmail == emailUpdateForm.controls.email.value" mat-raised-button
                            class="bg-btn-primary text-white opensans-regular clickable" [disabled]="disableSaveButton"
                            [ngClass]="emailUpdateForm.get('email').hasError('required') ? 'bg-disabled-primary' : 'bg-btn-primary'"
                            (click)="closeDialog()">SAVE</button>
                    </div>
                </div>
            </div>

        </form>

        <!-- phoneNumber update -->
        <form [formGroup]="phoneNumberUpdateForm" (ngSubmit)="onSubmit()" *ngIf="type == 'phoneNumber'">
            <mat-form-field appearance="outline" class="px-2 w-25 pt-2">
                <mat-label>
                    Select country code
                </mat-label>
                <mat-select [value]="countryCode" formControlName="countryCode">
                    <mat-optgroup *ngFor="let group of countryCodes" [label]="group.label">
                        <mat-option *ngFor="let item of group.dataArray" [value]="item.value">
                            {{item.name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="px-2 w-75 pt-2">
                <mat-label>
                    Phone Number
                </mat-label>
                <input type="number" matInput placeholder="Enter mobile number" formControlName="phoneNumber"
                    (click)="changeStatus()" required autocomplete="off" maxlength="12" minlength="6"
                    style="font-size:18px;">
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="phoneNumberUpdateForm.get('phoneNumber').hasError('required')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span> Phone Number is required </span>
                </mat-error>
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="phoneNumberUpdateForm.get('phoneNumber').hasError('min')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span> Should be greater than 6 digit </span>
                </mat-error>
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="phoneNumberUpdateForm.get('phoneNumber').hasError('max')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span> Should not exceed 12 digit </span>
                </mat-error>
                <mat-error class="text-left smaller-text c-alert-danger"
                    *ngIf="phoneNumberUpdateForm.get('phoneNumber').hasError('hasPhoneNumber')">
                    <span class="fa fa-exclamation-triangle"></span>
                    <span> phone number already exists</span>
                </mat-error>

            </mat-form-field>

            <div class="px-2 pt-4 pb-3">
                <div class="row mx-0">
                    <div class="col-12 px-0 text-center">
                        <span class="pr-3 c-graycolor opensans-regular clickable" (click)="closeDialog()">CANCEL</span>
                        <span class="pl-3">
                            <button
                                *ngIf="countryCode != phoneNumberUpdateForm.controls.countryCode.value || phoneNumber != phoneNumberUpdateForm.controls.phoneNumber.value"
                                mat-raised-button class="bg-btn-primary text-white opensans-regular "
                                [disabled]="disableSaveButton"
                                [ngClass]="phoneNumberUpdateForm.get('phoneNumber').hasError('required') ? 'bg-disabled-primary' : 'bg-btn-primary'">SAVE</button>

                            <button
                                *ngIf="countryCode == phoneNumberUpdateForm.controls.countryCode.value && phoneNumber == phoneNumberUpdateForm.controls.phoneNumber.value"
                                mat-raised-button class="bg-btn-primary text-white opensans-regular "
                                [ngClass]="phoneNumberUpdateForm.get('phoneNumber').hasError('required') ? 'bg-disabled-primary' : 'bg-btn-primary'"
                                [disabled]="disableSaveButton" (click)="closeDialog()">SAVE</button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>


</div>

<!-- membership status history -->
<div class="row mx-2 my-4" *ngIf="forWhom == 'membershipStatus'">
    <div class="col-12 px-0 p-4 opensans-light c-darkgray subheading text-center ">
        <span class="text-center oswald c-darkgray j-h3 pt-2">Membership Details</span>
        <img class="position-absolute clickable cross-icon-position" (click)="closeDialog()"
            src="/assets/images/static/close-cross-icon.svg" alt="" height="20" width="20">
    </div>
    <div class="col-12 px-2">
        <div class="row mx-0 mt-3" *ngFor="let item of planHistoryArray;">
            <div class="col-12 px-0 opensans-regular c-darkgray">
                {{item.boughtupDate | date:'medium'}}
            </div>
            <div class="col-12 px-0">
                <div class="row mx-0">
                    <div class="col-3 px-0 oswald body-text c-darkgray">
                        <span *ngIf="item.assistPlan">Assisted connect plan</span>
                        <span *ngIf="item.expressPlan">Express connect plan</span>
                        <span *ngIf="item.quickPlan">Quick connect plan</span>
                    </div>
                    <div class="col-2 px-0 opensans-light body-subtext c-darkgray">
                        <span *ngIf="item.duration == 1">{{item.duration}} month</span>
                        <span *ngIf="item.duration != 1">{{item.duration}} months</span>

                    </div>
                    <div class="col-2 px-0">
                        <span *ngIf="item.Status" class="opensans-regular body-subtext" style="color: #008538;"><img
                                src="/assets/images/static/success-green-icon.svg" class="pr-2" alt="">Success</span>
                        <span *ngIf="!item.Status" class="c-alert-danger opensans-regular body-subtext"><img
                                src="/assets/images/static/failed-red-icon.svg" class="pr-2" alt="">Failed</span>
                    </div>
                    <div class="col-5 px-0 opensans-regular body-subtext c-darkgray" *ngIf="item.expiryDate">
                        <span class="opensans-light c-darkergray pr-2">Expires on</span> {{item.expiryDate |
                        date:'medium'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>