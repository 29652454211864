<div class="row mx-0 text-center parent-class">
    <div class="col-11 mx-auto px-0 h-100">
        <div *ngIf="hasApiResponse" class="row mx-0 h-100 mb-2">
            <!-- Left View -->
            <div class="col-3">
                <div class="row mx-0">

                    <!-- userImage and Icon -->
                    <div class="col-12 px-0 box-shadow-section bg-white">
                        <div class="">
                            <div class="position-relative user-image-container">
                                <div class="position-absolute user-image"
                                    [ngStyle]="{'background-image': 'url('+ userData.profileImage +')'}">
                                    <div class="position-absolute photo-counter" matTooltip="Photo Counter"
                                        matTooltipClass="toolTipBgColor">
                                        <div class="position-relative">
                                            <img src="../../../assets/images/static/photo-counter.svg" height="20"
                                                width="20" alt="photo-counter">
                                            <span
                                                class="d-block position-absolute photo-counter-font">{{userData.photoCounter}}</span>
                                        </div>
                                    </div>
                                    <!-- user plan type -->
                                    <div class="position-absolute user-plan-display-box c-white px-2">
                                        <!--expired user consider as free member  -->
                                        <span
                                            *ngIf="userData.planExpireOn < userData.currentTimeStamp && userData.planExpireOn != userData.oneweekfreeplan"><i>Free
                                                Member </i></span>
                                        <!-- one week free plan member -->
                                        <span
                                            *ngIf="userData.oneweekfreeplan > userData.currentTimeStamp && userData.oneweekfreeplan == userData.planExpireOn">
                                            <i *ngIf="userData.planType == 'Assisted connect'">Assisted Connect-FREE</i>
                                            <i *ngIf="userData.planType == 'Express connect'">Express Connect-FREE</i>
                                            <i *ngIf="userData.planType == 'Quick connect'">Quick Connect-FREE</i>
                                        </span>
                                        <!-- free member and one week plan expired member -->
                                        <span
                                            *ngIf="userData.planExpireOn == null || (userData.planExpireOn == userData.oneweekfreeplan  && userData.oneweekfreeplan < userData.currentTimeStamp)"><i>Free
                                                Member </i></span>
                                        <!-- plan member -->
                                        <span
                                            *ngIf="userData.planExpireOn > userData.currentTimeStamp && userData.planExpireOn != userData.oneweekfreeplan">
                                            <i *ngIf="userData.planType == 'Assisted connect'">Assisted Connect</i>
                                            <i *ngIf="userData.planType == 'Express connect'">Express Connect</i>
                                            <i *ngIf="userData.planType == 'Quick connect'">Quick Connect</i>
                                        </span>
                                    </div>
                                    <div class="position-absolute bg-white user-verified-icon-container">
                                        <ul class="list-inline py-1 d-flex m-0">
                                            <li *ngFor="let item of userData.verifiedIconsArr; let last = last">
                                                <span class="d-inline-block" *ngIf="item.verified && !last">
                                                    <img class="pr-1" height="20" width="20" [src]="item.icon"
                                                        alt="verified-icon">
                                                </span>
                                                <span class="d-inline-block " *ngIf="!item.verified && !last">
                                                    <img class="pr-1" height="20" width="20"
                                                        [src]="item.notVerifiedicon" alt="verified-icon">
                                                </span>
                                                <span class="d-inline-block" *ngIf="item.verified && last">
                                                    <img class="pr-1" height="20" width="20" [src]="item.icon"
                                                        alt="verified-icon">
                                                </span>
                                                <span class="d-inline-block " *ngIf="item.issubmitted && last">
                                                    <img class="pr-1" height="20" width="20"
                                                        [src]="item.notVerifiedicon" alt="verified-icon">
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Basic Inforamtion -->
                    <div class="col-12 px-0 text-left box-shadow-section bg-white">
                        <div class="p-2 d-flex">
                            <div class="" [matTooltip]="userData.ismale ? 'Male' : 'Female'"
                                matTooltipClass="toolTipBgColor">
                                <img [src]="userData.ismale ? '../../../assets/images/static/male.svg' : '../../../assets/images/static/female.svg'"
                                    height="30" width="30" alt="gender-icon">
                            </div>
                            <div class="oswald c-darkgray j-h3 alias-bottom">
                                {{userData.alias}}
                                <span class="opensans-light c-darkgray body-subtext"># {{userData.userId}}</span>
                            </div>
                        </div>
                        <div class="col-12 px-2 pb-2" style="word-wrap: break-word;">
                            <div class="pl-3 opensans-regular">
                                <div *ngIf="userData.prospectFirstName" class="c-darkgray body-subtext">
                                    {{userData.prospectFirstName}}
                                    <span class="pl-1">{{userData.prospectLastName}}</span>
                                </div>
                                <div class="c-darkgray body-subtext pt-2">
                                    {{userData.firstName}}
                                    {{userData.lastName}}
                                    - {{userData.whom}}
                                </div>
                                <div class="c-darkgray body-subtext">
                                    {{userData.age}} years | {{userData.height}}
                                </div>
                            </div>
                        </div>

                        <ng-container *ngFor="let item of userData.basicLeftView">
                            <div class="d-flex px-2" matTooltipClass="toolTipBgColor" [matTooltip]="item.toolTipText">
                                <img [src]="item.icon" height="22" width="22" alt="icon">
                                <div class="c-darkgray body-subtext pl-2">
                                    {{dateFlag === item.parameter ? (item.parameter | date: 'MMM, yyy') :
                                    item.parameter}}<br />
                                    <span class="small-text c-darkergray">{{item.subparameter}}</span>
                                </div>
                            </div>
                        </ng-container>

                        <div class="d-flex px-2" matTooltipClass="toolTipBgColor" [matTooltip]="languages.toolTipText">
                            <img [src]="languages.icon" height="22" width="22" alt="icon">
                            <div class="c-darkgray body-subtext pl-2"
                                *ngIf="languages.parameter.length > 0; else noLanguages">
                                <ng-container *ngFor="let item of languages.parameter">
                                    <i *ngIf="item.fluency==='mother'">{{item.language}}</i>
                                    <b *ngIf="item.fluency==='complete'">, {{item.language}}</b>
                                    <span *ngIf="item.fluency !=='mother' && item.fluency !=='complete'">,
                                        {{item}}</span>
                                </ng-container>
                            </div>
                            <ng-template #noLanguages>
                                <div class="c-darkgray body-subtext pl-2">
                                    N.A.
                                </div>
                            </ng-template>
                        </div>

                        <div class="pt-3 d-flex">
                            <button matRipple class="w-100 body-subtext" *ngIf="isReviewer"
                                [ngClass]="fakeUserFlag ? 'fake-user-flag-button-active':'fake-user-flag-button bg-white'"
                                (click)="fakeUser(!fakeUserFlag)">
                                {{fakeUserFlag ? 'FLAGGED AS FAKE': 'FLAG AS FAKE'}}
                            </button>
                            <button matRipple class="w-50 body-subtext" *ngIf="!isReviewer"
                                [ngClass]="fakeUserFlag  ? 'fake-user-flag-button-active':'fake-user-flag-button bg-white'"
                                (click)="fakeUser(!fakeUserFlag)">
                                {{fakeUserFlag ? 'FLAGGED AS FAKE': 'FLAG AS FAKE'}}
                            </button>
                            <button matRipple class="w-50 body-subtext c-white position-relative" *ngIf="!isReviewer"
                                [disabled]="selectedProfileStatus.heading === 'SCREENED' ? true : false"
                                [ngStyle]="{'background-color': selectedProfileStatus.color,'border': '1px solid'+selectedProfileStatus.color}"
                                [matMenuTriggerFor]="status"
                                [ngClass]="selectedProfileStatus.heading === 'SCREENED' ? '' : 'profile-status-button'">
                                {{selectedProfileStatus.heading}}
                            </button>
                            <mat-menu #status="matMenu">
                                <button mat-menu-item *ngFor="let item of profileStatus;let i = index"
                                    (click)="changeProfileStatus(item.heading)">{{item.heading}}

                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <!-- flagged as fake dialog -->
                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2" *ngIf="fakeUserFlag">
                        <div class="oswald c-darkgray subheading pt-3 pb-2">Reason For Fake Profile</div>
                        <div class="c-darkergray small-text mr-2 pt-1" style=" word-wrap: break-word;">
                            <div [innerHtml]="fakeReasons"></div>
                        </div>
                    </div>

                    <!--flagged as hold dialog -->
                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2" *ngIf="holdUserFlag">
                        <div class="oswald c-darkgray subheading pt-3 pb-2">Reason for profile on hold:
                        </div>
                        <div class="c-darkergray small-text mr-2 pt-1" style=" word-wrap: break-word;">
                            <div [innerHtml]="HoldReasons"></div>
                        </div>
                    </div>

                    <!-- Account Details -->
                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2">
                        <!-- <button mat-icon-button matTooltip="Edit" matTooltipClass="toolTipBgColor"
                            class="float-right pt-3" (click)="editAccountDetails()" *ngIf="!editable">
                            <img class="center-box" src="../../../assets/images/static/edit.svg" height="22" width="22"
                                alt="edit-icon" />
                        </button> -->
                        <!-- <div class="float-right pt-2" (click)="saveAccountDetails()" *ngIf="editable">
                            <button mat-button color="warn" matTooltip="Save" matTooltipClass="toolTipBgColor">
                                SAVE
                            </button>
                        </div> -->
                        <!-- <div class="oswald c-darkgray subheading pt-3">Account Details</div> -->
                        <div class="oswald c-darkgray subheading pt-3 pb-1">
                            <span class="float-left">
                                Account Details
                            </span>
                            <!-- <span class="float-right">
                                <img class="clickable" src="../../../assets/images/static/edit.svg" (click)="updateEmailPhoneNumber()" alt="">
                            </span> -->
                        </div>
                        <div class="clearfix"></div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                User Name
                            </div>
                            <div class="c-darkgray small-text account-detail-answer" [attr.contenteditable]="editable"
                                #accountDetails style=" word-wrap: break-word;">
                                {{userData.firstName}}
                                {{userData.lastName}}
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Prospect name
                            </div>
                            <div class="c-darkgray small-text account-detail-answer" [attr.contenteditable]="editable"
                                #accountDetails style=" word-wrap: break-word;">
                                {{userData.prospectFirstName}}
                                {{userData.prospectLastName}}
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Email-address
                            </div>
                            <div class="c-darkgray small-text account-detail-answer-email-and-phone text-with-dot pr-1"
                                [attr.contenteditable]="editable" #accountDetails matTooltipClass="toolTipBgColor"
                                [matTooltip]="userData.email">
                                {{userData.email}}
                            </div>
                            <span>
                                <img mat-button class="pr-2 clickable" (click)="copyData(userData.email)"
                                    src="../../../assets/images/static/copy-icon.svg" height="15" alt="">
                            </span>
                            <span class="float-right" *ngIf="!isReviewer">
                                <img class="clickable" height="18px" src="../../../assets/images/static/edit.svg"
                                    (click)="updateEmailPhoneNumber('email',userData.email,userData.countryCodeTemp, userData.phoneNumberTemp, 'emailPhoneNumber' )"
                                    alt="">
                            </span>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Mobile number
                            </div>
                            <div class="c-darkgray small-text account-detail-answer-email-and-phone"
                                [attr.contenteditable]="editable" #accountDetails>
                                {{userData.phone}}
                            </div>
                            <span>
                                <img class="pr-2 clickable" (click)="copyData(userData.phone)"
                                    src="../../../assets/images/static/copy-icon.svg" height="15" alt="">
                            </span>
                            <span class="float-right" *ngIf="!isReviewer">
                                <img class="clickable" height="18px" src="../../../assets/images/static/edit.svg"
                                    (click)="updateEmailPhoneNumber('phoneNumber',userData.email, userData.countryCodeTemp, userData.phoneNumberTemp, 'emailPhoneNumber')"
                                    alt="">
                            </span>
                        </div>
                    </div>
                    <!-- Deletion Button -->
                    <div class="col-12 mt-3 py-2 px-0" *ngIf="!isReviewer">
                        <button mat-stroked-button class="c-white bg-btn-danger w-100"
                            (click)="profileDeletion()">DELETE PROFILE</button>
                    </div>
                    <!-- FORCIBLY LOG USER OUT  -->
                    <div class="col-12 mt-3 py-2 px-0" *ngIf="forceLogoutCheck == null && !isReviewer ">
                        <button mat-stroked-button class="w-100 c-alert-danger" style="border: 1px solid #d50000;"
                            (click)="forceiblyLogUserOut()">FORCIBLY LOG USER OUT </button>
                    </div>
                    <div class="col-12 mt-3 py-2 px-0" *ngIf="forceLogoutCheck && !isReviewer">
                        <button mat-stroked-button class="w-100 c-graycolor" style="border: 1px solid #979797;">USER
                            FORCIBLY LOGGED OUT </button>
                    </div>
                    <!-- Activity -->
                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2">
                        <div class="float-right d-flex pt-1">
                            <div matTooltip="Login Count" matTooltipClass="toolTipBgColor">
                                <img src="../../../assets/images/static/login-count.svg" height="22" width="22"
                                    alt="login-count-icon" />
                                <span class="c-darkgray small-text pl-1">{{userData.loginCount}}</span>
                            </div>
                            <div>
                                <span class="d-inline-block activity-label smaller-text"
                                    [ngStyle]="{'background-color': userData.activityStatus.color}">
                                    {{userData.activityStatus.label}}
                                </span>
                            </div>
                        </div>
                        <div class="oswald c-darkgray subheading pt-3">Activity</div>
                        <div class="clearfix"></div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">Sign up</div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                {{this.activty[0].signUp | date : 'medium'}}
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Sign up via
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">{{activty[1].signUpVia}}</div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Profile completed
                            </div>
                            <div *ngIf="activty[2].profileCompleted"
                                class="c-darkgray body-subtext account-detail-answer">
                                {{activty[2].profileCompleted | date:'medium'}}
                            </div>
                            <div *ngIf="!activty[2].profileCompleted"
                                class="c-darkgray body-subtext account-detail-answer pt-1">
                                Incomplete
                            </div>
                        </div>

                        <div *ngIf="selectedProfileStatus.heading !== 'SCREENED'" class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Last question
                            </div>
                            <div class="c-darkgray small-text account-detail-answer">
                                {{activty[6].currentquestionnumber}}
                            </div>
                        </div>
                        <!-- <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Profile screened
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                Thu, 12 May 2020 10:34 PM
                            </div>
                        </div> -->
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Last online
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                {{activty[4].lastOnline | date :'medium'}}
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Last online via
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                {{this.activty[5].lastOnlineVia}}
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Screening time
                            </div>
                            <div *ngIf="activty[7].screeningTime" class="c-darkgray body-subtext account-detail-answer">
                                {{activty[7].screeningTime | date:'medium'}}
                            </div>
                        </div>
                    </div>



                    <!-- Membership Status -->
                    <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">
                        <!-- <div class="float-right pt-3">
                            <img matTooltip="Add a Plan" matTooltipClass="toolTipBgColor" class="clickable mr-3"
                                (click)="showPlanDetails = !showPlanDetails;"
                                src="../../../assets/images/static/add.svg" height="22" width="22" alt="add-icon" />
                            <img class="clickable" src="../../../assets/images/static/info.svg" height="22" width="22"
                                alt="info-icon" />
                        </div> -->

                        <!-- expired plan user -->
                        <div
                            *ngIf="userData.planExpireOn < userData.currentTimeStamp && userData.planExpireOn != userData.oneweekfreeplan">
                            <div class="oswald c-darkgray subheading pt-3">Membership Status <span class="pl-3"><img
                                        src="../../../assets/images/static/rupee-icon.svg" height="21" width="21"
                                        alt=""></span><img class="float-right clickable"
                                    (click)="updateEmailPhoneNumber('email',userData.email,userData.countryCodeTemp, userData.phoneNumberTemp, 'membershipStatus' )"
                                    src="../../../assets/images/static/info.svg" alt=""></div>
                            <div class="text-center small-text c-darkgray py-2">{{userData.planType}}</div>
                            <div *ngIf="userData.remainingDays == 'expired'" class="text-center small-text c-darkgray">
                                {{userData.previousPlan}} connect</div>
                            <div class="text-center small-text c-darkgray pb-2"><i>{{userData.remainingDays}}</i></div>
                            <div class="pt-2 d-flex" *ngIf="userData.planExpireOn">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Expires on
                                </div>
                                <div class="c-darkgray small-text account-detail-answer">
                                    {{userData.planExpireOn | date:'medium'}}
                                </div>
                            </div>
                        </div>

                        <!-- for oneWeekPlanMember -->
                        <div
                            *ngIf="userData.oneweekfreeplan > userData.currentTimeStamp && userData.oneweekfreeplan == userData.planExpireOn">
                            <div class="oswald c-darkgray subheading pt-3">Membership Status</div>
                            <div class="clearfix"></div>
                            <div class="text-center small-text c-darkgray py-2">{{userData.planType}} (free)</div>
                            <div *ngIf="userData.remainingDays == 'expired'" class="text-center small-text c-darkgray">
                                {{userData.previousPlan}} connect</div>
                            <div class="text-center small-text c-darkgray pb-2"><i>{{userData.remainingDays}}</i></div>
                            <div class="pt-2 d-flex" *ngIf="userData.planExpireOn">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Expires on
                                </div>
                                <div class="c-darkgray small-text account-detail-answer">
                                    {{userData.planExpireOn | date:'medium'}}
                                </div>
                            </div>
                        </div>


                        <!-- free or oneWeekPlanExpired -->
                        <div
                            *ngIf="userData.planExpireOn == null || (userData.planExpireOn == userData.oneweekfreeplan  && userData.oneweekfreeplan < userData.currentTimeStamp)">
                            <div class="oswald c-darkgray subheading pt-3">Membership Status</div>
                            <div class="clearfix"></div>
                            <div class="text-center small-text c-darkgray pt-2">
                                Free Member
                            </div>
                        </div>

                        <!-- planed member -->
                        <div
                            *ngIf="userData.planExpireOn > userData.currentTimeStamp && userData.planExpireOn != userData.oneweekfreeplan">
                            <div class="oswald c-darkgray subheading pt-3">Membership Status <span><img
                                        class="float-right clickable"
                                        (click)="updateEmailPhoneNumber('email',userData.email,userData.countryCodeTemp, userData.phoneNumberTemp, 'membershipStatus' )"
                                        src="/assets/images/static/info.svg" alt=""></span></div>
                            <div class="clearfix"></div>
                            <div class="text-center small-text c-darkgray py-2">{{userData.planType}}</div>
                            <div *ngIf="userData.remainingDays == 'expired'" class="text-center small-text c-darkgray">
                                {{userData.previousPlan}} connect</div>
                            <div class="text-center small-text c-darkgray pb-2"><i>{{userData.remainingDays}}</i></div>
                            <div class="pt-2 d-flex" *ngIf="userData.planExpireOn">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Expires on
                                </div>
                                <div class="c-darkgray small-text account-detail-answer">
                                    {{userData.planExpireOn | date:'medium'}}
                                </div>
                            </div>
                        </div>


                    </div>

                    <!-- Device Information -->
                    <!-- <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">
                        <div class="float-right pt-3">
                            <img src="../../../assets/images/static/info.svg" height="22" width="22"
                                alt="history-icon" />
                        </div>
                        <div class="oswald c-darkgray subheading pt-3">Device Information</div>
                        <div class="clearfix"></div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                OS
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                iOS / 13.3.1
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Device
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                iPhone / mobile...
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Browser
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                Mobile Safari...
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                IP address
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                216.3. 128.12
                            </div>
                        </div>
                        <div class="pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Developer data
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                "Mozilla/5.0...
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- Middle View -->
            <div class="col-6 px-0">
                <div class="row mx-0">
                    <!-- Profile Icon -->
                    <div class="col-12 px-0 box-shadow-section bg-white mt-3">
                        <div class="row mx-0">
                            <ng-container *ngFor="let item of matchesIconArr;let i = index">
                                <button matRipple class="col px-0 text-left pl-1" [matTooltip]="item.toolTipText"
                                    matTooltipClass="toolTipBgColor"
                                    [ngClass]="i === selMatchAction ? 'sel-matches-icon-class':'matches-icon-class'"
                                    (click)="userMatcheAction(i,item.count)">
                                    <img [src]="i === selMatchAction ? selImagePath : item.icon" height="25" alt="icon">
                                    <span class="pr-1"
                                        [ngClass]="i === selMatchAction ? 'sel-matches-icon-font-class':'matches-icon-font-class'">
                                        {{item.count}}
                                    </span>
                                </button>
                            </ng-container>
                        </div>
                    </div>

                    <!-- Tab -->
                    <!--whenever add or remove tab, please update tabIndex in the ts file-->
                    <div class="col-12 px-0 mt-3 bg-white">
                        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="tabSelection"
                            (selectedTabChange)="tabChange($event)">
                            <!-- The purpose is only if user select that  icon-button the mat tab will look unselect  -->
                            <mat-tab>
                                <ng-template matTabContent>
                                    <app-user-matches-action
                                        [userIndex]="selMatchAction === 0 ? 1 : selMatchAction === 1 ? 10 : selMatchAction">
                                    </app-user-matches-action>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="ABOUT">
                                <ng-template matTabContent>
                                    <app-about></app-about>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="PREFERENCES">
                                <ng-template matTabContent>
                                    <app-preferences></app-preferences>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="PHOTOS">
                                <ng-template matTabContent>
                                    <app-photos></app-photos>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="SCREENER">
                                <ng-template matTabContent>
                                    <app-screener></app-screener>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="NOTES">
                                <ng-template matTabContent>
                                    <app-support-notes></app-support-notes>
                                </ng-template>
                            </mat-tab>
                            <mat-tab label="EDITS">
                                <ng-template matTabContent>
                                    <app-edits></app-edits>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>

            <!-- Right View -->
            <div class="col-3">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="row mx-0 mt-2">
                            <div class="col-6 px-0">
                                <button mat-raised-button class="c-white bg-btn-primary w-100"
                                    (click)="navigateToUserview()">USER VIEW</button>
                            </div>
                            <div class="col-6 px-0">
                                <div class="row ml-1">
                                    <div class="col-11 px-0">
                                        <button mat-flat-button class="w-100 bg-btn-primary">
                                            <img src="../../../assets/images/static/followup-active.svg" height="20"
                                                width="20" alt="follow-up-active-icon">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-3 px-0 ml-2">
                                <button mat-flat-button class="w-100 bg-btn-primary">
                                    <img src="../../../assets/images/static/usernotes-inactive.svg" height="20" width="20"
                                        alt="follow-up-inactive-icon">
                                </button>
                            </div> -->
                        </div>
                    </div>
                    <!-- Form -->
                    <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">
                        <!-- <div class="float-right mt-2" *ngIf="profileActionBtn">
                            <button (click)="profileValueUpdate(false)" mat-button class="c-graycolor">CANCEL</button>
                            <button (click)="profileValueUpdate(true)" mat-button class="c-btn-primary">UPDATE</button>
                        </div> -->
                        <div class="oswald c-darkgray subheading pt-3">Profile Value</div>
                        <!-- Profile Quality -->
                        <div class="my-2 pt-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Profile quality
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer ">
                                <select class="input-field" [(ngModel)]="userData.profileQuality"
                                    (change)="profileValue('quality',userData.profileQuality)">
                                    <option value=null disabled>Select</option>
                                    <option [value]="item" *ngFor="let item of profileQuality">{{item}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Diamond -->
                        <div class="my-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Diamond profile
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer ">
                                <select class="input-field" [(ngModel)]="userData.profileDiamond"
                                    (change)="profileValue('diamond',userData.profileDiamond)">
                                    <option value=null disabled>Select</option>
                                    <option [value]="i+1" *ngFor="let item of dimondProfile;let i = index">
                                        {{item}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- Probability -->
                        <div class="my-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                Upgrade probability
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                <select class="input-field" [(ngModel)]="userData.profileUpgrade"
                                    (change)="profileValue('upgrade',userData.profileUpgrade)">
                                    <option value=null disabled>Select</option>
                                    <option [value]="item.value" *ngFor="let item of upgradeProbabilityValue">
                                        {{item.faceValue}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- AC Prospect -->
                        <div class="my-2 d-flex">
                            <div class="c-darkergray small-text mr-1 account-detail-label dark-gray">
                                AC prospect
                            </div>
                            <div class="c-darkgray body-subtext account-detail-answer">
                                <select class="input-field" [(ngModel)]="userData.profileACProspect"
                                    (change)="profileValue('acProspect',userData.profileACProspect)">
                                    <option value=null disabled>Select</option>
                                    <option [value]="item" *ngFor="let item of acProspect">{{item}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <form [formGroup]="userProfileValueForm" class="pt-1"> 
                            <!-- Profile Quality --
                            <div class="my-2 pt-2 d-flex">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Profile quality
                                </div>
                                <div class="c-darkgray body-subtext account-detail-answer ">
                                    <select class="input-field" formControlName="profileQuality"
                                        [ngClass]="{'error': userProfileValueSubmit}">
                                        <option value=null disabled>Select 1, 4, 7, 10</option>
                                        <option *ngFor="let item of profileQuality">{{item}}</option>
                                    </select>
                                    <span
                                        *ngIf="userProfileValueForm.get('profileQuality').hasError('required') && userProfileValueSubmit"
                                        class="c-alert-danger caption">
                                        Required Field
                                    </span>
                                </div>
                            </div>
                            <!-- Diamond --
                            <div class="my-2 d-flex">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Diamond profile
                                </div>
                                <div class="c-darkgray body-subtext account-detail-answer ">
                                    <select class="input-field" formControlName="profileDiamond"
                                        [ngClass]="{'error': userProfileValueSubmit}">
                                        <option value=null disabled>Undecided, Yes, No</option>
                                        <option [value]="i+1" *ngFor="let item of dimondProfile;let i = index">
                                            {{item}}
                                        </option>
                                    </select>
                                    <span
                                        *ngIf="userProfileValueForm.get('profileDiamond').hasError('required') && userProfileValueSubmit"
                                        class="c-alert-danger caption">
                                        Required Field
                                    </span>
                                </div>
                            </div>
                            <!-- Probability --
                            <div class="my-2 d-flex">
                                <div class="c-darkergray small-text mr-1 account-detail-label">
                                    Upgrade probability
                                </div>
                                <div class="c-darkgray body-subtext account-detail-answer">
                                    <select class="input-field" formControlName="profileUpgrade"
                                        [ngClass]="{'error': userProfileValueSubmit}">
                                        <option value=null disabled>Select 10, 40, 70, 100</option>
                                        <option [value]="item.value" *ngFor="let item of upgradeProbabilityValue">
                                            {{item.faceValue}}
                                        </option>
                                    </select>
                                    <span
                                        *ngIf="userProfileValueForm.get('profileUpgrade').hasError('required') && userProfileValueSubmit"
                                        class="c-alert-danger caption">
                                        Required Field
                                    </span>
                                </div>
                            </div>
                            <!-- AC Prospect --
                            <div class="my-2 d-flex">
                                <div class="c-darkergray small-text mr-1 account-detail-label dark-gray">
                                    AC prospect
                                </div>
                                <div class="c-darkgray body-subtext account-detail-answer">
                                    <select class="input-field" formControlName="profileACProspect"
                                        [ngClass]="{'error': userProfileValueSubmit}">
                                        <option value=null disabled>Yes, No, Maybe</option>
                                        <option *ngFor="let item of acProspect">{{item}}</option>
                                    </select>
                                    <span
                                        *ngIf="userProfileValueForm.get('profileACProspect').hasError('required') && userProfileValueSubmit"
                                        class="c-alert-danger caption">
                                        Required Field
                                    </span>
                                </div>
                            </div>
                         </form> -->

                    </div>

                    <div class="col-12 px-0 mt-3" *ngIf="userType === 'supervisor'">
                        <button mat-raised-button class="c-white bg-btn-primary w-100"
                            (click)="matchRegenerate()">REGENERATE MATCHES</button>
                        <div class="opensans-regular pt-2 text-left body-subtext"
                            *ngIf="userData.regenrateMatchTimeStamp"> Last generated: <span class="opensans-light">
                                {{userData.regenrateMatchTimeStamp | date:'medium'}}</span> </div>
                    </div>

                    <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">

                        <div class="row mx-0 mt-2">
                            <!-- Action Button 4 -->

                            <!-- Phone -->
                            <div class="col-6 pl-0 pr-1">
                                <button mat-flat-button class="w-100 bg-btn-primary">
                                    <img src="../../../assets/images/static/call-white.svg" height="30" width="30"
                                        alt="phone-icon">
                                </button>
                            </div>
                            <!-- Mail -->
                            <div class="col-6 pl-1 pr-0">
                                <button mat-flat-button class="w-100 bg-btn-primary" (click)="templateopen()">
                                    <img src="../../../assets/images/static/email-white.svg" height="30" width="30"
                                        alt="email-icon">
                                </button>
                            </div>
                            <!-- Chat -->
                            <div class="col-6 pl-0 pr-1 py-2">
                                <button mat-flat-button class="w-100 bg-btn-primary">
                                    <img src="../../../assets/images/static/chat-white.svg" height="30" width="30"
                                        alt="chat-icon">
                                </button>
                            </div>
                            <!-- Notification -->
                            <div class="col-6 py-1 pl-1 pr-0 py-2">
                                <button mat-flat-button class="w-100 bg-btn-primary">
                                    <img src="../../../assets/images/static/notification-white.svg" height="30"
                                        width="35" alt="notification-icon">
                                </button>
                            </div>

                            <!-- Talk to User -->
                            <div class="col-12 px-0 mt-1">
                                <div class="float-right">
                                    <mat-slide-toggle [disabled]="userData.isTalkedUser || !userData.phone" class=""
                                        [(ngModel)]="userData.isTalkedUser" 
                                        (change)="istalkedUser($event)"></mat-slide-toggle>
                                </div>
                                <div class="c-darkgray body-">Talked to this user</div>
                            </div>

                            <!-- <div class="col-12 px-0 mt-2">
                                <div class="oswald c-darkgray subheading text-center ">Timeline</div>
                                <div class="row mx-0 my-3">
                                    <ng-container *ngFor="let item of timelineArr;let i = index">
                                        <button class="text-center col px-0 bg-white border-0"
                                            (click)="filterTimeline(i)">
                                            <img [src]="i === selTimelineIconIndex ? selTimelineIcon : item" alt="icon"
                                                height="25">
                                        </button>
                                    </ng-container>
                                </div>
                                <div class="timeline-height">
                                    <ng-container *ngFor="let item of [0,1,2,3,4,5,6,7,8,9]">
                                        <div class="d-flex mt-4">
                                            <div class="px-2">
                                                <img src="../../../assets/images/static/sms-gray.svg" height="25"
                                                    width="30" alt="icon">
                                            </div>
                                            <div class="position-relative">
                                                <div class="oswald body-subtext c-darkgray mb-2">
                                                    Thu, 12 May, 2020 10:34 PM
                                                </div>
                                                <div
                                                    [ngClass]="readMoreIndex === item ? 'timeline-content-readLess' : 'timeline-content'">
                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita,
                                                    assumenda sequi similique tempore nemo eius nihil illum fuga tempora
                                                    amet vero ipsa voluptatum. Aperiam earum ex, molestiae quas quasi
                                                    quod.
                                                </div>
                                                <div class="position-absolute"
                                                    [ngClass]="readMoreIndex === item ? 'timeline-readLess' : 'timeline-readMore'"
                                                    (click)="readMoreIndex = readMoreIndex === item ? -1 : item;">
                                                    {{readMoreIndex === item ? 'READ LESS' : '&nbsp;...READ MORE'}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!hasApiResponse" class="col-12 px-0 jystify-content-center">
            <div class="row mx-0 ph-item justify-content-between">
                <div class="col-3 pt-2 ph-item-other-tile">
                    <div class="row mx-0 m-2">
                        <div style="width: 300px; height: 260px; background-color: #ced4da;"></div>
                    </div>
                    <div class="row mx-0 mb-3">
                        <div class="col-12 px-0 mt-1 mb-1" *ngFor="let item of [1,2,3,4,5,6,7,8]">
                            <div class="d-flex my-1 ml-1">
                                <div style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                </div>
                                <div class="ml-3 mt-1" style="width: 200px;height: 12px; background-color: #ced4da;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pt-2">
                    <div class="row mx-0 mb-3 mat-elevation-z5 ph-item">
                        <div class="col-12 px-0 pl-2 pt-3">
                            <div class="row mx-0">
                                <div class="col- px-0 mt-3 mb-3" *ngFor="let item of [1,2,3,4,5,6,7,8,9]">
                                    <div class="d-flex my-2 ml-1">
                                        <div class="ml-3 mt-1"
                                            style="width: 50px;height: 8px; background-color: #ced4da;">
                                        </div>
                                    </div>

                                </div>
                                <div class="col-4 px-0 mt-3 mb-3" *ngFor="let item of [1,2,3]">
                                    <div class="d-flex my-2 ml-1">
                                        <div class="ml-3 mt-1"
                                            style="width: 150px;height: 30px; background-color: #ced4da;"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3  mat-elevation-z5 ph-item">
                        <div class="col-12 px-0">

                            <div class="row mx-0 m-3">
                                <div class="col-12 px-0">
                                    <div *ngFor="let item of [1,2,3,4,5]">
                                        <div class="d-flex my-2 ml-1">
                                            <div
                                                style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                            </div>
                                            <div class="ml-3 mt-1"
                                                style="width: 100px;height: 10px; background-color: #ced4da;"></div>
                                        </div>
                                        <div class="d-flex my-2 ml-1">
                                            <div class=" mt-1"
                                                style="width: 150px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 px-0">
                                    <div *ngFor="let item of [1,2,3,4,5]">
                                        <div class="d-flex my-2 ml-1">
                                            <div
                                                style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                            </div>
                                            <div class="ml-3 mt-1"
                                                style="width: 100px;height: 10px; background-color: #ced4da;"></div>
                                        </div>
                                        <div class="d-flex my-2 ml-1">
                                            <div class=" mt-1"
                                                style="width: 150px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3  mat-elevation-z5 pt-2 ph-item-other-tile">
                    <div class="row mx-0 mb-3 mat-elevation-z5">
                        <div class="col-12 px-0 jystify-content-center">
                            <div class="d-flex mb-3">
                                <span class="ml-2 mt-2"
                                    style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                                <span class="ml-1 mt-2"
                                    style="width: 120px; height: 40px; background-color: #ced4da;"></span>

                            </div>
                            <div class="d-flex mb-3">
                                <span class="ml-2 mt-2"
                                    style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                                <span class="ml-1 mt-2"
                                    style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-2 mat-elevation-z5">
                        <div class="ml-3 mt-1 text-center"
                            style="width: 150px;height: 10px; background-color: #ced4da;">
                        </div>
                        <div class="col-12 px-0 jystify-content-center" *ngFor="let item of [1,2,3,4]">
                            <div class="d-flex mb-3">
                                <span class="ml-3 mt-2"
                                    style="width: 200px; height: 30px; background-color: #ced4da;"></span>

                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3  mat-elevation-z5 ph-item">
                        <div class="col-12 px-0">

                            <div class="row mx-0 m-3">
                                <div class="col-12 px-0">
                                    <div *ngFor="let item of [1,2,3,4,5]">
                                        <div class="d-flex my-2 ml-1">
                                            <div
                                                style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                            </div>
                                            <div class="ml-3 mt-1"
                                                style="width: 50px;height: 10px; background-color: #ced4da;"></div>
                                        </div>
                                        <div class="d-flex my-2 ml-1">
                                            <div class=" mt-1"
                                                style="width: 100px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>