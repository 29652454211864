import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { RemoveUserDialogComponent } from './remove-user-dialog/remove-user-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';

@Component({
  selector: 'app-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.css']
})
export class AdminConsoleComponent implements OnInit {
  memberList: any;
  constructor(private router: Router, private localService: LocalStorageService, private md: MatDialog, private sharedService: SharedService, private apiService: ApiService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.sharedService.supportUserData) {
      this.memberList = this.sharedService.supportUserData;
    } else {
      const loaderDialog = this.md.open(LoadingScreenComponent, { disableClose: true });
      this.apiService.getsupportuserlist().subscribe(res => {
        if (res) {
          loaderDialog.close();
          this.memberList = res;
          this.sharedService.supportUserData = res;
        }
      }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localService.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          this.authService.gettingServerError();
        }
      });
    }

  }

  addMember(data) {
    this.localService.setToLocal('visitType', data);
    this.router.navigate(['/admin-console/add-user']);
  }

  memberForm(data, isKicked, ind) {
    if (!isKicked) {
      this.localService.setToLocal('visitType', data);
      this.router.navigate(['/admin-console/add-user']);
      const userData = this.memberList[ind];
      const userDetails = {
        userId: userData.userid,
        name: userData.name,
        role: userData.issupervisor ? 'Supervisor': 'Reviewer',
        email: userData.email,
        phone: userData.phonenumber
      }
      this.sharedService.userEditDetails = userDetails;
      // this.localService.setToLocal('userEditDetails', userDetails);
    }
  }

  removeUser(isKicked, userData, id) {
    if (!isKicked) {
      const removeUserDialog = this.md.open(RemoveUserDialogComponent, {
        height: '40vh',
        width: '60vh',
        data: { userDetails: userData, userId: id }
      });
      removeUserDialog.afterClosed().subscribe(res => {
          if (res == 'userkicked') {
            this.ngOnInit();
          }
      });
    }

  }

}
