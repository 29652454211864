<div class="row mx-0 parent-class">


    <div *ngIf="hasApiResponse" class="col-12 px-0 justify-content-center">
        <div class="row mx-0">
            <div class="col-12 px-0">

                <div class="row mx-0">
                    <!-- side panel menu -->
                    <div id="mySidepanel" class="sidepanel" *ngIf="!singleModerationClick">

                        <div class="pl-4">
                            <img class="closebtn" (click)="closeNav()"
                                src="../../../assets/images/static/filter-close.svg" alt="">

                            <br>
                            <span class="oswald j-h3 c-white">Filters</span><br>
                            <span class="position-absolute" style="top:135px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Name moderation' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Name-moderation')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Name-moderation')"> Name moderation</label><br>

                            </span>
                            <span class="position-absolute" style="top:175px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Profile-quality' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Profile-quality')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Profile-quality')"> Profile quality</label><br>
                            </span>
                            <span class="position-absolute" style="top:215px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Image-moderation' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Image-moderation')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Image-moderation')"> Image moderation</label><br>
                            </span>
                            <span class="position-absolute" style="top:255px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Video-moderation' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Video-moderation')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Video-moderation')"> Video Moderation</label><br>

                            </span>

                            <span class="position-absolute" style="top:295px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Not-screened' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Not-screened')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Not-screened')"> Not screened</label><br>

                            </span>
                            <span class="position-absolute" style="top:335px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Screening on hold' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Screening on hold')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Screening on hold')"> Screening on hold</label><br>

                            </span>
                            <span class="position-absolute" style="top:375px;">
                                <span [ngClass]="checkFilterOn == 'Fake-user' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Fake-User')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Fake-User')"> Fake user</label><br>
                            </span>


                            <span class="position-absolute" style="top:415px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Unmoderated-profiles' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Unmoderated-profiles')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Unmoderated-profiles')"> Unmoderated
                                    profiles</label><br>
                            </span>
                            <span class="position-absolute" style="top:455px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Personalized-assistance' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Personalized-assistance')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Personalized-assistance')"> Personalized
                                    assistance?</label><br>
                            </span>
                            <span class="position-absolute" style="top:495px;">
                                <span
                                    [ngClass]="checkFilterOn == 'Deletion-request' ? 'selectCheckBoxClass' : 'checkBoxClass'"
                                    (click)="appliedFilter('Deletion-request')"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilter('Deletion-request')"> Deletion request</label><br>
                            </span>

                            <span class="oswald j-h3 c-white pb-4 position-absolute" style="top:575px;">Sort
                                By</span><br>
                            <span class="position-absolute" style="top:615px;">
                                <span (click)="appliedFilterByOrder('Ascending-order')"
                                    [ngClass]="!SortingOrder ? 'checkedradioButtonClass' : 'radioButtonClass'"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilterByOrder('Ascending-order')"> Ascending order</label><br>
                            </span>
                            <span class="position-absolute" style="top:655px;">
                                <span (click)="appliedFilterByOrder('Descending-order')"
                                    [ngClass]="SortingOrder ? 'checkedradioButtonClass' : 'radioButtonClass'"></span>
                                <label class="oswald subheading c-white leftAligmnment clickable"
                                    (click)="appliedFilterByOrder('Descending-order')">Descending order</label><br>
                            </span>
                        </div>
                    </div>
                    <div class="px-0" *ngIf="!singleModerationClick">
                        <img class="openbtn" (click)="openNav()" src="../../../assets/images/static/Filter.svg" alt="">
                    </div>

                    <div class="col-11 mx-auto px-0 h-100 pb-3 text-center" style="margin-top: 3%;">
                        <div class="row mx-0 mb-3">
                            <div class="col-12 px-0 pr-3 pt-3 filterText" *ngIf="!singleModerationClick">
                                <span class="text-center oswald j-h2 c-darkergray"
                                    style="margin-left: 5%;">{{printFilterOn}}</span>


                                <span class="float-right oswald subheading c-darkgray" style="margin-right: 4%;">Total
                                    users: <span *ngIf="singleModerationClick">1</span> <span
                                        *ngIf="!singleModerationClick">{{totalUsers}} </span>
                                    <span *ngIf="displayDataArray.length  > 0">(Page {{displayPageNumber}})</span>
                                </span>
                                <span class="float-right c-darkgray bg-white opensans-regular mr-3 p-1"><img height="10"
                                        width="20" src="/assets/images/static/sortby.svg"
                                        alt="sortby-icon">{{sortBy}}</span>

                            </div>
                            <div class="col-12 px-0 pt-3 filterText text-center oswald j-h2 c-darkergray"
                                *ngIf="singleModerationClick">
                                Moderation view
                            </div>
                        </div>
                        <div class="row mx-0 h-100 mb-2" *ngFor="let pitem of displayDataArray; let pindex = index">
                            <!-- Left View -->
                            <div class="col-3">
                                <div class="row mx-0">

                                    <!-- userImage and Icon -->
                                    <div class="col-12 px-0 box-shadow-section bg-white">
                                        <div class="">
                                            <div class="position-relative user-image-container">
                                                <div class="position-absolute user-image"
                                                    [ngStyle]="{'background-image': 'url('+ pitem.profileImage +')'}">
                                                    <div class="position-absolute photo-counter"
                                                        matTooltipClass="toolTipBgColor" matTooltip="Photo Counter">

                                                        <div class="position-relative">
                                                            <img src="../../../assets/images/static/photo-counter.svg"
                                                                height="25" width="25" alt="photo-counter">
                                                            <span
                                                                class="d-block position-absolute photo-counter-font">{{pitem.photoCounter}}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="position-absolute user-plan-type-container c-white px-2">
                                                        <span
                                                            *ngIf="pitem.planType && pitem.remainingDays != 'expired'">
                                                            <i *ngIf="pitem.planType == 'Assisted connect'">Assisted
                                                                Connect</i>
                                                            <i *ngIf="pitem.planType == 'Express connect'">Express
                                                                Connect</i>
                                                            <i *ngIf="pitem.planType == 'Quick connect'">Quick
                                                                Connect</i>
                                                        </span>
                                                        <span *ngIf="!pitem.planType"><i>Free Member</i></span>
                                                        <span *ngIf="pitem.remainingDays == 'expired'"><i>Free
                                                                Member</i></span>
                                                    </div>
                                                    <div
                                                        class="position-absolute bg-white user-verified-icon-container">
                                                        <ul class="list-inline py-1 d-flex m-0">

                                                            <li>
                                                                <span class="p-1" *ngIf="pitem.isphoneverified">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/mobile-verified.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                                <span class="p-1" *ngIf="!pitem.isphoneverified">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/mobilel-verified-grey.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                                <span class="p-1" *ngIf="pitem.isemailverified">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/email-verified.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                                <span class="p-1" *ngIf="!pitem.isemailverified">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/email-verified-grey.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                                <span class="p-1"
                                                                    *ngIf="pitem.isvideoDecline && pitem.isvideoDecline != 'empty'">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/video-verified-grey.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                                <span class="p-1"
                                                                    *ngIf="!pitem.isvideoDecline && pitem.isvideoDecline != 'empty'">
                                                                    <img height="20" width="20"
                                                                        src="/assets/images/static/video-verified-blue-icon.svg"
                                                                        alt="verified-icon">
                                                                </span>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Basic Inforamtion -->
                                    <div class="col-12 px-0 text-left box-shadow-section bg-white">
                                        <div class="p-2 d-flex clickable" (click)="navigateToProfileView(pitem.userId)">
                                            <div class="" matTooltipClass="toolTipBgColor"
                                                [matTooltip]="pitem.ismale ? 'Male' : 'Female'">
                                                <img [src]="pitem.ismale ? '../../../assets/images/static/male-icon.svg' : '../../../assets/images/static/female-icon.svg'"
                                                    height="30" width="30" alt="gender-icon">
                                            </div>


                                            <div class="oswald c-darkgray j-h3 alias-bottom">
                                                {{pitem.alias}}
                                                <span class="opensans-light c-darkgray body-subtext"
                                                    style="color: #1b75bb;text-decoration: underline;">#
                                                    {{pitem.userId}}</span>
                                            </div>
                                        </div>
                                        <div class="col-12 px-2 pb-2" style="word-wrap: break-word;">
                                            <div class="pl-3 opensans-regular">
                                                <div *ngIf="pitem.prospectFirstName" class="c-darkgray body-subtext">
                                                    <span>{{pitem.prospectFirstName}}</span>
                                                    <span class="pl-1">{{pitem.prospectLastName}}</span>
                                                </div>
                                                <div class="c-darkgray body-subtext pt-2"
                                                    [ngClass]="pitem.prospectFirstName ? 'pt-0' : 'pt-2'">
                                                    <span>{{pitem.firstName}}</span>
                                                    <span class="pl-1">{{pitem.lastName}} </span>
                                                    <span *ngIf="pitem.whom !== 'Self'"> - {{pitem.whom}}</span>
                                                </div>
                                                <div class="c-darkgray body-subtext pt-2">
                                                    {{pitem.age}} years | {{pitem.height}}
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngFor="let item of pitem.basicLeftView">
                                            <div class="d-flex px-2" matTooltipClass="toolTipBgColor"
                                                [matTooltip]="item.toolTipText">
                                                <img [src]="item.icon" height="22" width="22" alt="icon">
                                                <div class="c-darkgray body-subtext pl-2 opensans-light">
                                                    {{item.dateFlag === item.parameter ? (item.parameter | date: 'MMM,
                                                    yyy') :
                                                    item.parameter}}
                                                    <br />
                                                    <span class="small-text c-darkergray">{{item.subparameter}}</span>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div class="d-flex px-2" matTooltipClass="toolTipBgColor"
                                            [matTooltip]="pitem.languages.toolTipText">
                                            <img [src]="pitem.languages.icon" height="22" width="22" alt="icon">
                                            <div class="c-darkgray body-subtext pl-2"
                                                *ngIf="pitem.languages.parameter.length > 0; else noLanguages">
                                                <ng-container *ngFor="let item of pitem.languages.parameter">
                                                    <i *ngIf="item.fluency==='mother'">{{item.language}}</i>
                                                    <b *ngIf="item.fluency==='complete'">, {{item.language}}</b>
                                                    <span
                                                        *ngIf="item.fluency !=='mother' && item.fluency !=='complete'">,
                                                        {{item.language}}</span>
                                                </ng-container>
                                            </div>
                                            <ng-template #noLanguages>
                                                <div class="c-darkgray body-subtext pl-2">
                                                    N.A.
                                                </div>
                                            </ng-template>
                                        </div>
                                        <!-- action buttons for mark as fake/screened -->
                                        <div class="pt-3 d-flex">
                                            <button matRipple class="w-100 body-subtext" *ngIf="checkisReviewer"
                                                [ngClass]="pitem.fakeUserFlag ? 'fake-user-flag-button-active':'fake-user-flag-button bg-white'"
                                                (click)="fakeUser(pindex, !pitem.fakeUserFlag, pitem.userId)">
                                                {{pitem.fakeUserFlag ? 'FLAGGED AS FAKE': 'FLAG AS FAKE'}}
                                            </button>
                                            <button matRipple class="w-50 small-text" *ngIf="!checkisReviewer"
                                                [ngClass]="pitem.fakeUserFlag ? 'fake-user-flag-button-active':'fake-user-flag-button bg-white'"
                                                (click)="fakeUser(pindex, !pitem.fakeUserFlag, pitem.userId)">
                                                {{pitem.fakeUserFlag ? 'FLAGGED AS FAKE': 'FLAG AS FAKE'}}
                                            </button>
                                            <button matRipple class="w-50 small-text c-white position-relative"
                                                *ngIf="!checkisReviewer"
                                                [disabled]="pitem.selectedProfileStatus.heading === 'SCREENED' ? true : false"
                                                [ngStyle]="{'background-color': pitem.selectedProfileStatus.color,'border': '1px solid'+pitem.selectedProfileStatus.color}"
                                                [matMenuTriggerFor]="status"
                                                [ngClass]="pitem.selectedProfileStatus.heading === 'SCREENED' ? '' : 'profile-status-button'">
                                                {{pitem.selectedProfileStatus.heading}}
                                            </button>
                                            <mat-menu #status="matMenu">
                                                <button mat-menu-item
                                                    *ngFor="let item of pitem.profileStatus;let i = index"
                                                    (click)="changeProfileStatus(pindex,i, pitem.userId, item.heading)">{{item.heading}}</button>
                                            </mat-menu>
                                        </div>

                                        <!-- <div *ngIf="pitem.isfakeprofile" class="p-2 d-flex">
                                            <button mat-button class="bg-blue c-white" (click)="fakeUser(pindex,false, pitem.userId)">Undo</button>
                                        </div> -->
                                    </div>

                                    <!-- flagged as fake dialog -->
                                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2"
                                        *ngIf="pitem.fakeUserFlag">
                                        <div class="oswald c-darkgray subheading pt-3 pb-2">Reason For Fake Profile
                                        </div>
                                        <div class="c-darkergray small-text mr-2 pt-1" style=" word-wrap: break-word;">
                                            <div [innerHtml]="pitem.fakeReasons"></div>
                                        </div>
                                    </div>

                                    <!--flagged as hold dialog -->
                                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2"
                                        *ngIf="pitem.holdUserFlag">
                                        <div class="oswald c-darkgray subheading pt-3 pb-2">Reason for profile on hold:
                                        </div>
                                        <div class="c-darkergray small-text mr-2 pt-1" style=" word-wrap: break-word;">
                                            <div [innerHtml]="pitem.HoldReasons"></div>
                                        </div>
                                    </div>


                                    <!-- Account Details -->
                                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2">
                                        <div class="oswald c-darkgray subheading pt-3">Account Details</div>
                                        <div class="clearfix"></div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                User Name
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer"
                                                [attr.contenteditable]="pitem.editable" #accountDetails
                                                style=" word-wrap: break-word;">
                                                <span matTooltipClass="toolTipBgColor"
                                                    [matTooltip]="pitem.firstName">{{pitem.firstName}} </span>
                                                <span matTooltipClass="toolTipBgColor"
                                                    [matTooltip]="pitem.lastName">{{pitem.lastName}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="pitem.prospectFirstName" class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Prospect name
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer"
                                                [attr.contenteditable]="pitem.editable" #accountDetails
                                                style=" word-wrap: break-word;">
                                                <span matTooltipClass="toolTipBgColor"
                                                    [matTooltip]="pitem.prospectFirstName">{{pitem.prospectFirstName}}
                                                </span>
                                                <span matTooltipClass="toolTipBgColor"
                                                    [matTooltip]="pitem.prospectLastName">{{pitem.prospectLastName}}</span>
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Email address
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer text-with-dot"
                                                [attr.contenteditable]="pitem.editable" #accountDetails
                                                matTooltipClass="toolTipBgColor" [matTooltip]="pitem.email">
                                                {{pitem.email}}
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Mobile number
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer pt-1"
                                                [attr.contenteditable]="pitem.editable" #accountDetails
                                                matTooltipClass="toolTipBgColor" [matTooltip]="pitem.phone">
                                                {{pitem.phone}}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <!-- Middle View -->
                            <div class="col-6 px-0">
                                <div class="row mx-0">
                                    <!-- Profile Icon -->
                                    <div class="col-12 px-0 box-shadow-section bg-white">
                                        <div class="row mx-0">
                                            <ng-container *ngFor="let item of pitem.matchesIconArr;let i = index">
                                                <button matRipple class="col px-0 text-left pl-1"
                                                    matTooltipClass="toolTipBgColor" [matTooltip]="item.toolTipText"
                                                    [ngClass]="i === pitem.selMatchAction ? 'sel-matches-icon-class':'matches-icon-class'"
                                                    (click)="userMatcheAction(pindex, i)">
                                                    <img [src]="i === pitem.selMatchAction ? pitem.selImagePath : item.icon"
                                                        height="25" alt="icon">
                                                    <span class="pr-1"
                                                        [ngClass]="i === pitem.selMatchAction ? 'sel-matches-icon-font-class':'matches-icon-font-class'">
                                                        {{item.count}}
                                                    </span>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <!-- tabs  -->
                                    <div class="col-12 px-0 mt-3 bg-white">
                                        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="pitem.tabSelection"
                                            (selectedTabChange)="tabChange($event, pindex,pitem.userId)">
                                            <!-- The purpose is only if user select that  icon-button the mat tab will look unselect  -->
                                            <mat-tab>
                                                <ng-template matTabContent>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="ABOUT">
                                                <ng-template matTabContent>
                                                    <div class="row mx-0">
                                                        <!-- Moderation Info -->
                                                        <div class="col-12 px-0 text-left box-shadow-section">
                                                            <div class="row mx-0">
                                                                <div class="col-12 box-shadow-section py-3 clickable"
                                                                    (click)="getmoderationInfoData(pindex)">
                                                                    <div class="float-right">
                                                                        <img [src]="btnObj.directionBtn"
                                                                            alt="direction-arrow" height="20" width="20"
                                                                            class="transitionClass"
                                                                            [ngStyle]="{'transform': pitem.moderationPanelOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
                                                                    </div>
                                                                    <div class="oswald c-darkgray subheading text-left">
                                                                        Moderation Info
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 py-2 opensans-light show"
                                                                    *ngIf="pitem.moderationPanelOpen">
                                                                    <div class="row mx-0">

                                                                        <!-- first name -->
                                                                        <div class="col-12 px-0 my-3">
                                                                            <div
                                                                                class="oswald c-darkgray body-subtext mb-2">
                                                                                First name </div>
                                                                            <div class="row mx-0">
                                                                                <div class="col-8 px-0 my-1">
                                                                                    <div class="c-darkergray body-subtext"
                                                                                        [ngClass]="pitem.editFirstName ? 'edit-university-company-class' : 'text-with-dot'"
                                                                                        [attr.contenteditable]="pitem.editFirstName"
                                                                                        #firstName>
                                                                                        {{pitem.userFirstName}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-4 px-0"
                                                                                    *ngIf="pitem.userFirstName != 'N.A.'">
                                                                                    <div class="row mx-0">
                                                                                        <div class="col-2 px-1">
                                                                                            <!-- <button mat-icon-button
                                                                                                matTooltip="Edit"
                                                                                                matTooltipClass="toolTipBgColor"
                                                                                                class="border-0 bg-white rounded-circle position-relative btn-container"
                                                                                                >
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.edit"
                                                                                                    height="25"
                                                                                                    width="25"
                                                                                                    alt="edit-btn"
                                                                                                    (click)="editFirstNameFunction(pindex)">
                                                                                            </button> -->
                                                                                        </div>
                                                                                        <div class="col-8 px-1">
                                                                                            <button matRipple
                                                                                                *ngIf="!checkisReviewer"
                                                                                                class="w-100 pb-1"
                                                                                                [disabled]="pitem.firstNameButtonDisable"
                                                                                                (click)="modeateFirstName(pindex,true, pitem.userId)"
                                                                                                [ngClass]="[pitem.firstNameleftBtnColor, 'moderation-word-bg-'+pitem.firstNameleftBtnColor]"
                                                                                                style="border-right: 1px solid white;">
                                                                                                <img *ngIf="pitem.firstNameleftBtnColor !== 'green'"
                                                                                                    [src]="pitem.firstNameleftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                                    alt="btn-tick"
                                                                                                    height="15"
                                                                                                    width="15">
                                                                                                <span
                                                                                                    *ngIf="pitem.firstNameleftBtnColor === 'green'"
                                                                                                    matTooltip="APPROVED"
                                                                                                    matTooltipClass="toolTipBgColor"
                                                                                                    class="c-white small-text position-relative d-block collegeBtnText">
                                                                                                    APPROVED
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>

                                                                                        <div class="col-2 px-1 text-center"
                                                                                            style="margin-top: -3px;">
                                                                                            <button mat-icon-button
                                                                                                *ngIf="pitem.valueAcceptedRejectedBy"
                                                                                                class="border-0 bg-white rounded-circle position-relative btn-container"
                                                                                                matTooltipClass="toolTipInfoBgColor"
                                                                                                [title]="parsedText(pitem.valueAcceptedRejectedBy)">
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.info"
                                                                                                    height="25"
                                                                                                    width="25"
                                                                                                    alt="edit-btn">
                                                                                                <span>
                                                                                                </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <!--Words that best describe - -->
                                                                        <div class="col-12 px-0">
                                                                            <div class="c-darkgray body-subtext my-2 oswald">
                                                                                Words that best describe
                                                                            </div>
                                                                            <div class="d-flex"
                                                                                *ngIf="pitem.moreAboutMyself.wordsDescribeMe.length > 0; else noWordsDescribeMe">
                                                                                <ul class="list-inline"
                                                                                    style="width: 90%;">
                                                                                    <ng-container
                                                                                        *ngFor="let item of pitem.moreAboutMyself.wordsDescribeMe">
                                                                                        <li class="list-inline-item mb-2"
                                                                                            *ngIf="item.customized; else notCustomized">
                                                                                            <span
                                                                                                *ngIf="!item.approvedBySupervisor"
                                                                                                class="moderation-icon-container position-relative moderation-icon-tick clickable"
                                                                                                [ngClass]="item.color">
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.tick[item.color]"
                                                                                                    alt="tick-icon"
                                                                                                    height="15"
                                                                                                    width="15">
                                                                                            </span>
                                                                                            <span
                                                                                                class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}"
                                                                                                [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                                                                                {{item.name}}
                                                                                            </span>
                                                                                            <span
                                                                                                *ngIf="!item.declineBySupervisior"
                                                                                                class="moderation-icon-container position-relative moderation-icon-cross clickable"
                                                                                                [ngClass]="item.color">
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.cross[item.color]"
                                                                                                    alt="cross-icon"
                                                                                                    height="12"
                                                                                                    width="12">
                                                                                            </span>
                                                                                        </li>
                                                                                        <ng-template #notCustomized>
                                                                                            <li
                                                                                                class="small-text c-darkgray list-inline-item mb-2">
                                                                                                #{{item.name}}</li>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                </ul>
                                                                                
                                                                            </div>
                                                                            <ng-template #noWordsDescribeMe>
                                                                                <div class="small-text c-darkgray mb-2"
                                                                                    style="width: 100%;">N.A.</div>
                                                                            </ng-template>
                                                                        </div>

                                                                        <!--I am looking for someone who is  -->
                                                                        <div class="col-12 px-0">
                                                                            <div class="c-darkgray body-subtext my-2 oswald">
                                                                                I am looking for someone who is
                                                                            </div>
                                                                            <div class="d-flex"
                                                                                *ngIf=" pitem.moreAboutPatner.lookingFor.length>0; else noLookingFor">
                                                                                <ul class="list-inline"
                                                                                    style="width: 90%;">
                                                                                    <ng-container
                                                                                        *ngFor="let item of pitem.moreAboutPatner.lookingFor">
                                                                                        <li class="list-inline-item mb-2"
                                                                                            *ngIf="item.customized; else notCustomized">
                                                                                            <span
                                                                                                *ngIf="!item.approvedBySupervisor"
                                                                                                class="moderation-icon-container position-relative moderation-icon-tick clickable"
                                                                                                [ngClass]="item.color">
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.tick[item.color]"
                                                                                                    alt="tick-icon"
                                                                                                    height="15"
                                                                                                    width="15">
                                                                                            </span>
                                                                                            <span
                                                                                                class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}"
                                                                                                [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                                                                                {{item.name}}
                                                                                            </span>
                                                                                            <span
                                                                                                *ngIf="!item.declineBySupervisior"
                                                                                                class="moderation-icon-container position-relative moderation-icon-cross clickable"
                                                                                                [ngClass]="item.color">
                                                                                                <img class="center-box"
                                                                                                    [src]="btnObj.cross[item.color]"
                                                                                                    alt="cross-icon"
                                                                                                    height="12"
                                                                                                    width="12">
                                                                                            </span>
                                                                                        </li>
                                                                                        <ng-template #notCustomized>
                                                                                            <li
                                                                                                class="small-text c-darkgray list-inline-item mb-2">
                                                                                                #{{item.name}}</li>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                </ul>
                                                                                
                                                                            </div>
                                                                            <ng-template #noLookingFor>
                                                                                <div class="small-text c-darkgray mb-2"
                                                                                    style="width: 100%">N.A.</div>
                                                                            </ng-template>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- Net Promoter Score -->
                                                        <div class="col-12 px-0 text-left box-shadow-section my-2">
                                                            <div class="row mx-0">
                                                                <div class="col-12 box-shadow-section py-3 clickable"
                                                                    (click)="getNPSrating(pindex,pitem.userId)">
                                                                    <div class="float-right">
                                                                        <img [src]="btnObj.directionBtn"
                                                                            alt="direction-arrow" height="20" width="20"
                                                                            class="transitionClass"
                                                                            [ngStyle]="{'transform': pitem.ratingGivenByUser ? 'rotate(90deg)' : 'rotate(0deg)'}">
                                                                    </div>
                                                                    <div class="oswald c-darkgray subheading text-left">
                                                                        Net Promoter Score
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 py-2 opensans-light"
                                                                    *ngIf="pitem.ratingGivenByUser">
                                                                    <ng-container *ngIf="pitem.ratingArray.length == 0">
                                                                        <div
                                                                            class="col-12 py-2 px-0 opensans-regular c-darkgray body-text text-center">
                                                                            Question not answered.
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngFor="let item of pitem.ratingArray; let last = last">
                                                                        <div class="row mx-0 py-1">
                                                                            <div class="col-12 px-0 pb-1">
                                                                                <span
                                                                                    class="opensans-regular c-darkgray body-subtext">
                                                                                    {{item.rating}}
                                                                                </span>
                                                                                <span
                                                                                    class="float-right small-text opensans-regular c-darkgray">
                                                                                    {{item.timestamp | date: 'medium'}}
                                                                                </span>
                                                                                <div class="c-darkgray body-subtext opensans-light"
                                                                                    style="word-wrap: break-word;">
                                                                                    {{item.reason}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>
                                            <mat-tab label="PHOTOS">
                                                <ng-template matTabContent>
                                                    <div class="row mx-0" style="background-color: #f2f2f2;">
                                                        <div class="col-12 mt-3 px-0 opensans-light">
                                                            <div class="row mx-0">

                                                                <!-- for unmoderate video -->
                                                                <div class="col-12 px-0 text-left bg-white box-shadow-section"
                                                                    *ngIf="pitem.video && pitem.video.topBtnColor == 'orange' && pitem.video.bottomBtnColor == 'orange'">
                                                                    <div class="row mx-0">
                                                                        <div class="" style="width: 43%;">
                                                                            <ziggeo-player #ziggeoplayer
                                                                                [apiKey]="apiKey" [options]="{
                                                                                theme:'modern',
                                                                                height: 300,
                                                                                width: 250,
                                                                                video:pitem.video.videoid
                                                                            }">
                                                                            </ziggeo-player>
                                                                        </div>
                                                                        <div class="px-2" style="width: 50%;">

                                                                            <div
                                                                                class="small-text c-darkergray pl-2 pt-3">
                                                                                {{pitem.video.photouploadtime |
                                                                                date:'medium'}}
                                                                            </div>

                                                                            <div class=""
                                                                                *ngIf="pitem.video.reviewerAction === false">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By reviewer
                                                                                    <b>{{pitem.video.reviewedBy}}</b>
                                                                                    {{pitem.video.reviewerModerationTime
                                                                                    | date:'medium'}}
                                                                                    <div
                                                                                        class="small-text c-darkgray py-1">
                                                                                        Reason for rejecting Video</div>
                                                                                </div>

                                                                                <div class="pl-2"
                                                                                    *ngIf="pitem.video.declineReasonsByReviewer">
                                                                                    <div
                                                                                        class="small-text c-darkergray py-1">
                                                                                        {{pitem.video.declineReasonsByReviewer.split(';')[0]}}
                                                                                        <span class="readMore"
                                                                                            (click)="declineDialog(pindex,pitem.userId, true)">READ
                                                                                            MORE</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class=""
                                                                                *ngIf="pitem.video.reviewerAction">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By reviewer
                                                                                    <b>{{pitem.video.reviewedBy}}</b>
                                                                                    {{pitem.video.reviewerModerationTime
                                                                                    | date:'medium'}}
                                                                                </div>
                                                                            </div>


                                                                            <div class=""
                                                                                *ngIf="pitem.video.supervisorAction === false && !pitem.video.unmoderated">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    <span
                                                                                        class="d-inline-block moderation-word-bg-red rounded-circle position-relative"
                                                                                        style="height: 20px;width: 20px;">
                                                                                        <img class="center-box"
                                                                                            [src]="btnObj.cross.white"
                                                                                            alt="tick" height="15"
                                                                                            width="15" />
                                                                                    </span> By supervisor
                                                                                    <b>{{pitem.video.moderatedBy}}</b>
                                                                                    {{pitem.video.supervisorModerationTime
                                                                                    | date:'medium'}}
                                                                                    <div
                                                                                        class="small-text c-darkgray py-1">
                                                                                        Reason for rejecting Video</div>
                                                                                </div>

                                                                                <div class="pl-2"
                                                                                    *ngIf="pitem.video.declineReasonsBySupervisor">
                                                                                    <div
                                                                                        class="small-text c-darkergray py-1">
                                                                                        {{pitem.video.declineReasonsBySupervisor.split(';
                                                                                        ')[0]}}
                                                                                        <span class="readMore"
                                                                                            (click)="declineDialog(pindex,pitem.userId, true)">READ
                                                                                            MORE</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class=""
                                                                                *ngIf="pitem.video.supervisorAction && !pitem.video.unmoderated">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By supervisor
                                                                                    <b>{{pitem.video.moderatedBy}}</b>
                                                                                    {{pitem.video.supervisorModerationTime
                                                                                    | date:'medium'}}
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div class="" style="width: 7%">

                                                                            <button matRipple class="w-100 pb-1 d-block"
                                                                                style="height: 50%;"
                                                                                (click)="userVideoAction(pindex,pitem.userId, true)"
                                                                                [disabled]="pitem.video.disabledselfieVideoButton"
                                                                                [ngClass]="[pitem.video.topBtnColor, 'moderation-word-bg-'+pitem.video.topBtnColor]">
                                                                                <img *ngIf="pitem.video.topBtnColor !== 'green'"
                                                                                    [src]="pitem.video.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                    alt="btn-tick" height="25"
                                                                                    width="25">
                                                                                <span
                                                                                    *ngIf="pitem.video.topBtnColor === 'green'"
                                                                                    matTooltip="APPROVED"
                                                                                    matTooltipClass="toolTipBgColor"
                                                                                    class="d-block c-white small-text">
                                                                                    <ul class="list-inline"
                                                                                        style="margin: auto 0;height: 100%;">
                                                                                        <li
                                                                                            *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                                                                            {{item}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            </button>
                                                                            <button matRipple class="w-100 pb-1 d-block"
                                                                                style="height: 50%;"
                                                                                (click)="declineDialog(pindex,pitem.userId, false)"
                                                                                [ngClass]="[pitem.video.bottomBtnColor, 'moderation-word-bg-'+pitem.video.bottomBtnColor]">
                                                                                <img *ngIf="pitem.video.bottomBtnColor !== 'red'"
                                                                                    [src]="pitem.video.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                                                    alt="cross-btn" height="25"
                                                                                    width="25">
                                                                                <span
                                                                                    *ngIf="pitem.video.bottomBtnColor === 'red'"
                                                                                    matTooltip="REJECTED"
                                                                                    matTooltipClass="toolTipBgColor"
                                                                                    class="d-block c-white small-text">
                                                                                    <ul class="list-inline"
                                                                                        style="margin: auto 0;height: 100%;">
                                                                                        <li
                                                                                            *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                                                                            {{item}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                

                                                                <ng-container
                                                                    *ngFor="let item of pitem.photoArr;let i = index">

                                                                    <div
                                                                        class="col-12 px-0 my-3 bg-white box-shadow-section">
                                                                        <div class="row mx-0">
                                                                            <div class="clickable" style="width: 43%;">
                                                                                <div class="position-relative w-100"
                                                                                    style="padding-top: 100%;">
                                                                                    <!-- [ngStyle]="{'background-image': 'url('+ item.imageurl +')'}" -->

                                                                                    <div class="position-absolute img-square-landscape"
                                                                                        (click)="openFullViewImage(item.imageurl)"
                                                                                        *ngIf="item.tempwidth > item.tempheight">
                                                                                        <img [src]="item.imageurl"
                                                                                            alt=""
                                                                                            class="photo-size-landscape">
                                                                                    </div>
                                                                                    <div class="position-absolute img-square"
                                                                                        (click)="openFullViewImage(item.imageurl)"
                                                                                        *ngIf="item.tempwidth <= item.tempheight">
                                                                                        <img [src]="item.imageurl"
                                                                                            alt="" class="photo-size">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="px-2 text-left"
                                                                                style="width: 50%;">
                                                                                <div
                                                                                    class="small-text c-darkergray my-2 pl-3">
                                                                                    <span>
                                                                                        {{item.photouploadtime |
                                                                                        date:'medium'}}
                                                                                    </span>
                                                                                    <span class="pl-2 oswald"
                                                                                        *ngIf="item.imageType !== 'image'">
                                                                                        <span
                                                                                            *ngIf="item.imageType == 'deploma'">
                                                                                            <img src="../../../assets/images/static/degree-verified.svg"
                                                                                                alt="">
                                                                                        </span>
                                                                                        <span
                                                                                            *ngIf="item.imageType == 'photoid'">
                                                                                            <img src="../../../assets/images/static/photoID-verified.svg"
                                                                                                alt="">
                                                                                        </span>
                                                                                        <span
                                                                                            *ngIf="item.imageType == 'employeeId'">
                                                                                            <img src="../../../assets/images/static/employee-verified.svg"
                                                                                                alt="">
                                                                                        </span>
                                                                                    </span>

                                                                                </div>
                                                                                <!-- for action taken on photos -->
                                                                                <div class="d-flex"
                                                                                    *ngIf="item.imageType === 'image' && item.checkActionTaken === false">
                                                                                    <div class="">
                                                                                        <mat-checkbox
                                                                                            class="c-btn-primary"
                                                                                            [(ngModel)]="item.markasprofile"
                                                                                            (change)="makeProfilePhoto(pindex,i,$event,pitem)">
                                                                                        </mat-checkbox>
                                                                                    </div>
                                                                                    <!-- <div class="" *ngIf="pitem.photoCounter == 1">
                                                                                        <mat-checkbox class="c-btn-primary" [(ngModel)]="item.markasprofile"  (change)="makeProfilePhoto(pindex,i,$event,pitem)"></mat-checkbox>
                                                                                    </div> -->

                                                                                    <div
                                                                                        class="small-text c-darkergray pl-2">
                                                                                        <div class="">
                                                                                            Make profile picture
                                                                                        </div>
                                                                                        <div class="pt-1">
                                                                                            {{item.photoApprovedtime |
                                                                                            date:'medium'}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <!-- for profile photo -->
                                                                                <div class="d-flex"
                                                                                    *ngIf="item.imageType === 'image' && item.markasprofile && item.checkActionTaken === true && pitem.tempCheckForProfilePhoto == 1">
                                                                                    <div class=""
                                                                                        *ngIf="pitem.photoCounter > 1">
                                                                                        <mat-checkbox
                                                                                            class="c-btn-primary"
                                                                                            [(ngModel)]="item.markasprofile"
                                                                                            (change)="makeProfilePhoto(pindex,i,$event,pitem)">
                                                                                        </mat-checkbox>

                                                                                    </div>
                                                                                    <div class=""
                                                                                        *ngIf="pitem.photoCounter == 1 && item.checkActionTaken === true">
                                                                                        <mat-checkbox
                                                                                            class="c-btn-primary"
                                                                                            [(ngModel)]="item.markasprofile"
                                                                                            disabled
                                                                                            (change)="makeProfilePhoto(pindex,i,$event,pitem)">
                                                                                        </mat-checkbox>
                                                                                    </div>
                                                                                    <div class=""
                                                                                        *ngIf="pitem.photoCounter == 1 && item.checkActionTaken === false">
                                                                                        <mat-checkbox
                                                                                            class="c-btn-primary"
                                                                                            [(ngModel)]="item.markasprofile"
                                                                                            (change)="makeProfilePhoto(pindex,i,$event,pitem)">
                                                                                        </mat-checkbox>
                                                                                    </div>
                                                                                    <div
                                                                                        class="small-text c-darkergray pl-2">
                                                                                        <div class="">
                                                                                            Make profile picture
                                                                                        </div>
                                                                                        <div class="pt-1">
                                                                                            {{item.photoApprovedtime |
                                                                                            date:'medium'}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <!-- <div class="d-flex mt-2">
                                                                                    <div class="">
                                                                                        <mat-checkbox></mat-checkbox>
                                                                                    </div>
                                                                                    <div class="small-text c-darkergray pl-2">
                                                                                        Disallow as profile picture
                                                                                    </div>
                                                                                </div> -->
                                                                                <div class="pl-3 pt-2">
                                                                                    <div class=""
                                                                                        *ngIf="item.imageType === 'image'">
                                                                                        <span
                                                                                            class="c-darkgray small-text"
                                                                                            style="vertical-align: middle;">
                                                                                            Caption
                                                                                        </span>
                                                                                        <span
                                                                                            *ngIf="item.descriptionunmoderated">
                                                                                            <button mat-icon-button
                                                                                                matTooltip="Edit"
                                                                                                matTooltipClass="toolTipBgColor"
                                                                                                *ngIf="!item.description1 || (item.descriptionunmoderated && !isReviewer)"
                                                                                                (click)="pitem.captionEditIndex = i">
                                                                                                <img class=""
                                                                                                    [src]="btnObj.action.edit"
                                                                                                    height="20"
                                                                                                    width="20"
                                                                                                    alt="edit-btn">
                                                                                            </button>

                                                                                            <button mat-icon-button
                                                                                                matTooltip="approved-icon"
                                                                                                matTooltipClass="toolTipBgColor"
                                                                                                class="border-0 bg-white rounded-circle"
                                                                                                (click)="changeStatus(pindex,i,true,item,'caption')"
                                                                                                [disabled]="item.disablePhotoApproveButton"
                                                                                                style="vertical-align: middle;">
                                                                                                <span
                                                                                                    class="d-block rounded-circle position-relative mx-auto"
                                                                                                    [ngClass]="[item.descriptionLeftBtnColor, 'moderation-word-bg-'+item.descriptionLeftBtnColor]"
                                                                                                    style="height: 20px;width: 20px;">
                                                                                                    <img class="center-box"
                                                                                                        [src]="item.descriptionLeftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                                        height="12"
                                                                                                        width="12"
                                                                                                        alt="Approved-btn">
                                                                                                </span>
                                                                                            </button>

                                                                                            <!-- <button mat-icon-button matTooltip="rejected-icon"
                                                                                                matTooltipClass="toolTipBgColor" class="border-0 bg-white rounded-circle"
                                                                                                *ngIf="item.descriptionLeftBtnColor !== 'red'"
                                                                                                (click)="changeStatus(i,false,item,'caption')" style="margin-top: -11px;">
                                                                                                <span class="d-block rounded-circle position-relative mx-auto"
                                                                                                    [ngClass]="[item.descriptionRightBtnColor, 'moderation-word-bg-'+item.descriptionRightBtnColor]"
                                                                                                    style="height: 20px;width: 20px;">
                                                                                                    <img class="center-box"
                                                                                                        [src]="item.descriptionRightBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                                                                        height="12" width="12" alt="edit-btn">
                                                                                                </span>
                                                                                            </button> -->
                                                                                        </span>

                                                                                        <span
                                                                                            *ngIf="!item.descriptionunmoderated">
                                                                                            <button mat-icon-button
                                                                                                matTooltip="approved-icon"
                                                                                                matTooltipClass="toolTipBgColor"
                                                                                                class="border-0 bg-white rounded-circle"
                                                                                                (click)="changeStatus(pindex,i,true,item,'caption')"
                                                                                                [disabled]="item.disablePhotoApproveButton"
                                                                                                style="vertical-align: middle;">
                                                                                                <span
                                                                                                    class="d-block rounded-circle position-relative mx-auto"
                                                                                                    [ngClass]="[item.descriptionLeftBtnColor, 'moderation-word-bg-'+item.descriptionLeftBtnColor]"
                                                                                                    style="height: 20px;width: 20px;">
                                                                                                    <img class="center-box"
                                                                                                        [src]="item.descriptionLeftBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                                        height="12"
                                                                                                        width="12"
                                                                                                        alt="Approved-btn">
                                                                                                </span>
                                                                                            </button>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="mt-1"
                                                                                        *ngIf="item.imageType === 'image'">
                                                                                        <div class="c-darkgray smaller-text"
                                                                                            (keyup)="saveCaption(pindex,$event,item)"
                                                                                            (keydown.esc)="changeMind(pindex, $event, item)"
                                                                                            [ngClass]="{'caption-edit-class': pitem.captionEditIndex === i}"
                                                                                            [attr.contenteditable]="pitem.captionEditIndex === i">
                                                                                            {{item.description ?
                                                                                            item.description : 'N.A.'}}

                                                                                        </div>
                                                                                        <!-- <div class="c-darkergray smaller-text mb-1"
                                                                                            *ngIf="captionEditIndex === i">
                                                                                            <i>Press ESC to cancel & ENTER to
                                                                                                save</i>
                                                                                        </div> -->
                                                                                    </div>
                                                                                    <div class="c-darkgray smaller-text"
                                                                                        *ngIf="item.description1">
                                                                                        {{item.description1}} By
                                                                                        Reviewer
                                                                                    </div>
                                                                                </div>


                                                                                <div class=""
                                                                                    *ngIf="item.reviewerAction === false">
                                                                                    <div
                                                                                        class="c-darkgray small-text pl-2 pt-2">
                                                                                        By reviewer
                                                                                        <b>{{item.reviewedBy}}</b>
                                                                                        {{item.reviewerModerationTime |
                                                                                        date:'medium'}}
                                                                                        <div
                                                                                            class="small-text c-darkgray py-1">
                                                                                            Reason for rejecting Photo
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="pl-2"
                                                                                        *ngIf="item.reason">
                                                                                        <div
                                                                                            class="small-text c-darkergray py-1">
                                                                                            {{item.reason.split(';
                                                                                            ')[0]}}
                                                                                            <span class="readMore"
                                                                                                (click)="openPhotoDeclineDialog(pindex,i,false,item,'img',true)">READ
                                                                                                MORE</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class=""
                                                                                    *ngIf="item.reviewerAction">
                                                                                    <div
                                                                                        class="c-darkgray small-text pl-2 pt-2">
                                                                                        By reviewer
                                                                                        <b>{{item.reviewedBy}}</b>
                                                                                        {{item.reviewerModerationTime |
                                                                                        date:'medium'}}
                                                                                    </div>
                                                                                </div>


                                                                                <div class=""
                                                                                    *ngIf="item.supervisorAction === false">
                                                                                    <div
                                                                                        class="c-darkgray small-text pl-2 pt-2">
                                                                                        By supervisor
                                                                                        <b>{{item.moderatedBy}}</b>
                                                                                        {{item.supervisorModerationTime
                                                                                        | date:'medium'}}
                                                                                        <div
                                                                                            class="small-text c-darkgray py-1">
                                                                                            Reason for rejecting Photo
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="pl-2"
                                                                                        *ngIf="item.declinereason">
                                                                                        <div
                                                                                            class="small-text c-darkergray py-1">
                                                                                            {{item.declinereason.split(';
                                                                                            ')[0]}}
                                                                                            <span class="readMore"
                                                                                                (click)="openPhotoDeclineDialog(pindex,null,null,item,null,true)">READ
                                                                                                MORE</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div class=""
                                                                                    *ngIf="item.supervisorAction">
                                                                                    <div
                                                                                        class="c-darkgray small-text pl-2 pt-2">
                                                                                        By supervisor
                                                                                        <b>{{item.moderatedBy}}</b>
                                                                                        {{item.supervisorModerationTime
                                                                                        | date:'medium'}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="" style="width: 7%;">

                                                                                <button matRipple
                                                                                    class="w-100 pb-1 d-block"
                                                                                    style="height: 50%;"
                                                                                    [ngClass]="[item.topBtnColor, 'moderation-word-bg-'+item.topBtnColor]"
                                                                                    [disabled]="isReviewer ?  (item.disablePhotoApproveButton || item.bottomBtnColor === 'gray') :   (item.bottomBtnColor === 'red' || item.disablePhotoApproveButton)"
                                                                                    (click)="changeStatus(pindex,i,true,item,'img')">
                                                                                    <img *ngIf="item.topBtnColor !== 'green'"
                                                                                        [src]="item.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                        alt="btn-tick" height="25"
                                                                                        width="25">
                                                                                    <span
                                                                                        *ngIf="item.topBtnColor === 'green'"
                                                                                        matTooltip="APPROVED"
                                                                                        matTooltipClass="toolTipBgColor"
                                                                                        class="d-block c-white small-text">
                                                                                        <ul class="list-inline"
                                                                                            style="margin: auto 0;height: 100%;">
                                                                                            <li
                                                                                                *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                                                                                {{item}}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </span>
                                                                                </button>
                                                                                <button matRipple
                                                                                    class="w-100 pb-1 d-block"
                                                                                    style="height: 50%;"
                                                                                    (click)="openPhotoDeclineDialog(pindex,i,false,item,'img',false)"
                                                                                    [disabled]="isReviewer ?  (item.disablePhotoApproveButton || item.topBtnColor === 'purple') : (item.disablePhotoApproveButton || item.topBtnColor === 'green')"
                                                                                    [ngClass]="[item.bottomBtnColor, 'moderation-word-bg-'+item.bottomBtnColor]">
                                                                                    <img *ngIf="item.bottomBtnColor !== 'red'"
                                                                                        [src]="item.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                                                        alt="cross-btn" height="25"
                                                                                        width="25">
                                                                                    <span
                                                                                        *ngIf="item.bottomBtnColor === 'red'"
                                                                                        matTooltip="REJECTED"
                                                                                        matTooltipClass="toolTipBgColor"
                                                                                        class="d-block c-white small-text">
                                                                                        <ul class="list-inline"
                                                                                            style="margin: auto 0;height: 100%;">
                                                                                            <li
                                                                                                *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                                                                                {{item}}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>

                                                                <!-- moderated video -->
                                                                <div class="col-12 px-0 text-left bg-white box-shadow-section"
                                                                    *ngIf="pitem.video && pitem.video.topBtnColor != 'orange' && pitem.video.bottomBtnColor != 'orange'">
                                                                    <div class="row mx-0">
                                                                        <div class="" style="width: 43%;">
                                                                            <ziggeo-player #ziggeoplayer
                                                                                [apiKey]="apiKey" [options]="{
                                                                            theme:'modern',
                                                                            height: 300,
                                                                            width: 250,
                                                                            video:pitem.video.videoid
                                                                        }">
                                                                            </ziggeo-player>
                                                                        </div>
                                                                        <div class="px-2" style="width: 50%;">

                                                                            <div
                                                                                class="small-text c-darkergray pl-2 pt-3">
                                                                                {{pitem.video.photouploadtime |
                                                                                date:'medium'}}
                                                                            </div>

                                                                            <div class=""
                                                                                *ngIf="pitem.video.reviewerAction === false">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By reviewer
                                                                                    <b>{{pitem.video.reviewedBy}}</b>
                                                                                    {{pitem.video.reviewerModerationTime
                                                                                    | date:'medium'}}
                                                                                    <div
                                                                                        class="small-text c-darkgray py-1">
                                                                                        Reason for rejecting Video</div>
                                                                                </div>

                                                                                <div class="pl-2"
                                                                                    *ngIf="pitem.video.declineReasonsByReviewer">
                                                                                    <div
                                                                                        class="small-text c-darkergray py-1">
                                                                                        {{pitem.video.declineReasonsByReviewer.split(';')[0]}}
                                                                                        <span class="readMore"
                                                                                            (click)="declineDialog(pindex,pitem.userId, true)">READ
                                                                                            MORE</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class=""
                                                                                *ngIf="pitem.video.reviewerAction">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By reviewer
                                                                                    <b>{{pitem.video.reviewedBy}}</b>
                                                                                    {{pitem.video.reviewerModerationTime
                                                                                    | date:'medium'}}
                                                                                </div>
                                                                            </div>


                                                                            <div class=""
                                                                                *ngIf="pitem.video.supervisorAction === false && !pitem.video.unmoderated">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    <span
                                                                                        class="d-inline-block moderation-word-bg-red rounded-circle position-relative"
                                                                                        style="height: 20px;width: 20px;">
                                                                                        <img class="center-box"
                                                                                            [src]="btnObj.cross.white"
                                                                                            alt="tick" height="15"
                                                                                            width="15" />
                                                                                    </span> By supervisor
                                                                                    <b>{{pitem.video.moderatedBy}}</b>
                                                                                    {{pitem.video.supervisorModerationTime
                                                                                    | date:'medium'}}
                                                                                    <div
                                                                                        class="small-text c-darkgray py-1">
                                                                                        Reason for rejecting Video</div>
                                                                                </div>

                                                                                <div class="pl-2"
                                                                                    *ngIf="pitem.video.declineReasonsBySupervisor">
                                                                                    <div
                                                                                        class="small-text c-darkergray py-1">
                                                                                        {{pitem.video.declineReasonsBySupervisor.split(';
                                                                                        ')[0]}}
                                                                                        <span class="readMore"
                                                                                            (click)="declineDialog(pindex,pitem.userId, true)">READ
                                                                                            MORE</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div class=""
                                                                                *ngIf="pitem.video.supervisorAction && !pitem.video.unmoderated">
                                                                                <div
                                                                                    class="c-darkgray small-text pl-2 pt-2">
                                                                                    By supervisor
                                                                                    <b>{{pitem.video.moderatedBy}}</b>
                                                                                    {{pitem.video.supervisorModerationTime
                                                                                    | date:'medium'}}
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div class="" style="width: 7%">

                                                                            <button matRipple class="w-100 pb-1 d-block"
                                                                                style="height: 50%;"
                                                                                (click)="userVideoAction(pindex,pitem.userId, true)"
                                                                                [disabled]="pitem.video.disabledselfieVideoButton"
                                                                                [ngClass]="[pitem.video.topBtnColor, 'moderation-word-bg-'+pitem.video.topBtnColor]">
                                                                                <img *ngIf="pitem.video.topBtnColor !== 'green'"
                                                                                    [src]="pitem.video.topBtnColor === 'white' ? btnObj.tick.orange : btnObj.tick.white"
                                                                                    alt="btn-tick" height="25"
                                                                                    width="25">
                                                                                <span
                                                                                    *ngIf="pitem.video.topBtnColor === 'green'"
                                                                                    matTooltip="APPROVED"
                                                                                    matTooltipClass="toolTipBgColor"
                                                                                    class="d-block c-white small-text">
                                                                                    <ul class="list-inline"
                                                                                        style="margin: auto 0;height: 100%;">
                                                                                        <li
                                                                                            *ngFor="let item of ['A','P','P','R','O','V','E','D']">
                                                                                            {{item}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            </button>
                                                                            <button matRipple class="w-100 pb-1 d-block"
                                                                                style="height: 50%;"
                                                                                (click)="declineDialog(pindex,pitem.userId, false)"
                                                                                [ngClass]="[pitem.video.bottomBtnColor, 'moderation-word-bg-'+pitem.video.bottomBtnColor]">
                                                                                <img *ngIf="pitem.video.bottomBtnColor !== 'red'"
                                                                                    [src]="pitem.video.bottomBtnColor === 'white' ? btnObj.cross.orange : btnObj.cross.white"
                                                                                    alt="cross-btn" height="25"
                                                                                    width="25">
                                                                                <span
                                                                                    *ngIf="pitem.video.bottomBtnColor === 'red'"
                                                                                    matTooltip="REJECTED"
                                                                                    matTooltipClass="toolTipBgColor"
                                                                                    class="d-block c-white small-text">
                                                                                    <ul class="list-inline"
                                                                                        style="margin: auto 0;height: 100%;">
                                                                                        <li
                                                                                            *ngFor="let item of ['R','E','J','E','C','T','E','D']">
                                                                                            {{item}}
                                                                                        </li>
                                                                                    </ul>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </mat-tab>
                                            <!-- SCREENER Points -->
                                            <mat-tab label="SCREENER">
                                                <ng-template matTabContent>
                                                    <div class="row mx-0 my-3" *ngIf="!pitem.screenerPointSkeleton">
                                                        <div
                                                            class="col-12 px-0 pt-2 text-center opensans-regular subheading c-darkgray">
                                                            Total Points: {{pitem.totalScreenerPointsValue}}
                                                        </div>
                                                        <div class="col-12 px-0 mt-3">
                                                            <div class="row mx-0">
                                                                <ng-container
                                                                    *ngFor="let item of pitem.screenerPointsArrayList;let i = index">
                                                                    <!-- matchesIconArr -->
                                                                    <button matRipple class="col text-center px-0"
                                                                        [ngClass]="i === pitem.selectActiveScreener ? 'sel-matches-icon-class':'matches-icon-class'"
                                                                        (click)="screenerPiontsList(item.passedValue, pindex, i)">
                                                                        <span style="font-size: 14px; float:none;"
                                                                            [ngClass]="i === pitem.selectActiveScreener ? 'sel-matches-icon-font-class':'matches-icon-font-class'">
                                                                            {{item.text}} <span
                                                                                class="c-darkgray opensans-regular ml-1 px-1"
                                                                                style="background-color: #fd7c72;">{{item.total
                                                                                * item.value}}</span>
                                                                        </span>
                                                                    </button>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 px-2 pt-3">
                                                            <div class="row mx-0">
                                                                <div class="col-12 px-0"
                                                                    *ngFor="let item of pitem.screenerPointArray;">
                                                                    <div class="row mx-0">
                                                                        <div class="col-12 px-2 text-left">
                                                                            <div class="oswald body-text c-darkgray pt-3"
                                                                                *ngIf="item.scroeType && item.scroeType != 'otherThenScreenerType'">
                                                                                {{item.scroeType}}
                                                                            </div>
                                                                            <div
                                                                                class="opensans-light body-subtext c-darkgray pl-3 pt-3">
                                                                                {{item.subtext}}</div>
                                                                            <div
                                                                                class="opensans-regular body-subtext c-darkgray pl-3 pt-1">
                                                                                {{item.redFlag}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- skeleton for screener points -->
                                                    <div class="row mx-0 my-4 justify-content-center ph-item"
                                                        *ngIf="pitem.screenerPointSkeleton" style="height: 350px;">
                                                        <div class="col-3 px-0 text-center screener-skelenton-class">
                                                        </div>
                                                        <div class="col-12 px-0 pt-2">
                                                            <div class="row mx-3 justify-content-between">
                                                                <div class="col-2 px-0 screener-skelenton-class"
                                                                    style="height: 30px;"></div>
                                                                <div class="col-2 px-0 screener-skelenton-class"
                                                                    style="height: 30px;"></div>
                                                                <div class="col-2 px-0 screener-skelenton-class"
                                                                    style="height: 30px;"></div>
                                                                <div class="col-2 px-0 screener-skelenton-class"
                                                                    style="height: 30px;"></div>
                                                                <div class="col-2 px-0 screener-skelenton-class"
                                                                    style="height: 30px;"></div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 px-0">
                                                            <div class="row mx-2 justify-content-start mt-3">
                                                                <div
                                                                    class="col-4 px-0 pl-3 pt-3 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                            <div class="row mx-4 justify-content-start mt-3">
                                                                <div
                                                                    class="col-3 px-0 pl-2 pt-2 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                            <div class="row mx-4 justify-content-start mt-3">
                                                                <div
                                                                    class="col-5 px-0 pl-2 pt-2 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 px-0 pt-3">
                                                            <div class="row mx-2 justify-content-start mt-3">
                                                                <div
                                                                    class="col-4 px-0 pl-3 pt-3 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                            <div class="row mx-4 justify-content-start mt-3">
                                                                <div
                                                                    class="col-3 px-0 pl-2 pt-2 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                            <div class="row mx-4 justify-content-start mt-3">
                                                                <div
                                                                    class="col-5 px-0 pl-2 pt-2 screener-skelenton-class">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="row mx-0 m-2">
                                                        <div style="width: 300px; height: 260px; background-color: #ced4da;"></div>
                                                    </div>
                                                    <div class="row mx-0 mb-3">
                                                        <div class="col-12 px-0 mt-1 mb-1" *ngFor="let item of [1,2,3,4,5,6,7,8]">
                                                            <div class="d-flex my-1 ml-1">
                                                                <div style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                                                </div>
                                                                <div class="ml-3 mt-1" style="width: 200px;height: 12px; background-color: #ced4da;"></div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </ng-template>
                                            </mat-tab>
                                            <!---Notes Tab-->
                                            <mat-tab label="NOTES">
                                                <ng-template matTabContent>
                                                    <div class="row mx-0 vh-100 bg-white" style="margin-bottom: 200px;">
                                                        <div class="col-12 px-0">
                                                            <div class="row mx-3 d-flex">
                                                                <div
                                                                    class="col-4 px-0 oswald subheading c-darkgray pt-4 text-left">
                                                                    Timeline
                                                                </div>
                                                                <div class="col-8 px-0" style="margin-top: 4%;">
                                                                    <img class="float-right clickable" height="18"
                                                                        src="/assets/images/static/reload-icon.svg"
                                                                        (click)="refreshUserNotes(pindex,pitem.userId)"
                                                                        alt="">
                                                                </div>


                                                                <!-- <div class="col-12 px-0 pt-2 text-center">
                                                                    <span [ngClass] = "talkWithUser ? 'selectCheckBoxClass' : 'checkBoxClass'" (click)= "talkwithuserFunction()"></span>
                                                                    <label class="pl-4"> Did you talk with this user?</label><br>
                                                                </div> -->
                                                                <div class="col-12 px-0 text-center">
                                                                    <mat-checkbox class="c-btn-primary"
                                                                        (change)="talkwithuserFunction($event)"></mat-checkbox>
                                                                    <label class="pl-2"> Did you talk with this
                                                                        user?</label><br>
                                                                </div>
                                                                <div class="pt-3">
                                                                    <textarea [(ngModel)]="supportNotes"
                                                                        class="col-12 px-0 p-2 pt-4" id="written-area"
                                                                        value="Clear" rows="7" cols="190"
                                                                        placeholder="Type message"
                                                                        style="border:1px solid #979797;border-radius: 3px;"></textarea>
                                                                </div>
                                                                <div class="col-12 px-0 pt-2">
                                                                    <div class="row mx-0 justify-content-center">
                                                                        <div class="col-3 px-0">
                                                                            <button [disabled]="!supportNotes"
                                                                                mat-flat-button
                                                                                [ngClass]="!supportNotes ? 'disabledSaveNotes' : 'c-white enableSaveNotes' "
                                                                                (click)="saveSupportNotesData(supportNotes,pitem.userId,pindex)"
                                                                                [disabled]="disableSaveNoteButton">
                                                                                SAVE NOTES
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row mx-0 " *ngIf="!pitem.userNotesSkelton">
                                                                    <div class="col-12 px-0 pt-4 text-left"
                                                                        *ngFor="let item of pitem.userNotes">
                                                                        <img src="../../../../assets/images/static/sms-gray.svg"
                                                                            height="22px" width="22px" alt="">
                                                                        <span
                                                                            class="pl-2 opensans-regular c-darkgray body-subtext">{{item.day}},
                                                                            {{item.Time | date : 'medium'}} </span>
                                                                        <span class="pl-1 body-subtext">
                                                                            <span class="subheading">By:
                                                                            </span>{{item.createdby}} </span>
                                                                        <br>
                                                                        <div class="row mx-0 ml-4 pb-3">
                                                                            <div class="col-12 px-0 opensans-light c-darkgray small-text"
                                                                                style="word-wrap: break-word;"
                                                                                [innerHtml]="item.detail">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 px-0 pt-4 pb-3" *ngIf="showMore">
                                                                        <div class="row mx-0 justify-content-center">
                                                                            <div class="col-12 px-0">
                                                                                <button class="showMoreButtonBgColor"
                                                                                    mat-button
                                                                                    (click)="loadMoreData(pindex,pitem.userId)">SHOW
                                                                                    MORE</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- skelenton -->
                                                                <div class="col-12 px-3 pb-5"
                                                                    *ngIf="pitem.userNotesSkelton">
                                                                    <div class="row mx-0 ">
                                                                        <div class="pt-4">
                                                                            <div class="row mx-0 ph-item">
                                                                                <div
                                                                                    class="col-4 px-0 mat-elevation-z5 skelenton-class">
                                                                                </div>
                                                                            </div>

                                                                            <div class="row mx-0 mt-4 ph-item">
                                                                                <div class="col-12 px-0">
                                                                                    <div
                                                                                        class="mat-elevation-z5 skelenton-class-width">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="row mx-0 ">
                                                                        <div class="pt-4">
                                                                            <div class="row mx-0 ph-item">
                                                                                <div
                                                                                    class="col-4 px-0 mat-elevation-z5 skelenton-class">
                                                                                </div>
                                                                            </div>

                                                                            <div class="row mx-0 mt-4 ph-item">
                                                                                <div class="col-12 px-0">
                                                                                    <div
                                                                                        class="mat-elevation-z5 skelenton-class-width">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="row mx-0 ">
                                                                        <div class="pt-4">
                                                                            <div class="row mx-0 ph-item">
                                                                                <div
                                                                                    class="col-4 px-0 mat-elevation-z5 skelenton-class">
                                                                                </div>
                                                                            </div>

                                                                            <div class="row mx-0 mt-4 ph-item">
                                                                                <div class="col-12 px-0">
                                                                                    <div
                                                                                        class="mat-elevation-z5 skelenton-class-width">
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>

                                                </ng-template>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>
                                </div>
                            </div>

                            <!-- Right View -->
                            <div class="col-3">
                                <div class="row mx-0">

                                    <!-- action buttons icon clickable -->
                                    <div class="col-12 box-shadow-section text-left bg-white pb-2 px-2">

                                        <div class="row mx-0 mt-2">
                                            <!-- Action Button 4 -->

                                            <!-- Phone -->
                                            <div class="col-6 pl-0 pr-1">
                                                <button mat-flat-button class="w-100 bg-btn-primary">
                                                    <img src="../../../assets/images/static/call-white.svg" height="30"
                                                        width="30" alt="phone-icon">
                                                </button>
                                            </div>
                                            <!-- Mail -->
                                            <div class="col-6 pl-1 pr-0">
                                                <button mat-flat-button class="w-100 bg-btn-primary">
                                                    <img src="../../../assets/images/static/email-white.svg" height="30"
                                                        width="30" alt="email-icon">
                                                </button>
                                            </div>
                                            <!-- Chat -->
                                            <div class="col-6 pl-0 pr-1 py-2">
                                                <button mat-flat-button class="w-100 bg-btn-primary">
                                                    <img src="../../../assets/images/static/chat-white.svg" height="30"
                                                        width="30" alt="chat-icon">
                                                </button>
                                            </div>
                                            <!-- Notification -->
                                            <div class="col-6 py-1 pl-1 pr-0 py-2">
                                                <button mat-flat-button class="w-100 bg-btn-primary">
                                                    <img src="../../../assets/images/static/notification-white.svg"
                                                        height="30" width="35" alt="notification-icon">
                                                </button>
                                            </div>

                                            <!-- Talk to User -->
                                            <div class="col-12 px-0 mt-1">
                                                <div class="float-right">
                                                    <mat-slide-toggle [disabled]="pitem.isTalkedUser || !pitem.phone"
                                                        class="" [(ngModel)]="pitem.isTalkedUser"
                                                        (change)="istalkedUser(pindex,pitem.userId, $event)">
                                                    </mat-slide-toggle>
                                                </div>
                                                <div class="c-darkgray small-text opensans-regular">Talked to this user</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Form of profile value-->
                                    <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">
                                        <div class="oswald c-darkgray subheading pt-3">Profile Value</div>
                                        <!-- Profile Quality -->
                                        <div class="my-2 pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Profile quality
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer ">
                                                <select class="input-field" [(ngModel)]="pitem.profileQuality"
                                                    (change)="profileValue(pitem.userId,'quality',pitem.profileQuality)">
                                                    <option value=null disabled>Select 1, 4, 7, 10</option>
                                                    <option [value]="item" *ngFor="let item of profileQuality">{{item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Diamond -->
                                        <div class="my-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Diamond profile
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer ">
                                                <select class="input-field" [(ngModel)]="pitem.profileDiamond"
                                                    (change)="profileValue(pitem.userId,'diamond',pitem.profileDiamond)">
                                                    <option value=null disabled>Undecided, Yes, No</option>
                                                    <option [value]="i+1"
                                                        *ngFor="let item of dimondProfile;let i = index">
                                                        {{item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- Probability -->
                                        <div class="my-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Upgrade probability
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer">
                                                <select class="input-field" [(ngModel)]="pitem.profileUpgrade"
                                                    (change)="profileValue(pitem.userId,'upgrade',pitem.profileUpgrade)">
                                                    <option value=null disabled>Select 10, 40, 70, 100</option>
                                                    <option [value]="item.value"
                                                        *ngFor="let item of upgradeProbabilityValue">
                                                        {{item.faceValue}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- AC Prospect -->
                                        <div class="my-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label dark-gray">
                                                AC prospect
                                            </div>
                                            <div class="c-darkgray body-subtext account-detail-answer">
                                                <select class="input-field" [(ngModel)]="pitem.profileACProspect"
                                                    (change)="profileValue(pitem.userId,'acProspect',pitem.profileACProspect)">
                                                    <option value=null disabled>Yes, No, Maybe</option>
                                                    <option [value]="item" *ngFor="let item of acProspect">{{item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Membership Status -->
                                    <div class="col-12 mt-3 box-shadow-section text-left bg-white pb-2 px-2">
                                        <div class="oswald c-darkgray subheading pt-3">Membership Status</div>
                                        <div class="clearfix"></div>
                                        <div class="text-center small-text c-darkgray pt-2" *ngIf="pitem.planType">
                                            {{pitem.planType}}</div>
                                        <div class="text-center small-text c-darkgray pb-2" *ngIf="pitem.remainingDays">
                                            <i>{{pitem.remainingDays}}</i>
                                        </div>
                                        <div class="text-center small-text c-darkgray pt-3" *ngIf="!pitem.planType">
                                            Free Member
                                        </div>
                                        <!-- <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Bought on
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.planBoughtOn | date:'medium'}}
                                            </div>
                                        </div> -->
                                        <div class="pt-2 d-flex" *ngIf="pitem.planExpireOn">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Expires on
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.planExpireOn | date:'medium'}}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Activity -->
                                    <div class="col-12 px-2 mt-3 box-shadow-section text-left bg-white pb-2">
                                        <div class="float-right d-flex pt-1">
                                            <div matTooltip="Login Count" matTooltipClass="toolTipBgColor">
                                                <img src="../../../assets/images/static/login-count.svg" height="22"
                                                    width="22" alt="login-count-icon" />
                                                <span class="c-darkgray small-text pl-1">{{pitem.loginCount}}</span>
                                            </div>
                                        </div>
                                        <div class="oswald c-darkgray subheading pt-3">Activity</div>
                                        <div class="clearfix"></div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">Sign up</div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.signUp | date : 'medium'}}
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Sign up via
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.signUpVia}}
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Profile completed
                                            </div>
                                            <div *ngIf="pitem.activity.profileCompleted"
                                                class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.profileCompleted | date:'medium'}}
                                            </div>
                                            <div *ngIf="!pitem.activity.profileCompleted"
                                                class="c-darkgray small-text account-detail-answer"
                                                style="padding-top:7px;">
                                                Incomplete
                                            </div>
                                        </div>
                                        <div *ngIf="pitem.selectedProfileStatus?.heading !== 'SCREENED'"
                                            class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Last question
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.currentquestionnumber}}
                                            </div>
                                        </div>
                                        <!-- <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Profile screened
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                Thu, 12 May 2020 10:34 PM
                                            </div>
                                        </div> -->
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Last online
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.lastOnline | date :'medium'}}
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Last online via
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.lastOnlineVia}}
                                            </div>
                                        </div>
                                        <div class="pt-2 d-flex">
                                            <div class="c-darkergray small-text mr-1 account-detail-label">
                                                Screening time
                                            </div>
                                            <div class="c-darkgray small-text account-detail-answer">
                                                {{pitem.activity.screeningTime | date :'medium'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- pagination -->
            <div class="col-12 px-0 pb-4" *ngIf="totalUsers >= 25">
                <div class="row mx-0 justify-content-center">
                    <div class="col-7 px-0 bg-white pt-2 pb-1 text-center">
                        <span class="float-left pl-3 pt-2">
                            <img class="pl-4" *ngIf="displayPageNumber == 1"
                                src="/assets/images/static/left-double-arrow-icon.svg" height="27px" alt="">
                            <img class="pl-4 clickable" *ngIf="displayPageNumber != 1" (click)="nextPageDataLoad(1)"
                                src="/assets/images/static/left-double-arrow-icon.svg" height="27px" alt="">
                            <img class="px-2 pl-5" *ngIf="displayPageNumber == 1"
                                src="/assets/images/static/left-single-arrow-icon.svg" height="27px" alt="">
                            <img class="px-2 clickable pl-5" *ngIf="displayPageNumber != 1"
                                (click)="nextPageDataLoad((apiparamsForFiltering.skip / 25))"
                                src="/assets/images/static/left-single-arrow-icon.svg" height="27px" alt="">
                        </span>
                        <span class="float-right pr-3 pt-2">
                            <img class="px-2 pr-5" *ngIf="displayPageNumber == lastPageNumber"
                                src="/assets/images/static/right-single-arrow-icon.svg" height="27px" alt="">
                            <img class="px-2 clickable pr-5" *ngIf="displayPageNumber != lastPageNumber"
                                (click)="nextPageDataLoad((apiparamsForFiltering.skip / 25) + 2)"
                                src="/assets/images/static/right-single-arrow-icon.svg" height="27px" alt="">
                            <img class="pr-4" *ngIf="displayPageNumber == lastPageNumber"
                                src="/assets/images/static/right-double-arrow-icon.svg" height="27px" alt="">
                            <img class="pr-4 clickable" *ngIf="displayPageNumber != lastPageNumber"
                                (click)="nextPageDataLoad(lastPageNumber)"
                                src="/assets/images/static/right-double-arrow-icon.svg" height="27px" alt="">
                        </span>
                        <div class="row mx-0  justify-content-center ">
                            <div class="col-9 pt-scroll-content px-0 py-1 flex-nowrap scroll-empty-class">
                                <div #pageNumber class="px-2 opensans-light c-darkgray body-subtext "
                                    *ngFor="let item of PagesNumber; let i = index; let last = last">
                                    <div class="clickable p-2 "
                                        [ngClass]="displayPageNumber == item.number ? 'activePageClass' : 'non-activePageClass' "
                                        (click)="nextPageDataLoad(item.number)">
                                        {{item.number}}
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <!-- <div class="col-6 px-0 text-center oswald j-h2 mx-auto pt-5" *ngIf="displayDataArray.length == 0">
                Total users: 0
            </div> -->
        </div>
    </div>

    <!-- for skeleton -->
    <div *ngIf="!hasApiResponse" class="col-12 px-0 jystify-content-center">
        <div class="row mx-0 ph-item justify-content-between">
            <div class="col-3 pt-2 ">
                <div class="row mx-0 m-2">
                    <div style="width: 300px; height: 260px; background-color: #ced4da;"></div>
                </div>
                <div class="row mx-0 mb-3">
                    <div class="col-12 px-0 mt-1 mb-1" *ngFor="let item of [1,2,3,4,5,6,7,8]">
                        <div class="d-flex my-1 ml-1">
                            <div style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                            </div>
                            <div class="ml-3 mt-1" style="width: 200px;height: 12px; background-color: #ced4da;"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 pt-2">
                <div class="row mx-0 mb-3 mat-elevation-z5 ph-item">
                    <div class="col-12 px-0 pl-2 pt-3">
                        <div class="row mx-0">
                            <div class="col- px-0 mt-3 mb-3" *ngFor="let item of [1,2,3,4,5,6,7,8,9]">
                                <div class="d-flex my-2 ml-1">
                                    <div class="ml-3 mt-1" style="width: 50px;height: 8px; background-color: #ced4da;">
                                    </div>
                                </div>

                            </div>
                            <div class="col-4 px-0 mt-3 mb-3" *ngFor="let item of [1,2,3]">
                                <div class="d-flex my-2 ml-1">
                                    <div class="ml-3 mt-1"
                                        style="width: 150px;height: 30px; background-color: #ced4da;"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mb-3  mat-elevation-z5 ph-item">
                    <div class="col-12 px-0">

                        <div class="row mx-0 m-3">
                            <div class="col-12 px-0">
                                <div *ngFor="let item of [1,2,3,4,5]">
                                    <div class="d-flex my-2 ml-1">
                                        <div
                                            style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                        </div>
                                        <div class="ml-3 mt-1"
                                            style="width: 100px;height: 10px; background-color: #ced4da;"></div>
                                    </div>
                                    <div class="d-flex my-2 ml-1">
                                        <div class=" mt-1"
                                            style="width: 150px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 px-0">
                                <div *ngFor="let item of [1,2,3,4,5]">
                                    <div class="d-flex my-2 ml-1">
                                        <div
                                            style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                        </div>
                                        <div class="ml-3 mt-1"
                                            style="width: 100px;height: 10px; background-color: #ced4da;"></div>
                                    </div>
                                    <div class="d-flex my-2 ml-1">
                                        <div class=" mt-1"
                                            style="width: 150px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3  mat-elevation-z5 pt-2">
                <div class="row mx-0 mb-3 mat-elevation-z5">
                    <div class="col-12 px-0 jystify-content-center">
                        <div class="d-flex mb-3">
                            <span class="ml-2 mt-2"
                                style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                            <span class="ml-1 mt-2"
                                style="width: 120px; height: 40px; background-color: #ced4da;"></span>

                        </div>
                        <div class="d-flex mb-3">
                            <span class="ml-2 mt-2"
                                style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                            <span class="ml-1 mt-2"
                                style="width: 120px; height: 40px; background-color: #ced4da;"></span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mb-2 mat-elevation-z5">
                    <div class="ml-3 mt-1 text-center" style="width: 150px;height: 10px; background-color: #ced4da;">
                    </div>
                    <div class="col-12 px-0 jystify-content-center" *ngFor="let item of [1,2,3,4]">
                        <div class="d-flex mb-3">
                            <span class="ml-3 mt-2"
                                style="width: 200px; height: 30px; background-color: #ced4da;"></span>

                        </div>
                    </div>
                </div>
                <div class="row mx-0 mb-3  mat-elevation-z5 ph-item">
                    <div class="col-12 px-0">

                        <div class="row mx-0 m-3">
                            <div class="col-12 px-0">
                                <div *ngFor="let item of [1,2,3,4,5]">
                                    <div class="d-flex my-2 ml-1">
                                        <div
                                            style="width: 15px;height: 15px; border-radius: 50%; background-color: #ced4da;">
                                        </div>
                                        <div class="ml-3 mt-1"
                                            style="width: 50px;height: 10px; background-color: #ced4da;"></div>
                                    </div>
                                    <div class="d-flex my-2 ml-1">
                                        <div class=" mt-1"
                                            style="width: 100px;height: 10px; background-color: #ced4da; margin-left: 35px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>