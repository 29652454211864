import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-screener',
  templateUrl: './screener.component.html',
  styleUrls: ['./screener.component.css']
})
export class ScreenerComponent implements OnInit {
  screenerPointsArrayList = [
    {
      text: 'VERY HIGH',
      passedValue: 'veryHighScreenerPoints',
      total: 0,
      value: 10
    },
    {
      text: 'HIGH',
      passedValue: 'highScreenerPoints',
      total: 0,
      value: 7
    },
    {
      text: 'MEDIUM',
      passedValue: 'mediumScreenerPoints',
      total: 0,
      value: 5
    },
    {
      text: 'LOW',
      passedValue: 'lowScreenerPoints',
      total: 0,
      value: 3
    },
    {
      text: 'VERY LOW',
      passedValue: 'veryLowScreenerPoints',
      total: 0,
      value: 1
    }
  ];
  selectActiveScreener = 0;
  screenerPointSkeleton = true;
  totalScreenerPointsValue = 0;
  screenerPointArray = [];
  veryHighScreenerPoints = [];
  highScreenerPoints = [];
  mediumScreenerPoints = [];
  lowScreenerPoints = [];
  veryLowScreenerPoints = [];
  constructor(private sharedservice: SharedService, private apiservice: ApiService) { }

  ngOnInit(): void {

    const apiparams: any = {
      userid: this.sharedservice.userId
    };
    this.apiservice.getprofilescannerpoints(apiparams).subscribe(res => {
      if (res) {
        res.default?.map(async (x, index) => {
          await this.storeDifferentScorePoints(x, 'Defaults', index, 1);
        })
        res.outlierResult?.map(async (x, index) => {
          await this.storeDifferentScorePoints(x, 'Outliers', index, 2);
        })
        res.lazyoptionsResult?.map(async (x, index) => {
          await this.storeDifferentScorePoints(x, 'Lazy options', index, 3);
        })
        res.inconsistenciesResult?.map(async (x, index) => {
          await this.storeDifferentScorePoints(x, 'Inconsistencies', index, 4);
        })

        setTimeout(() => {
          this.screenerPointSkeleton = false;
          this.screenerPointsArrayList[0].total = this.veryHighScreenerPoints.length;
          this.screenerPointsArrayList[1].total = this.highScreenerPoints.length;
          this.screenerPointsArrayList[2].total = this.mediumScreenerPoints.length;
          this.screenerPointsArrayList[3].total = this.lowScreenerPoints.length;
          this.screenerPointsArrayList[4].total = this.veryLowScreenerPoints.length;
          this.totalScreenerPointsValue = ((this.veryHighScreenerPoints.length * 10) + (this.highScreenerPoints.length * 7) + (this.mediumScreenerPoints.length * 5) + (this.lowScreenerPoints.length * 3) + (this.veryLowScreenerPoints.length * 1));
          if (this.veryHighScreenerPoints.length > 0) {
            this.selectActiveScreener = 0;
            this.screenerPointArray = this.veryHighScreenerPoints;
          } else if (this.highScreenerPoints.length > 0) {
            this.selectActiveScreener = 1;
            this.screenerPointArray = this.highScreenerPoints;
          } else if (this.mediumScreenerPoints.length > 0) {
            this.selectActiveScreener = 2;
            this.screenerPointArray = this.mediumScreenerPoints;
          } else if (this.lowScreenerPoints.length > 0) {
            this.selectActiveScreener = 3;
            this.screenerPointArray = this.lowScreenerPoints;
          } else if (this.veryLowScreenerPoints.length > 0) {
            this.selectActiveScreener = 4;
            this.screenerPointArray = this.veryLowScreenerPoints;
          } else {
            this.selectActiveScreener = 0;
            this.screenerPointArray = this.veryHighScreenerPoints;
          }
        }, 500);
      }
    })
  }

  async storeDifferentScorePoints(x, pointType, index, callingArraySource) {
    if (index == 0) {
      x.scroeType = pointType;
    } else {
      x.scroeType = 'otherThenScreenerType'; // jusy for ui purpose to display 
    }
    x.callingArraySource = callingArraySource;
    if (x.point == 1) {
      await this.createscreenerPointArray(this.veryLowScreenerPoints, pointType, x);
    } else if (x.point == 3) {
      await this.createscreenerPointArray(this.lowScreenerPoints, pointType, x);
    } else if (x.point == 5) {
      await this.createscreenerPointArray(this.mediumScreenerPoints, pointType, x);
    } else if (x.point == 7) {
      await this.createscreenerPointArray(this.highScreenerPoints, pointType, x);
    } else {
      await this.createscreenerPointArray(this.veryHighScreenerPoints, pointType, x);

    }
  }

  screenerPiontsList(type, index) {
    this.selectActiveScreener = index;
    this.screenerPointArray = [];
    if (type == 'veryHighScreenerPoints') {
      this.screenerPointArray = this.veryHighScreenerPoints;
    } else if (type == 'highScreenerPoints') {
      this.screenerPointArray = this.highScreenerPoints;
    } else if (type == 'mediumScreenerPoints') {
      this.screenerPointArray = this.mediumScreenerPoints;
    } else if (type == 'lowScreenerPoints') {
      this.screenerPointArray = this.lowScreenerPoints;
    } else {
      this.screenerPointArray = this.veryLowScreenerPoints;
    }
  }

  createscreenerPointArray(arrayforPoints: any[], pointType, x) {
    let len = arrayforPoints.length;
    if (arrayforPoints[len - 1]?.callingArraySource != x.callingArraySource) {
      x.scroeType = pointType;
    }
    arrayforPoints.push(x);
  }

}
