import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-userdetails-dialog',
  templateUrl: './update-userdetails-dialog.component.html',
  styleUrls: ['./update-userdetails-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateUserdetailsDialogComponent implements OnInit {
  emailUpdateForm: UntypedFormGroup;
  phoneNumberUpdateForm: UntypedFormGroup;
  countryCodes = [
    {
      label: 'Top countries',
      dataArray: []
    },
    {
      label: 'Next countries',
      dataArray: []
    },
    {
      label: 'Other countries',
      dataArray: []
    }
  ];
  type;
  dbEmail;
  countryCode;
  phoneNumber;
  userId;

  awaitResponse = false;
  isEmail = false;
  isPhoneNumber = false;
  forWhom;
  planHistoryArray = [];
  disableSaveButton = false;
  constructor(public mdRef: MatDialogRef<UpdateUserdetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, public md: MatDialog, private fb: UntypedFormBuilder, private dataservice: DataService, private apiservice: ApiService, private localservice: LocalStorageService, private router: Router) {
    this.type = this.data.infromationType;
    this.userId = this.data.userId;
    this.dbEmail = this.data.email;
    this.countryCode = this.data.countryCode;
    this.phoneNumber = this.data.phoneNumber;
    this.forWhom = this.data.forwhom;
    if (this.forWhom == 'emailPhoneNumber') {
      this.dataservice.getAllCountriesCodes().subscribe((res: any) => {
        this.countryCodes[0].dataArray = res.top;
        this.countryCodes[1].dataArray = res.next;
        this.countryCodes[2].dataArray = res.all;
      });
    }

  }

  ngOnInit(): void {
    if (this.forWhom == 'emailPhoneNumber') {
      this.emailUpdateForm = this.fb.group({
        email: [this.dbEmail, [Validators.required, Validators.email, Validators.maxLength(50)]],
      });
      this.phoneNumberUpdateForm = this.fb.group({
        countryCode: [this.countryCode, [Validators.required]],
        phoneNumber: [this.phoneNumber, [Validators.required, Validators.min(100000), Validators.max(999999999999)]]
      });
    } else {
      const params = {
        userid: Number(this.userId)
      }
      this.apiservice.getmembershipdetails(params).subscribe(result => {
        const apiData = result;
        if (result) {
          for (const i in apiData)
            this.prepareplanHistoryData(apiData[i]);
        }
      })
    }

  }

  prepareplanHistoryData(apidata) {
    const temp: any = {};
    temp.assistPlan = apidata.assist;
    temp.expressPlan = apidata.express;
    temp.quickPlan = apidata.quick;
    temp.duration = apidata.duration;
    temp.Status = apidata.orderstatus;
    temp.boughtupDate = apidata.subscriptionboughton;
    temp.expiryDate = apidata.subscriptionexpireson;

    this.planHistoryArray.push(temp);

  }


  onSubmit() {
    this.disableSaveButton = true;
    // email update
    if (this.emailUpdateForm.valid) {
      if (this.emailUpdateForm.controls.email.value != this.dbEmail) {
        this.apiservice.doesemailexist({ username: this.emailUpdateForm.controls.email.value }).subscribe(result => {
          if (result) {
            const params = {
              userid: Number(this.userId),
              email: this.emailUpdateForm.controls.email.value,
            }
            this.callApi(params);
          } else if (!result) {
            this.emailUpdateForm.controls.email.setErrors({ hasEmail: true });
          }
        },
          (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users

            if (err.status === 401) {
              this.localservice.removeToken('user');
              this.router.navigate(['/login']);
            } else if (err.status >= 500) {
              //  this.sharedservice.gettingServerError();
            } else if (err.status == 400) {
              this.emailUpdateForm.controls.email.setErrors({ email: true });
            }
          })

      }
    }

    // phone Number

    if (this.phoneNumberUpdateForm.valid) {
      if (this.phoneNumberUpdateForm.controls.phoneNumber.value != this.phoneNumber || this.phoneNumberUpdateForm.controls.countryCode.value != this.countryCode) {
        const countryCodeWithOutPlus = this.phoneNumberUpdateForm.controls.countryCode.value.split('+');
        const phoneParams: any = {
          countrycode: Number(countryCodeWithOutPlus[1]),
          phonenumber: Number(this.phoneNumberUpdateForm.controls.phoneNumber.value)
        }

        this.apiservice.doesphoneexist(phoneParams).subscribe(result => {
          if (result) {
            phoneParams.userid = this.userId;
            this.callApi(phoneParams);
          } else if (!result) {
            this.phoneNumberUpdateForm.controls.phoneNumber.setErrors({ hasPhoneNumber: true });
          }
        },
          (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
            if (err.status === 401) {
              this.localservice.removeToken('user');
              this.router.navigate(['/login']);
            } else if (err.status >= 500) {
              //  this.sharedservice.gettingServerError();
            }
          })
      }

    }
  }

  changeStatus() {
    this.disableSaveButton = false;
  }
  callApi(params) {
    this.apiservice.updateuserdetails(params).subscribe(result => {
      if (result) {
        this.awaitResponse = true;
        if (params.email) {
          this.mdRef.close({
            email: this.emailUpdateForm.controls.email.value,
          });
        } else if (params.phonenumber) {
          this.mdRef.close({
            countryCode: params.countrycode,
            phoneNumber: params.phonenumber
          });
        }
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
        if (err.status === 401) {
          this.localservice.removeToken('user');
          this.router.navigate(['/login']);
        } else if (err.status >= 500) {
          //  this.sharedservice.gettingServerError();
        }
      });
  }

  closeDialog() {
    this.mdRef.close();
  }
}
