import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { DeclineReasonDialogComponent } from '../decline-reason-dialog/decline-reason-dialog.component';
import { DeclineReasonsPhotosDialogComponent } from './decline-reasons-photos-dialog/decline-reasons-photos-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FullPhotoviewDialogComponent } from './full-photoview-dialog/full-photoview-dialog.component';
@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {
  tempCaption = null;
  tempCheckForProfilePhoto = 1;
  tempTickBoxActive = -1;
  photoArr = [];
  video;
  apiKey;
  declineReasonsBySupervisor;
  declineReasonsByReviewer;
  photoCount = 0;
  btnObj = {
    tick: {
      orange: '../../../../assets/images/static/tick-orange.svg',
      white: '../../../../assets/images/static/tick-white.svg'
    },
    cross: {
      orange: '../../../../assets/images/static/cross-orange.svg',
      white: '../../../../assets/images/static/cross-white.svg'
    },
    action: {
      edit: '../../../../assets/images/static/edit.svg'
    }
  };

  isReviewer;
  unmoderatedData;
  captionEditIndex = -1;
  countUnapprovedPhotos = 0;

  constructor(private apiservice: ApiService, private sessionstorage: SessionStorageService, private sharedservice: SharedService, private dialog: MatDialog, private localservice: LocalStorageService) { }

  ngOnInit(): void {
    this.unmoderatedData = this.sessionstorage.getFromSession('user-' + this.sharedservice.userId + '-unmoderatedData');
    this.getUserPhotos(this.unmoderatedData);
    this.getUserVideo(this.unmoderatedData);
    const user = this.localservice.getFromLocal('user');
    this.sharedservice.userType.isReviewer = user.supportUserType === 'reviewer' ? true : false;
    this.isReviewer = this.sharedservice.userType.isReviewer;
    this.sharedservice.userType.name = user?.firstname;
    this.apiKey = this.sharedservice.apiKey;
  }

  changeStatus(index, status, item, type) {
    this.photoArr[index].disablePhotoApproveButton = true;
    if (this.tempCaption != null) {
      this.captionEditIndex = -1;
      item.description = this.tempCaption;
      this.tempCaption = null;
    }
    const params: any = {
      approve: status,
      caption: type === 'img' ? null : item.description,
      imageid: item.imageid,
      isphotoid: item.imageType === 'image' ? false : true,
      markasprofile: item.markasprofile ? true : false
    };
    if (!status && type === 'img') {
      params.declinereason = this.sharedservice.isReviewer ? item.reason : item.declinereason;
    }
    const action = this.unmoderatedData.userdata[0].imagedata[index];
    if (item.unmoderated && type === 'img' || ((item.descriptionunmoderated && !this.isReviewer) && type === 'caption')) {
      this.apiservice.imagemoderation(params).subscribe(res => {
        if (res) {

          if (type === 'img') {
            if (!this.isReviewer && status) {
              action.supervisorAction = status;
              this.photoArr[index].supervisorAction = status;
              action.unmoderated = null;
              action.topBtnColor = 'green';
              this.photoArr[index].topBtnColor = 'green';
              action.bottomBtnColor = 'white';
              this.photoArr[index].bottomBtnColor = 'white';
              action.moderatedBy = this.sharedservice.userType.name;
              this.photoArr[index].moderatedBy = this.sharedservice.userType.name;
              action.supervisorModerationTime = Date.now();
              this.photoArr[index].supervisorModerationTime = Date.now();
            } else if (!this.isReviewer && status === false) {
              action.supervisorAction = status;
              this.photoArr[index].supervisorAction = status;
              action.unmoderated = null;
              action.declinereason = item.declinereason;
              this.photoArr[index].declinereason = item.declinereason;
              action.topBtnColor = 'white';
              this.photoArr[index].topBtnColor = 'white';
              action.bottomBtnColor = 'red';
              this.photoArr[index].bottomBtnColor = 'red';
              action.moderatedBy = this.sharedservice.userType.name;
              this.photoArr[index].moderatedBy = this.sharedservice.userType.name;
              action.supervisorModerationTime = Date.now();
              this.photoArr[index].supervisorModerationTime = Date.now();
            } else if (this.isReviewer && status) {
              action.reviewerAction = status;
              this.photoArr[index].reviewerAction = status;
              this.photoArr[index].topBtnColor = 'purple';
              action.topBtnColor = 'purple';
              this.photoArr[index].bottomBtnColor = 'white';
              action.bottomBtnColor = 'white';
              this.photoArr[index].reviewedBy = this.sharedservice.userType.name;
              action.reviewedBy = this.sharedservice.userType.name;
              this.photoArr[index].reviewerModerationTime = Date.now();
              action.reviewerModerationTime = Date.now();
            } else {
              action.reviewerAction = status;
              this.photoArr[index].reviewerAction = status;
              action.reason = item.reason;
              this.photoArr[index].reason = item.reason;
              this.photoArr[index].topBtnColor = 'white';
              action.topBtnColor = 'white';
              this.photoArr[index].bottomBtnColor = 'gray';
              action.bottomBtnColor = 'gray';
              this.photoArr[index].reviewedBy = this.sharedservice.userType.name;
              action.reviewedBy = this.sharedservice.userType.name;
              this.photoArr[index].reviewerModerationTime = Date.now();
              action.reviewerModerationTime = Date.now();
            }
            const currenttime = Date.now();
            this.photoArr[index].photoApprovedtime = item.markasprofile ? currenttime : null;
          } else if (type === 'caption') {
            if (!this.isReviewer && status) {
              action.descriptionunmoderated = null;
              this.photoArr[index].descriptionunmoderated = null;
              this.photoArr[index].descriptionLeftBtnColor = 'green';
              action.descriptionLeftBtnColor = 'green';
              this.photoArr[index].descriptionRightBtnColor = 'white';
              action.descriptionRightBtnColor = 'white';
            } else if (!this.isReviewer && status === false) {
              action.descriptionunmoderated = null;
              this.photoArr[index].descriptionunmoderated = null;
              action.descriptionLeftBtnColor = 'white';
              this.photoArr[index].descriptionLeftBtnColor = 'white';
              action.descriptionRightBtnColor = 'red';
              this.photoArr[index].descriptionRightBtnColor = 'red';
            } else if (this.isReviewer && status) {
              this.photoArr[index].descriptionLeftBtnColor = 'purple';
              action.descriptionLeftBtnColor = 'purple';
              this.photoArr[index].descriptionRightBtnColor = 'white';
              action.descriptionRightBtnColor = 'white';
            } else {
              this.photoArr[index].descriptionLeftBtnColor = 'white';
              action.descriptionLeftBtnColor = 'white';
              this.photoArr[index].descriptionRightBtnColor = 'gray';
              action.descriptionRightBtnColor = 'gray';
            }
          }
          this.photoArr[index].disablePhotoApproveButton = false;
          action.disablePhotoApproveButton = false;
          this.unmoderatedData.userdata[0].imagedata[index] = action;
          this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', this.unmoderatedData);

        }
      });
    }
  }

  saveCaption(e, item) {
    // console.log('H');
    // item.description = e.target.innerText;
    // this.changeStatus(this.captionEditIndex, true, item, 'caption');
    // this.captionEditIndex = -1;
    this.tempCaption = e.target.innerText;
  }

  makeProfilePhoto(index, e) {
    if (this.tempCheckForProfilePhoto == 1) {
      this.tempCheckForProfilePhoto = 2;
      this.tempTickBoxActive = index;
    } else if (index != this.tempTickBoxActive) {
      this.photoArr[this.tempTickBoxActive].markasprofile = false;
      this.photoArr[index].markasprofile = e.checked;
      this.tempTickBoxActive = index;
      this.tempCheckForProfilePhoto = 2;
    }
  }
  getUserPhotos(data) {

    const userPhotosArr = data.userdata[0]?.imagedata;
    // console.log(userPhotosArr.length);

    if (userPhotosArr) {
      userPhotosArr.forEach(photos => {
        if (photos.imageid === data.userdata[0].user?.profileimage) {
          photos.markasprofile = true;
        }
        photos.disablePhotoApproveButton = false;
        // Dont act overSmart else If both Conditions are required.
        // If you think it can de done by taking first condition then read above line
        if (photos.supervisorAction && !photos.unmoderated) {
          photos.topBtnColor = 'green';
          photos.bottomBtnColor = 'white';
          photos.checkActionTaken = true;
        } else if (photos.supervisorAction === false && !photos.unmoderated) {
          photos.topBtnColor = 'white';
          photos.bottomBtnColor = 'red';
          photos.checkActionTaken = true;
        } else if (photos.reviewerAction === true && photos.unmoderated) {
          photos.topBtnColor = 'purple';
          photos.bottomBtnColor = 'white';
          photos.checkActionTaken = true;
        } else if (photos.reviewerAction === false && photos.unmoderated) {
          photos.topBtnColor = 'white';
          photos.bottomBtnColor = 'gray';
          photos.checkActionTaken = true;
        } else {
          photos.topBtnColor = 'orange';
          photos.bottomBtnColor = 'orange';
          photos.checkActionTaken = false;
        }
        /////////////////////////////////////
        // We dont have a reject Caption functionality in existing CRM
        // So here I code it if API build to reject the caption according to that
        //
        if (!photos.descriptionunmoderated && photos.description) {
          photos.descriptionLeftBtnColor = 'green';
          photos.descriptionRightBtnColor = 'white';
          // Yeah I know both condition are same
          // Condition will change when we have a API to reject the caption
        } else if (!photos.descriptionunmoderated && photos.description) {
          photos.descriptionLeftBtnColor = 'white';
          photos.descriptionRightBtnColor = 'red';
        } else if (photos.descriptionunmoderated && photos.description1) {
          photos.descriptionLeftBtnColor = 'purple';
          photos.descriptionRightBtnColor = 'white';
        } else if (photos.descriptionunmoderated && photos.description1) {
          photos.descriptionLeftBtnColor = 'white';
          photos.descriptionRightBtnColor = 'gray';
        } else {
          photos.descriptionLeftBtnColor = 'orange';
          photos.descriptionRightBtnColor = 'orange';
        }
        const photoData: any = { ...photos };
        if (photos.imagename.includes('deploma')) {
          photoData.imageType = 'deploma';
        } else if (photos.imagename.includes('photoid')) {
          photoData.imageType = 'photoid';
        } else if (photos.imagename.includes('employeeId')) {
          photoData.imageType = 'employeeId';
        } else {
          photoData.imageType = 'image';
          this.photoCount = this.photoCount + 1;
        }
        setTimeout(() => {
          const img = new Image();
          img.src = photos.imageurl;
          img.onload = function () {
            photoData.tempheight = img.height;
            photoData.tempwidth = img.width;
          };
        });
        if (photoData.topBtnColor == "orange" && photos.bottomBtnColor == "orange") {
          this.countUnapprovedPhotos++;
          this.photoArr.splice(0, 0, photoData);
        } else {
          this.photoArr.push(photoData);
        }

      });
    }

    let temp = this.countUnapprovedPhotos;
    let i = 0;
    while (temp >= 0) {
      if (this.photoArr[i]?.imageType == 'image' && this.photoArr[i]?.topBtnColor == 'orange' && this.photoArr[i]?.bottomBtnColor == 'orange') {
        const ele = this.photoArr.splice(i, 1);
        // ele = [{obj1}]  array of 1 length.
        this.photoArr.splice(0, 0, ele[0]);
      }
      i++;
      temp--;
    }
    this.unmoderatedData.userdata[0].imagedata = this.photoArr;
    this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', this.unmoderatedData);
  }

  changeMind(e, item) {
    e.target.innerText = item.description;
    this.captionEditIndex = -1;
  }

  getUserVideo(data) {
    this.video = data.userdata[0]?.videodata[0];
    if (this.video) {
      this.video.disabledselfieVideoButton = false;
      if (this.video.supervisorAction && !this.video.unmoderated) {
        this.video.topBtnColor = 'green';
        this.video.bottomBtnColor = 'white';
      } else if (this.video.supervisorAction === false && !this.video.unmoderated) {
        this.video.topBtnColor = 'white';
        this.video.bottomBtnColor = 'red';
      } else if (this.video.reviewerAction === true && this.video.unmoderated) {
        this.video.topBtnColor = 'purple';
        this.video.bottomBtnColor = 'white';
      } else if (this.video.reviewerAction === false && this.video.unmoderated) {
        this.video.topBtnColor = 'white';
        this.video.bottomBtnColor = 'gray';
      } else {
        this.video.topBtnColor = 'orange';
        this.video.bottomBtnColor = 'orange';
      }
      this.declineReasonsByReviewer = this.video.reason;
      this.declineReasonsBySupervisor = this.video.declinereason;
    }

    // console.log(this.video);
    // console.log(this.declineReasonsBySupervisor);
  }

  userVideoAction(status) {
    this.video.disabledselfieVideoButton = true;
    const params = {
      approve: status,
      declinereason: null,
      userid: this.sharedservice.userId
    };
    params.declinereason = status ? null : this.sharedservice.isReviewer ? this.declineReasonsByReviewer : this.declineReasonsBySupervisor;
    const action = this.unmoderatedData.userdata[0]?.videodata[0];
    if (action.unmoderated) {
      this.apiservice.videoModeration(params).subscribe(res => {
        if (!this.isReviewer && status) {
          action.supervisorAction = status;
          action.unmoderated = null;
          this.video.topBtnColor = 'green';
          this.video.bottomBtnColor = 'white';
          this.video.moderatedBy = this.sharedservice.userType.name;
          this.sharedservice.videoverified = true;
          this.video.supervisorModerationTime = Date.now();
        } else if (!this.isReviewer && status === false) {
          action.supervisorAction = status;
          action.unmoderated = null;
          action.declinereason = this.declineReasonsBySupervisor;
          this.video.topBtnColor = 'white';
          this.video.bottomBtnColor = 'red';
          this.video.moderatedBy = this.sharedservice.userType.name;
          this.video.supervisorModerationTime = Date.now();
        } else if (this.isReviewer && status) {
          action.reviewerAction = status;
          this.video.topBtnColor = 'purple';
          this.video.bottomBtnColor = 'white';
          this.video.reviewedBy = this.sharedservice.userType.name;
          this.video.reviewerModerationTime = Date.now();
          this.sharedservice.videoverified = true;
        } else {
          action.reviewerAction = status;
          this.video.topBtnColor = 'white';
          this.video.bottomBtnColor = 'gray';
          this.video.reviewedBy = this.sharedservice.userType.name;
          action.reason = this.declineReasonsByReviewer;
          this.video.reviewerModerationTime = Date.now();
        }
        this.video.disabledselfieVideoButton = false;

        this.sessionstorage.setToSession('user-' + this.sharedservice.userId + '-unmoderatedData', this.unmoderatedData);
      });
    }
  }

  declineDialog(param) {
    const action = this.unmoderatedData.userdata[0]?.videodata[0];
    if (action.unmoderated || param) {
      const dialog = this.dialog.open(DeclineReasonDialogComponent, {
        autoFocus: false,
        maxHeight: '90vh',
        maxWidth: '50vw',
        data: { videoData: this.unmoderatedData.userdata[0]?.videodata[0], readmore: param }
      });

      dialog.afterClosed().subscribe(result => {
        if (result) {
          if (!this.sharedservice.isReviewer) {
            this.declineReasonsBySupervisor = result;
          } else {
            this.declineReasonsByReviewer = result;
          }
          this.userVideoAction(false);
        }
      });
    }
  }

  openPhotoDeclineDialog(index, status, item, type, openD) {
    if (openD || item.unmoderated) {
      const dialog = this.dialog.open(DeclineReasonsPhotosDialogComponent, {
        autoFocus: false,
        maxHeight: '90vh',
        maxWidth: '50vw',
        data: { data: item, readmore: openD }
      });

      dialog.afterClosed().subscribe(result => {
        if (result) {
          if (!this.sharedservice.isReviewer) {
            item.declinereason = result;
          } else {
            item.reason = result;
            // console.log(item);
          }
          // console.log(item);
          this.changeStatus(index, status, item, type);
          // console.log(result);
          // this.userVideoAction(false);
        }
      });
    }
  }

  openFullViewImage(imgUrl) {
    //console.log("dailog open");

    const photoGuideDialog = this.dialog.open(FullPhotoviewDialogComponent, {
      panelClass: 'dialog-container-photoview',
      height: '100vh',
      width: '100vw',
      autoFocus: false,
      data: { image: imgUrl },
      backdropClass: 'dialog-bg-trans'
    });

    photoGuideDialog.afterClosed().subscribe(result => {
      if (result) {
        // console.log('successful');
      }
    });



  }


}
