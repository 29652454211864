import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-decline-reason-dialog',
  templateUrl: './decline-reason-dialog.component.html',
  styleUrls: ['./decline-reason-dialog.component.css']
})
export class DeclineReasonDialogComponent implements OnInit {
  selectedReasons = {};
  declineInputReason = new UntypedFormControl();
  showDeclineReasons;
  selDeclineReasonsBySupervisor;
  selDeclineReasonsByReviewer;
  supervisor;
  reviewer;
  declineReasonsArr = [];
  constructor(private dataservice: DataService, public dialogRef: MatDialogRef<DeclineReasonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sharedservice: SharedService) { }

  ngOnInit(): void {
    //console.log(this.data.videoData);
    this.supervisor = this.data.videoData.moderatedBy;
    this.reviewer = this.data.videoData.reviewedBy;
    // tslint:disable-next-line: max-line-length
    this.showDeclineReasons = this.sharedservice.isReviewer && this.data.videoData.unmoderated && this.data.videoData.reviewerAction === null ? true : this.data.videoData.unmoderated && !this.sharedservice.isReviewer && !this.data.readmore ? true : false;
    this.selDeclineReasonsBySupervisor = this.data.videoData.declinereason?.split('; ');
    this.selDeclineReasonsByReviewer = this.data.videoData.reason?.split('; ');
    //console.log(this.selDeclineReasonsByReviewer);
    // this.declineReasonsArr.forEach(element => {
    //   if (declineArr.includes(element.reason)) {
    //     element.isActive = true;
    //   }
    // });
    this.dataservice.getAllDeclineReason().subscribe((res: any) => {
      this.declineReasonsArr = res.videodeclineReason;
    });
  }

  selDeclineReason(e, item, i) {
    if (e.checked) {
      this.selectedReasons[i] = item.reason;
    } else {
      delete this.selectedReasons[i];
    }
    console.log(this.selectedReasons);
  }

  sendDataBack() {
    let reasons = null;
    for (const key in this.selectedReasons) {
      if (!reasons) {
        reasons = this.selectedReasons[key];
      } else {
        reasons += '; ' + this.selectedReasons[key];
      }
    }
    console.log(this.declineInputReason);
    if (this.declineInputReason.value) {
      if (reasons) {
        reasons += '; ' + this.declineInputReason.value;
      } else {
        reasons = this.declineInputReason.value;
      }
    }
    this.dialogRef.close(reasons);
  }

}
