import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { async } from 'rxjs/internal/scheduler/async';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-decline-reasons-photos-dialog',
  templateUrl: './decline-reasons-photos-dialog.component.html',
  styleUrls: ['./decline-reasons-photos-dialog.component.css']
})
export class DeclineReasonsPhotosDialogComponent implements OnInit {
  declineReasonsArr = [];
  declinePhotoIdReasonsArr = [];
  declineInputReason = new UntypedFormControl();
  selectedReasons = {};
  phtoDescription;
  selDeclineReasonsBySupervisor;
  selDeclineReasonsByReviewer;
  showReasons;
  disableDeclineButton = false;
  constructor(private dataservice: DataService, public dialogRef: MatDialogRef<DeclineReasonsPhotosDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private sharedservice: SharedService) { }

  ngOnInit(): void {
    console.log(this.data.data);
    this.phtoDescription = this.data.data;
    this.dataservice.getAllDeclineReason().subscribe((res: any) => {
      this.declineReasonsArr = res.photodeclineReason;
      this.declinePhotoIdReasonsArr = res.photoIDdeclineReason;

      if (this.phtoDescription.imageType == 'deploma') {
        const regex = /photo ID/gi;

        this.declinePhotoIdReasonsArr.forEach((obj) => {
          obj.reason = obj.reason.replace(regex, 'university diploma');
        })

      } else if (this.phtoDescription.imageType == 'employeeId') {
        const regex = /photo ID/gi;

        this.declinePhotoIdReasonsArr.forEach((obj) => {
          obj.reason = obj.reason.replace(regex, 'employee ID');
        })

      }
    });
    this.declineInputReason.valueChanges.subscribe(x => {
      if (x.length > 0) {
        this.disableDeclineButton = true;
      } else {
        this.disableDeclineButton = false;
      }

    });


    // this.declinePhotoIdReasonsArr.forEach((obj) =>{
    //   if(obj.isActive === false){
    //     this.disableDeclineButton = true;
    //   }else{
    //     this.disableDeclineButton = false;
    //   }
    // })

    this.selDeclineReasonsBySupervisor = this.phtoDescription.declinereason?.split('; ');
    this.selDeclineReasonsByReviewer = this.phtoDescription.reason?.split('; ');
    // tslint:disable-next-line: max-line-length
    this.showReasons = this.sharedservice.isReviewer && this.phtoDescription.unmoderated && this.phtoDescription.reviewerAction === null ? true : this.phtoDescription.unmoderated && !this.sharedservice.isReviewer && !this.data.readmore ? true : false;
  }

  selDeclineReasonsPhoto(e, item, i) {

    if (e.checked) {
      this.disableDeclineButton = true;
      this.selectedReasons[i] = item.reason;
    } else {
      delete this.selectedReasons[i];
      if (Object.keys(this.selectedReasons).length === 0) {
        this.disableDeclineButton = false;
      }
    }

    // console.log(this.selectedReasons);
  }

  sendDataBack() {
    let reasons = null;
    console.log(this.selectedReasons);
    for (const key in this.selectedReasons) {
      if (!reasons) {
        reasons = this.selectedReasons[key];
      } else {
        reasons += '; ' + this.selectedReasons[key];
      }
    }
    // console.log(this.declineInputReason);
    if (this.declineInputReason.value) {
      if (reasons) {
        reasons += '; ' + this.declineInputReason.value;
      } else {
        reasons = this.declineInputReason.value;
      }
    }
    this.dialogRef.close(reasons);
  }
}
