import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getUrl(filename: any) {
    return '../../assets/json/' + filename;
  }

  getAllCountriesCodes() {
    return this.http.get(this.getUrl('country-code.json'));
  }

  getAllEditLogs() {
    return this.http.get(this.getUrl('edit-logs.json'));
  }

  getAllEmailTemplate() {
    return this.http.get(this.getUrl('emailTemplate.json'));
  }
  getAllDeclineReason() {
    return this.http.get(this.getUrl('decline-reasons.json'));
  }
}
