<div class="row mx-0 justify-content-center" style="background-color: #f2f2f2; height: 42rem;">
    <div class="col-12 px-0 subheading mt-4">
        <div style="margin-left: 10%;">
            <span class="clickable" style="color: #a369e8;" (click)="callBack()">Team Settings </span><span
                class="ml-3 mr-2 fa fa-angle-right c-graycolor"> </span><span> {{userType}}</span>
        </div>
    </div>
    <div class="col-6 px-0 bg-white mt-5" style="margin-bottom: 15%;">
        <div class="row mx-0 justify-content-center">
            <div class="col-11 px-0 ">
                <form [formGroup]="addUser" (ngSubmit)="onSubmit()">
                    <div class="row mx-0 justify-content-between">
                        <div class="col-12 px-0">
                            <div class="oswald c-darkgray j-h3 mt-3">Agent details</div>
                        </div>

                        <!-- name -->
                        <div class="col-5 px-0 mt-5">
                            <div class="oswald c-darkgray subheading">Name</div>
                            <input class="mt-2 w-100 p-1 border-element" type="text" placeholder="Name"
                                formControlName="name">

                            <mat-error *ngIf="addUser.get('name').hasError('required') && addUser.get('name').touched"
                                class=" mt-1 c-alert-danger mat-error">
                                <span class=""></span>Name is required
                            </mat-error>
                        </div>
                        <div class="col-5 px-0 mt-5">
                            <div class="oswald c-darkgray subheading">Mobile number</div>
                            <input class="mt-2 w-100 p-1 border-element" type="number" placeholder="Mobile Number"
                                formControlName="phone">
                            <mat-error *ngIf="addUser.get('phone').hasError('required') && addUser.get('phone').touched"
                                class=" mt-1 c-alert-danger mat-error">
                                <span class=""></span>Mobile number is required
                            </mat-error>
                        </div>
                        <div class="col-5 px-0 mt-4">
                            <div class="oswald c-darkgray subheading">Email</div>
                            <input class="mt-2 w-100 p-1 border-element" type="email" placeholder="Email"
                                formControlName="email">
                            <mat-error *ngIf="addUser.get('email').hasError('required') && addUser.get('email').touched"
                                class=" mt-1 c-alert-danger mat-error">
                                <span class=""></span>Email is required
                            </mat-error>
                        </div>
                        <div class="col-5 px-0 mt-4">
                            <div class="oswald c-darkgray subheading">Password</div>
                            <input [type]="eyeVisible? 'text': 'password'" class="mt-2 w-100 p-1 border-element"
                                type="password" placeholder="Password (atleast 8 characters)"
                                formControlName="password">
                            <div class="clickable eyeClass" (click)="eyeVisible = !eyeVisible">
                                <img *ngIf="eyeVisible" src="/assets/images/static/green-signUp-open-eye-icon.svg"
                                    alt="">
                                <img *ngIf="!eyeVisible" src="/assets/images/static/gray-signUp-close-eye-icon.svg"
                                    alt="">
                            </div>
                            <mat-error
                                *ngIf="addUser.get('password').hasError('required') && addUser.get('password').touched && !isEditProfile"
                                class=" mt-1 c-alert-danger mat-error">
                                <span class=""></span>Password is required
                            </mat-error>

                        </div>
                        <div class="col-5 px-0 mt-4">
                            <div class="oswald c-darkgray subheading">Role</div>
                            <!-- <input matInput class="mt-2 w-100" type="text" placeholder="Role" formControlName="role" [value]="selectedValue">  -->

                            <div class="c-darkgray account-detail-answer">
                                <select class="border-element w-100 mt-2 p-1" formControlName="role">
                                    <option value="" disabled selected>Select</option>
                                    <option [value]="item.position" *ngFor="let item of roles">{{item.position}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="addUser.get('role').hasError('required') && addUser.get('role').touched"
                                    class=" mt-1 c-alert-danger mat-error">
                                    <span class=""></span>Role is need to selected
                                </mat-error>
                            </div>
                        </div>

                        <div class="col-12 px-0 text-center mt-5 mb-3">
                            <div *ngIf="!isEditProfile">
                                <button mat-stroked-button color="primary" class="mt-4 mr-2" style="border: none;"
                                    (click)="onCancel()">CANCEL</button>
                                <button mat-raised-button class="w-50 c-white mt-4 small-text"
                                    [disabled]="!addUser.valid"
                                    [ngClass]="!addUser.valid ? 'btn-bg-disabled' : 'bg-btn-primary'">ADD
                                    MEMBER</button>
                            </div>

                            <div *ngIf="isEditProfile">
                                <button mat-stroked-button color="primary" class="mt-4" style="border: none;"
                                    (click)="onCancel()">CANCEL</button>
                                <button mat-raised-button
                                    class="w-25 c-white mt-4 small-text bg-btn-primary">SAVE</button>
                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>
