<div class="row mx-0">
    <!-- Moderation Info -->
    <div class="col-12 px-0 text-left box-shadow-section">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#moderation"
                (click)=" moderationPanelOpen = !moderationPanelOpen">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20"
                        class="transitionClass"
                        [ngStyle]="{'transform': moderationPanelOpen ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Moderation Info
                </div>
            </div>
            <div class="col-12 py-2 opensans-light collapse show" id="moderation">
                <div class="row mx-0">


                    <!-- first name -->
                    <div class="col-12 px-0 my-3">
                        <div class="oswald c-darkgray body-subtext mb-2">First name <span
                                *ngIf="moderationFirstname.singleTimemoderation == null"
                                class="small-text clickable opensans-light"
                                style="color: #1b75bb;text-decoration: underline;" (click)="goToEditTab()"> <i>(edit
                                    history)</i></span> </div>
                        <div class="row mx-0">
                            <div class="col-7 px-0 my-1">
                                <div class="c-darkergray body-subtext"
                                    [ngClass]="{'edit-university-company-class': editFirstName}"
                                    [attr.contenteditable]="editFirstName" #firstname>
                                    {{moderationFirstname.userFirstName ? moderationFirstname.userFirstName : 'N.A.'}}
                                </div>
                                <div *ngIf="firstnameLenghtExceed" class="mb-2 c-alert-danger smaller-text">
                                    <span class="fa fa-exclamation-triangle"></span> Please stay within the characters
                                    limit(20 max).
                                </div>
                            </div>
                            <div class="col-5 px-0" *ngIf="moderationFirstname.userFirstName != 'N.A.'">
                                <div class="row mx-0 align-items-center">
                                    <div class="col-3 px-1">
                                        <button *ngIf="!isReviewer" mat-icon-button matTooltip="Edit"
                                            matTooltipClass="toolTipBgColor"
                                            class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.edit" height="25" width="25"
                                                alt="edit-btn" (click)="editFirstNameFunction()">
                                        </button>
                                    </div>
                                    <div class="col-6 px-1">
                                        <button *ngIf="!isReviewer" matRipple class="w-100 pb-1"
                                            [disabled]="moderationFirstname.firstNameButtonDisable"
                                            (click)="moderateFirstName(true)"
                                            [ngClass]="[moderationFirstname.leftBtnColor, 'moderation-word-bg-'+moderationFirstname.leftBtnColor]">
                                            <img *ngIf="moderationFirstname.leftBtnColor !== 'green'"
                                                [src]="moderationFirstname.leftBtnColor === 'green'  ? btnObj.tick.orange : btnObj.tick.white"
                                                alt="btn-tick" height="15" width="15">
                                            <span *ngIf="moderationFirstname.leftBtnColor === 'green'"
                                                matTooltip="APPROVED" matTooltipClass="toolTipBgColor"
                                                class="c-white small-text position-relative d-block collegeBtnText">
                                                APPROVED
                                            </span>
                                        </button>
                                    </div>
                                    <div class="col-3 px-1 text-center">
                                        <button *ngIf="moderationFirstname.valueAcceptedBy"
                                            matTooltipClass="toolTipInfoBgColor"
                                            [title]="parsedText(moderationFirstname.valueAcceptedBy)" mat-icon-button
                                            class="border-0 bg-white rounded-circle position-relative btn-container">
                                            <img class="center-box" [src]="btnObj.info" height="25" width="25"
                                                alt="edit-btn">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    <!--Words that best describe - -->
                    <div class="col-12 px-0">
                        <div class="oswald c-darkgray body-subtext my-3">
                            Words that best describe
                        </div>
                        <div class="d-flex"
                            *ngIf="moderationData.moreAboutMyself.wordsDescribeMe.length > 0; else noWordsDescribeMe">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutMyself.wordsDescribeMe">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor"
                                            class="moderation-icon-container position-relative moderation-icon-tick clickable"
                                            [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span
                                            class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}"
                                            [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior"
                                            class="moderation-icon-container position-relative moderation-icon-cross clickable"
                                            [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noWordsDescribeMe>
                            <div class="small-text c-darkgray mb-2" style="width: 100%;">N.A.</div>
                        </ng-template>
                    </div>

                    <!--I am looking for someone who is  -->
                    <div class="col-12 px-0">
                        <div class="oswald c-darkgray body-subtext my-3">
                            I am looking for someone who is
                        </div>
                        <div class="d-flex"
                            *ngIf=" moderationData.moreAboutPatner.lookingFor.length>0; else noLookingFor">
                            <ul class="list-inline" style="width: 90%;">
                                <ng-container *ngFor="let item of moderationData.moreAboutPatner.lookingFor">
                                    <li class="list-inline-item mb-2" *ngIf="item.customized; else notCustomized">
                                        <span *ngIf="!item.approvedBySupervisor"
                                            class="moderation-icon-container position-relative moderation-icon-tick clickable"
                                            [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.tick[item.color]" alt="tick-icon"
                                                height="15" width="15">
                                        </span>
                                        <span
                                            class="small-text c-darkgray moderation-word moderation-word-bg-{{item.color}}"
                                            [ngClass]="{'moderation-word-red': item.color==='red', 'moderation-word-green': item.color==='green' }">
                                            {{item.name}}
                                        </span>
                                        <span *ngIf="!item.declineBySupervisior"
                                            class="moderation-icon-container position-relative moderation-icon-cross clickable"
                                            [ngClass]="item.color">
                                            <img class="center-box" [src]="btnObj.cross[item.color]" alt="cross-icon"
                                                height="12" width="12">
                                        </span>
                                    </li>
                                    <ng-template #notCustomized>
                                        <li class="small-text c-darkgray list-inline-item mb-2">#{{item.name}}</li>
                                    </ng-template>
                                </ng-container>
                            </ul>
                            <!-- <div class="" style="width: 10%;">
                                <button mat-icon-button class="border-0 bg-white rounded-circle  position-relative btn-container">
                                    <img class="center-box" [src]="btnObj.info" height="25" width="25" alt="edit-btn">
                                </button>
                            </div> -->
                        </div>
                        <ng-template #noLookingFor>
                            <div class="small-text c-darkgray mb-2" style="width: 100%">N.A.</div>
                        </ng-template>
                    </div>


                </div>
            </div>
        </div>
    </div>
    
    <!-- Reported Concerns -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse" data-target="#reportConcern"
                (click)="getReportedConcerns(activated, '1')">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20"
                        class="transitionClass"
                        [ngStyle]="{'transform': reportConcern ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Reported Concerns
                </div>
            </div>

            <div class="col-12 px-0 text-center body-text py-2" *ngIf="reportConcern">
                <div class="row mx-0 border-button opensans-regular clickable">
                    <div matRipple [ngClass]="activated === '1'? 'active':'not-active'" class="col-6 py-2"
                        (click)="activationOnTab('others')" (click)="getReportedConcerns(activated, '2')"
                        style="border-bottom :3px solid #cacaca" id="f-button">
                        BY OTHERS
                    </div>
                    <div matRipple [ngClass]="activated === '2'? 'active':'not-active'" class="col-6 py-2"
                        (click)="activationOnTab('me')" style="border-bottom : 3px solid #cacaca" id="s-button"
                        (click)="getReportedConcerns(activated, '2')">
                        BY ME
                    </div>
                    <div class="slider col-12 px-0"></div>
                </div>
            </div>
            <div class="col-12 px-4 c-darkgray pt-2 pb-3" style="max-height: 850px; overflow-y: auto;"
                *ngIf="reportConcern && activated === '1'">
                <div class="row mx-0 mb-4" *ngFor="let item of reportListDataByOthers">
                    <div class="col-12 px-0">
                        <span class="oswald subheading">
                            {{item.alias}}
                        </span>
                        <span class="body-subtext opensans-light pl-3">#{{item.reportedId}}</span>
                        <span class="body-subtext opensans-light pl-3">{{item.age}} years | {{item.location}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-regular body-subtext">
                        {{item.day}}, {{item.time | date: 'mediumDate'}} {{item.time | date: 'mediumTime'}}
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-light body-subtext" style="word-wrap: break-word;">
                        <span *ngFor="let item of item.reasonSplit; let i = index">
                            {{item}}<br>
                        </span>
                    </div>
                </div>
                <div class="row mx-0" *ngIf="reportListDataByOthers.length == 0">
                    <div class="col-12 px-0 text-center pt-3 oswald subheading">
                        Data not available
                    </div>
                </div>
                <div class="row mx-0" *ngIf="showMoreForByOther">
                    <!-- by other -->
                    <div class="col-12 px-0 text-center">
                        <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData('1', '2')">LOAD
                            MORE</button>
                    </div>
                </div>
            </div>
            <div class="col-12 px-4 pt-2 pb-3" style="max-height: 850px; overflow-y: auto;"
                *ngIf="reportConcern && activated === '2'">
                <div class="row mx-0 mb-4" *ngFor="let item of reportListDataByME">
                    <div class="col-12 px-0">
                        <span class="oswald subheading clickable nodeid-color"
                            (click)="navigateToProfileView(item.reportedOnUser)">#{{item.reportedOnUser}}</span>
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-regular body-subtext">
                        {{item.day}}, {{item.time | date: 'mediumDate'}} {{item.time | date: 'mediumTime'}}
                    </div>
                    <div class="col-12 px-0 pt-2 opensans-light body-subtext" style="word-wrap: break-word;">
                        <span *ngFor="let item of item.reasonSplit; let i = index">
                            {{item}}<br>
                        </span>
                    </div>
                </div>
                <div class="row mx-0" *ngIf="reportListDataByME.length == 0">
                    <div class="col-12 px-0 text-center pt-3 oswald subheading">
                        Data not available
                    </div>
                </div>
                <div class="row mx-0" *ngIf="showMoreForByMe">
                    <div class="col-12 px-0 text-center">
                        <button class="showMoreButtonBgColor" mat-button (click)="loadMoreData(activated, '2')">LOAD
                            MORE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- npsRating -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 box-shadow-section py-3 clickable" data-toggle="collapse"
                data-target="#ratingGivenByUser" (click)="getNPSrating()">
                <div class="float-right">
                    <img [src]="btnObj.directionBtn" alt="direction-arrow" height="20" width="20"
                        class="transitionClass"
                        [ngStyle]="{'transform': ratingGivenByUser ? 'rotate(90deg)' : 'rotate(0deg)'}">
                </div>
                <div class="oswald c-darkgray subheading text-left">
                    Net Promoter Score
                </div>
            </div>
            <div class="col-12 py-2 opensans-light collapse" id="ratingGivenByUser">
                <ng-container *ngIf="ratings.length == 0">
                    <div class="col-12 py-2 px-0 opensans-regular c-darkgray body-text text-center">
                        Question not answered.
                    </div>
                </ng-container>
                <ng-container *ngFor="let item of ratings; let last = last">
                    <div class="row mx-0 py-1">
                        <div class="col-12 px-0 pb-1">
                            <span class="opensans-regular c-darkgray body-subtext">
                                {{item.rating}}
                            </span>
                            <span class="float-right small-text opensans-regular c-darkgray">
                                {{item.timestamp | date: 'medium'}}
                            </span>
                            <div class="c-darkgray body-subtext opensans-light" style="word-wrap: break-word;">
                                {{item.reason}}
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
        </div>
    </div>

    <!-- Basics -->
    <div class="col-12 px-0 text-left box-shadow-section my-2">
        <div class="row mx-0">
            <div class="col-12 text-left my-3">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <div class="float-right">
                            <button mat-stroked-button class="edit-button"
                                (click)="navigateToUserview('basics')">EDIT</button>
                        </div>
                        <div class="oswald c-darkgray subheading text-left">
                            Profile info
                        </div>
                    </div>
                    <ng-container *ngFor="let item of userData.basics; let i = index">
                        <div class="col-6 px-0 mt-3"
                            *ngIf="item.label != 'Words that best describe me' && item.label != 'My hobbies/Interests'">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-graycolor small-text opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkgray body-subtext opensans-light mt-1"
                                        *ngIf="item.label != 'Languages & fluency'">
                                        {{(dateFlag === item.info || visaFlag === item.info) ? (item.info | date: 'MMM,
                                        yyy') : item.info}}
                                    </div>
                                    <div class="c-darkgray body-subtext opensans-light mt-1"
                                        *ngIf="item.label == 'Languages & fluency'">
                                        <div *ngIf="item.info == 'N.A.'">
                                            {{item.info}}
                                        </div>
                                        <div *ngIf="item.info != 'N.A.'">
                                            <div *ngFor="let infoItem of item.info">
                                                {{infoItem}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="col-12 px-0" *ngIf="i == (userData.basics.length - 2)"></div>
                        <div class="col-6 px-0 mt-3"
                            *ngIf="item.label == 'Words that best describe me' || item.label == 'My hobbies/Interests'">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <img [src]="item.icon" alt="icon" height="25" width="25">
                                </div>
                                <div class="">
                                    <div class="c-graycolor small-text opensans-light">
                                        {{item.label}}
                                    </div>
                                    <div class="c-darkergray opensans-light mt-1">
                                        <ul class="list-inline" *ngIf="item.info?.length > 0; else noTrait">
                                            <ng-container *ngFor="let item of item.info">
                                                <li
                                                    class="list-inline-item traits c-darkgray body-subtext opensans-light">
                                                    {{item}}
                                                </li>
                                            </ng-container>
                                        </ul>
                                        <ng-template #noTrait>
                                            <div class="small-text c-darkgray mb-2">N.A.</div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>