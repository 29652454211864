<div class="row mx-0" style="background-color: #f2f2f2;">
    <div class="col-12 text-center px-0 opensans-light">
        <div class="row mx-0 my-3" *ngFor="let user of userData; let userIndex = index">
            <!-- <ng-container *ngFor="let user of userData; let userIndex = index">
                <div class="col-12 my-2 bg-white box-shadow-section px-0">
                    <div class="row mx-0">
                        <!-- User Image --
                        <div class="" style="width: 52%;">
                            <div class="row mx-0">
                                <div class="col-5 px-0">
                                    <div class="position-absolute mt-2" style="right: 0;">
                                        <img [src]="user.ismale ? '../../../../assets/images/static/match-female.svg' : '../../../../assets/images/static/match-male.svg'"
                                            alt="user-icon" height="30" width="30">
                                    </div>
                                    <div class="plan-triangle">
                                        <img class="plan-icon" [src]="user.btnObj.planIcons[2]" alt="icon" height="25"
                                            width="25">
                                        <div class="c-darkergray plan-date">12 Dec 2020</div>
                                    </div>
                                    <div class="w-100 text-center">
                                        <div class="position-relative mx-auto" style="height: 100px;width:100px;">
                                            <img [src]="user.profileImage" height="100" width="100"
                                                class="rounded-circle" alt="profileImage">
                                            <img class="photo-counter-match-img"
                                                src="../../../../assets/images/static/match-photo-counter.svg"
                                                height="15" width="15" alt="photo-counter">
                                            <span
                                                class="position-absolute d-inline-block c-white caption photo-counter-match-text">
                                                {{user.photoCounter}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-100 text-center">
                                        <ul class="list-inline py-1 m-0">
                                            <li *ngFor="let item of user.verifiedIconsArr" class="list-inline-item"
                                                [matTooltip]="item.toolTipText" matTooltipClass="toolTipBgColor"
                                                [ngClass]="{'mx-1': item.verified}">
                                                <span class="d-inline-block" *ngIf="!item.verified">
                                                    <img height="15" width="15" [src]="item.icon" alt="verified-icon">
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="mt-2">
                                        <span class="d-inline-block activity-label smaller-text"
                                            [ngStyle]="{'background-color': user.activityStatus.color}">
                                            {{user.activityStatus.label}}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-7 px-0">
                                    <div class="row mx-0">
                                        <div class="col-12 px-0 mt-3">
                                            <div class="c-darkergray body-subtext oswald">
                                                {{user.alias}}
                                                <span
                                                    class="c-darkgray small-text opensans-light">{{'#'+user.userId}}</span>
                                                <span class="d-inline-block position-absolute" style="right: 0;"
                                                    *ngIf="user.btnObj.profileModeration[0].value">
                                                    <img [src]="user.btnObj.profileModeration[0].icon" height="15"
                                                        width="15" alt="moderation-icon">
                                                </span>
                                            </div>
                                            <div class="c-darkgray small-text my-1">
                                                Pankaj Arora
                                            </div>
                                            <div class="c-darkgray small-text my-1">
                                                Vivek Arora – Son
                                            </div>
                                            <ul class="list-inline my-1">
                                                <li class="list-inline-item"
                                                    *ngFor="let item of user.btnObj.profileQuality;let i = index">
                                                    <span class="">
                                                        <img *ngIf="i<3" [src]="item.icon" alt="icon" height="15"
                                                            width="15">
                                                        <img *ngIf="i===3" [src]="item.icon" alt="icon" height="25"
                                                            width="30">
                                                    </span>
                                                    <span class="c-darkergray caption" *ngIf="i%2===0">
                                                        {{item.value}}
                                                    </span>
                                                    <img *ngIf="i%2===1" [src]="item.value" height="15" width="15"
                                                        alt="icon">
                                                </li>
                                            </ul>
                                            <div class="w-100 text-left px-0">
                                                <button matRipple class="border-0">
                                                    <img src="../../../../assets/images/static/interaction-history-blue.svg"
                                                        alt="icon" height="20" width="20">
                                                    <span class="small-text" style="color: #a369e8;">
                                                        Interaction history
                                                    </span>
                                                </button>
                                            </div>
                                            <ul class="list-inline my-1">
                                                <li class="list-inline-item"
                                                    *ngFor="let item of user.userStats[0].user;let i = index">
                                                    <span class="">
                                                        <img [src]="item.icon" alt="icon" height="15" width="15">
                                                    </span>
                                                    <!-- <span class="c-darkergray caption">
                                                        {{item.value}}
                                                    </span> --
                                                </li>
                                            </ul>
                                            <ul class="list-inline my-1">
                                                <li class="list-inline-item"
                                                    *ngFor="let item of user.userStats[1].other;let i = index">
                                                    <span class="">
                                                        <img [src]="item.icon" alt="icon" height="15" width="15">
                                                    </span>
                                                    <!-- <span class="c-darkergray caption">
                                                        {{item.value}}
                                                    </span> --
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Basic Info --
                        <div class="" style="width: 40%;">
                            <div class="row mx-0">
                                <ng-container *ngFor="let item of user.basicView">
                                    <div class="d-flex px-2 col-6 my-1" [matTooltip]="item.toolTipText"
                                        matTooltipClass="toolTipBgColor">
                                        <img [src]="item.icon" height="22" width="22" alt="icon" *ngIf="item.icon">
                                        <div class="c-darkgray small-text pl-2">
                                            {{dateFlag === item.parameter ? (item.parameter | date) : item.parameter}}<br />
                                            <span class="smaller-text c-darkergray">{{item.subparameter}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!-- Right Panel Btn --
                        <div class="text-center box-shadow-section" style="width: 8%;">
                            <ul class="list-inline mt-2">
                                <li class="clickable mt-2 py-1" matRipple (click)="userCallToAction(userIndex,i)"
                                    *ngFor="let item of user.btnObj.rightPanelIcons;let i = index">
                                    <img [src]="i === selUserActionIndex && objIndex === userIndex ? selUserActionIcon : item"
                                        alt="icon" height="25" width="25">
                                </li>
                            </ul>
                        </div>
                        <!-- User Match Action Btn --
                        <div class="col-12 px-0 bg-white">
                            <div class="row mx-0">
                                <ng-container *ngFor="let item of user.btnObj.matcheIcons">
                                    <button matRipple class="col px-0 text-left pl-1 matches-icon-class"
                                        [matTooltip]="item.toolTipText" matTooltipClass="toolTipBgColor">
                                        <img [src]="item.icon" height="25" alt="icon">
                                        <span class="pr-1 matches-icon-font-class">
                                            {{item.count}}
                                        </span>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container> -->
            <div class="col-12 px-0 c-alert-info subheading ">{{user.alias}}</div>
            <div class="col-12 px-0 ">Email: {{user.email}}</div>
            <div class="col-12 px-0">User id: {{user.userId}}</div>
            <div class="col-12 px-0">User Name: {{user.username}}</div>
            <div class="col-12 px-0">Time: {{user.timeStamp | date:'medium' }}</div>
            <div class="col-12 mt-1 py-2 px-0 text-center">
                <button mat-raised-button class="c-white bg-btn-secondary w-50"
                    (click)="viewCompleteProfile(user.userId)">View Complete Profile</button>
            </div>
        </div>
        <div class="row mx-0 pt-2 pb-3" *ngIf="showMoreButton">
            <div class="col-12 px-0 text-center">
                <button mat-button (click)="showMoreData()" style="background-color: #85952A; color:white">Show more
                </button>
            </div>
        </div>
    </div>
</div>