<div class="row mx-0 bgimg justify-content-center">
    <div class="col-12 px-0 text-center">
      <!-- logo -->
      <div class="row mx-0">
        <img src="/assets/images/meta/jodi-logo.svg" class="img-fluid logo ml-5 m-3" alt="logo">
      </div>
      <div class="row mx-0 justify-content-center">
        <div class="col-5 px-0 bg-white text-center mat-elevation-z5 form-area">
            <div class="oswald j-h2 c-darkgray pt-5">
              Log into Jodi.com.in
            </div>
            <!-- form -->
            <form class="px-4" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <input type="text" formControlName="howdidyouhear" hidden>
              <!-- email field -->
              <mat-form-field appearance="outline" class="px-3 w-100 pt-4">
                <mat-label class="c-darkgray body-subtext">Email address</mat-label>
                <input (blur)="timeStart();" class="" matInput email formControlName="email" maxlength="50" autocomplete="off" (blur)="onBlurEmailMethod()" (click)="patternCheck()" style="font-size:18px;">
                <mat-error *ngIf="loginForm.get('email').errors?.pattern && checkEmailpattern" class=" mt-1 c-alert-danger mat-error">
                  <span class="fa fa-exclamation-triangle"></span> Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="loginForm.get('email').hasError('required')" class="mt-1 c-alert-danger mat-error">
                    <span class="fa fa-exclamation-triangle"> </span> Email is required
                </mat-error>
              </mat-form-field>
  
              <!-- password field -->
              <mat-form-field appearance="outline" class="px-3 w-100">
                <mat-label class="c-darkgray body-subtext">Password</mat-label>
                <input matInput [type]="eyeVisible? 'text': 'password'" formControlName="password" maxlength="20" autocomplete="off" style="font-size:18px;">
                <mat-error *ngIf="loginForm.get('password').hasError('required')" class="mt-1 c-alert-danger mat-error">
                  <span class="fa fa-exclamation-triangle"> </span> Password is required
                </mat-error>
                <mat-error *ngIf="loginForm.get('password').hasError('minlength') && !loginForm.get('password').hasError('required')" class="mt-1 c-alert-danger mat-error">
                    <span class="fa fa-exclamation-triangle"> </span> Enter a secure password (min. 8 characters)
                </mat-error>
                <mat-error *ngIf="loginForm.get('password').hasError('maxLength') && !loginForm.get('password').hasError('required')" class="mt-1 c-alert-danger mat-error">
                    <span class="fa fa-exclamation-triangle"> </span> Max length is exceed
                </mat-error>
                <div class="clickable eyeClass " (click)="eyeVisible = !eyeVisible">
                  <img *ngIf="eyeVisible" src="/assets/images/static/green-signUp-open-eye-icon.svg" alt="">
                  <img *ngIf="!eyeVisible" src="/assets/images/static/gray-signUp-close-eye-icon.svg" alt="">
                </div>
              </mat-form-field>
  
              <!-- remember me -->
              <div class="opensans-light c-graycolor text-left px-3">
                <!-- <input type="checkbox"> Remember me for 2 weeks -->
                <mat-checkbox>Remember me for 2 weeks</mat-checkbox>
              </div>
  
              
              <div class="col-12 px-0 text-center">
                <div class="row mx-0 justify-content-center">
                  <div #recaptcha ></div>
                </div>
              </div>
              
              <div *ngIf="errorRecaptcha">
                <span class="opensans-regular" style="color:#ff0000"><i class="fa fa-exclamation-triangle" style="color:#ff0000"></i> Take this security check. Prove you are human.</span>
              </div>
              
              <mat-error *ngIf="loginForm.hasError('unauthorised')" class="text-center c-alert-danger mat-error">
                <span class="fa fa-exclamation-triangle"> </span> Invalid email address or password
              </mat-error>
  
              <!-- logIn Button -->
              <div class="px-2 pt-4">
                <button mat-raised-button class="w-50 bg-btn-primary text-white opensans-regular"  [disabled]="awaitResponse" [ngClass]="awaitResponse ? 'bg-disabled-primary' : 'bg-btn-primary'">LOG IN
                  <span>
                    <mat-spinner diameter="18" class="spinner mat-spinner-color" *ngIf="awaitResponse"></mat-spinner>
                  </span>
                </button>
              </div>
              
  
              <!-- Forgot Password -->
              <!-- <div class="text-center opensans-regular c-btn-primary pt-3">
                <span class="clickable" routerLink='/forgot-password'>FORGOT PASSWORD</span>
              </div> -->
            </form>
        </div>
      </div>
    </div>
  </div>