import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  heightData = [
    {
      id: 137,
      value: '4ft 6in – 137cm or less'
    },
    {
      id: 138,
      value: '4ft 6.5in – 138cm'
    },
    {
      id: 140,
      value: '4ft 7in – 140cm'
    },
    {
      id: 141,
      value: '4ft 7.5in – 141cm'
    },
    {
      id: 142,
      value: '4ft 8in – 142cm'
    },
    {
      id: 144,
      value: '4ft 8.5in – 144cm'
    },
    {
      id: 145,
      value: '4ft 9in – 145cm'
    },
    {
      id: 146,
      value: '4ft 9.5in – 146cm'
    },
    {
      id: 147,
      value: '4ft 10in – 147cm'
    },
    {
      id: 149,
      value: '4ft 10.5in – 149cm'
    },
    {
      id: 150,
      value: '4ft 11in – 150cm'
    },
    {
      id: 151,
      value: '4ft 11.5in – 151cm'
    },
    {
      id: 152,
      value: '5ft – 152cm'
    },
    {
      id: 154,
      value: '5ft 0.5in – 154cm'
    },
    {
      id: 155,
      value: '5ft 1in – 155cm'
    },
    {
      id: 156,
      value: '5ft 1.5in – 156cm'
    },
    {
      id: 157,
      value: '5ft 2in – 157cm'
    },
    {
      id: 159,
      value: '5ft 2.5in – 159cm'
    },
    {
      id: 160,
      value: '5ft 3in – 160cm'
    },
    {
      id: 161,
      value: '5ft 3.5in – 161cm'
    },
    {
      id: 163,
      value: '5ft 4in – 163cm'
    },
    {
      id: 164,
      value: '5ft 4.5in – 164cm'
    },
    {
      id: 165,
      value: '5ft 5in – 165cm'
    },
    {
      id: 166,
      value: '5ft 5.5in – 166cm'
    },
    {
      id: 168,
      value: '5ft 6in – 168cm'
    },
    {
      id: 169,
      value: '5ft 6.5in – 169cm'
    },
    {
      id: 170,
      value: '5ft 7in – 170cm'
    },
    {
      id: 171,
      value: '5ft 7.5in – 171cm'
    },
    {
      id: 173,
      value: '5ft 8in – 173cm'
    },
    {
      id: 174,
      value: '5ft 8.5in – 174cm'
    },
    {
      id: 175,
      value: '5ft 9in – 175cm'
    },
    {
      id: 177,
      value: '5ft 9.5in – 177cm'
    },
    {
      id: 178,
      value: '5ft 10in – 178cm'
    },
    {
      id: 179,
      value: '5ft 10.5in – 179cm'
    },
    {
      id: 180,
      value: '5ft 11in – 180cm'
    },
    {
      id: 182,
      value: '5ft 11.5in – 182cm'
    },
    {
      id: 183,
      value: '6ft – 183cm'
    },
    {
      id: 184,
      value: '6ft 0.5in – 184cm'
    },
    {
      id: 185,
      value: '6ft 1in – 185cm'
    },
    {
      id: 187,
      value: '6ft 1.5in – 187cm'
    },
    {
      id: 188,
      value: '6ft 2in – 188cm'
    },
    {
      id: 189,
      value: '6ft 2.5in – 189cm'
    },
    {
      id: 191,
      value: '6ft 3in – 191cm'
    },
    {
      id: 192,
      value: '6ft 3.5in – 192cm'
    },
    {
      id: 193,
      value: '6ft 4in – 193cm'
    },
    {
      id: 194,
      value: '6ft 4.5in – 194cm'
    },
    {
      id: 196,
      value: '6ft 5in – 196cm'
    },
    {
      id: 197,
      value: '6ft 5.5in – 197cm'
    },
    {
      id: 198,
      value: '6ft 6in – 198cm'
    },
    {
      id: 199,
      value: '6ft 6.5in – 199cm'
    },
    {
      id: 201,
      value: '6ft 7in – 201cm'
    },
    {
      id: 202,
      value: '6ft 7.5in – 202cm'
    },
    {
      id: 203,
      value: '6ft 8in – 203cm'
    },
    {
      id: 204,
      value: '6ft 8.5in – 204cm'
    },
    {
      id: 206,
      value: '6ft 9in – 206cm'
    },
    {
      id: 207,
      value: '6ft 9.5in – 207cm'
    },
    {
      id: 208,
      value: '6ft 10in – 208cm'
    },
    {
      id: 210,
      value: '6ft 10.5in – 210cm'
    },
    {
      id: 211,
      value: '6ft 11in – 211cm'
    },
    {
      id: 212,
      value: '6ft 11.5in – 212cm'
    },
    {
      id: 213,
      value: '7ft – 213cm or more'
    }
  ];
  userMatchAction = {
    Index: 0,
    count: 0
  };
  apiKey = environment.apiKey;
  videoverified = false;
  userId = 0;
  userProfileData = new Subject<any>();
  tabSwitch = new Subject<any>();
  isReviewer = false;
  userType = {
    isReviewer: false,
    name: null,
    isAdmin: false
  };
  userEditDetails: any;
  supportUserData: any;
  profileValue = {
    profileQuality: null,
    profileDiamond: null,
    profileUpgrade: null,
    profileACProspect: null
  };
  constructor(private localService: LocalStorageService, private router: Router, private apiservice: ApiService, private snackBar: MatSnackBar) { }

  userProfileInfo() {
    this.apiservice.getUserProfile({ userid: this.userId }).subscribe(res => {
      this.userProfileData.next(res);
    });
  }

  getAvatarPhoto(ismale) {
    if (ismale) {
      return '/assets/images/static/male-avatar-600x600.jpg';
    } else {
      return '/assets/images/static/Female-avatar-600x600.jpg';
    }
  }

  planExpiryInDays(remainingDays){
    const temp: any = {};
    if(remainingDays > 0){
      if(remainingDays >= 365){
        temp.year = Math.floor(remainingDays / 365);
        temp.days = remainingDays % 365;
        if(temp.days > 30){
          temp.month = Math.floor(temp.days / 30);
          temp.monthDays = temp.days % 30;
          return (temp.year + ' year ' + temp.month + ' month ' + temp.monthDays + ' days ' + ' left ');
        } else if(temp.days < 30) {
          temp.monthDays = temp.days;
          return (temp.year + ' year ' +  temp.monthDays + ' days ' + ' left');
        }

      } else if(remainingDays < 365){
        temp.month = Math.floor(remainingDays / 30);
        temp.monthDays = (remainingDays % 30);
        if(temp.month == 0){
          return ( temp.monthDays + ' days ' + ' left');
        } else {
          if(temp.monthDays == 0){
            return (temp.month + ' month ' + ' left');
          } else {
           return (temp.month + ' month ' +  temp.monthDays + ' days ' + ' left');
          }
        }
       
      }
    } else{
     return 'expired';
    } 
  }

  // for open snack bar 
  openSnackBar(message: any, duration) {
    this.snackBar.open(message, '', {
      duration: duration,
    });
  }

  // return height data on the basis of height in cm
  getConvertedHeight(data) {
    const heightIncm = this.heightData.find(e => e.id === data);
    return heightIncm.value;
  }
}
