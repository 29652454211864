import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../../loading-screen/loading-screen.component';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreateUserComponent implements OnInit {
  addUser;
  roles = [
    {
      id: 1,
      position: 'Supervisor'
    },
    {
      id: 2,
      position: 'Reviewer'
    }
  ];
  selectedValue;
  userType = '';
  userDetails;
  isEditProfile = false;
  eyeVisible = false;
  constructor(private fb: UntypedFormBuilder, private localService: LocalStorageService, private router: Router, private sharedService: SharedService, private apiService: ApiService, private md: MatDialog, private authService: AuthService) {
    this.userType = this.localService.getFromLocal('visitType');
    if (this.userType === 'Edit Agent Details') {
      this.isEditProfile = true;
    }
    this.userDetails = this.sharedService.userEditDetails;
   }

  ngOnInit(): void {
    this.addUser = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      phone: ['', [Validators.required,Validators.min(100000), Validators.max(999999999999)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9!#$%&'*/=?^_`{|}~\+\-]+(?:\.[a-zA-Z0-9!#$%&'*/=?^_`{|}~\+\-]+)*@[a-zA-Z0-9]+([\.][a-z]{2,})+(\.([a-z]{2,})+)*$")]],
      role: ['', [Validators.required]]

    });
    if (this.userDetails) {
      this.addUser.controls.name.value = this.userDetails.name;
      this.addUser.controls.email.value = this.userDetails.email;
      this.addUser.controls.phone.value = this.userDetails.phone;
      this.addUser.controls.role.value = this.userDetails.role;
      this.addUser.controls.name.disable();
      this.addUser.controls.email.disable();

    }
  }

  onCancel() {
    this.addUser.patchValue({
      name: '',
      email: '',
      phone: '',
      password: ''
    });
    this.router.navigate(['/admin-console']);

  }

  callBack() {
    this.router.navigate(['/admin-console']);
  }

  onSubmit() {
    let issupervisor = false;
    if (this.addUser.controls.role.value && this.addUser.controls.role.value === 'Supervisor') {
      issupervisor = true;
    }
    if (this.isEditProfile) {
      // if want to edit profiles 
      const params: any = {
        userid: this.userDetails.userId,
        phonenumber: this.addUser.controls.phone.value,
        issupervisor: issupervisor,
      };
      if (this.addUser.controls.password.value) {
        params.password = this.addUser.controls.password.value;
      }
      if (!this.addUser.get('phone').hasError('required')) {
        const loaderSDialog = this.md.open(LoadingScreenComponent, { disableClose: true });
        this.apiService.supportuseredit(params).subscribe(res => {
          if (res) {
            this.sharedService.supportUserData = null;
            loaderSDialog.close();
            this.router.navigate(['/admin-console']);
          }
        }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localService.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            this.authService.gettingServerError();
          }
        });
      }
    } else {
      // add new member
      if (this.addUser.valid) {
        const params = {
          firstname: this.addUser.controls.name.value,
          phonenumber: this.addUser.controls.phone.value,
          issupervisor: issupervisor,
          username: this.addUser.controls.email.value,
          password: this.addUser.controls.password.value
        };
        const loaderSDialog = this.md.open(LoadingScreenComponent, { disableClose: true });
        this.apiService.supportuseradd(params).subscribe(res => {
          if (res) {
            this.sharedService.supportUserData = null;
            loaderSDialog.close();
            this.router.navigate(['/admin-console']);
          }
        }, (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
          if (err.status === 401) {
            this.localService.removeToken('user');
            this.router.navigate(['/login']);
          } else if (err.status >= 500) {
            this.authService.gettingServerError();
          }
        });
      }
    }
  }

  ngOnDestroy() {
    // this.localService.removeToken('userEditDetails');
    this.sharedService.userEditDetails = null;
  }

}
