<div class="row mx-0 justify-content-center">
    <div class="col-12 px-0">
        <img class="float-right clickable" src="/assets/images/static/close-cross-icon.svg" alt="" (click)="onCancel()">
    </div>
    <div class="col-12 px-0 text-center">
        <div class="text-center">
            <img src="/assets/images/static/red-warning.svg" alt="" height="100" width="100">
        </div>
    </div>
    <div class="col-12 px-0 text-center mt-3">
        <div class="c-darkgray opensans-regular body-subtext">Sure you want to remove {{userName}}?</div>
    </div>
    <div class="col-12 px-0 text-center">
        <button mat-Stroked-button class="mt-4 mr-4" style="border: none; background-color: white;"
            (click)="onCancel()">CANCEL</button>
        <button mat-raised-button class="sm-btn" color="primary"
            style="border-radius: 4px !important; box-shadow: none !important; border-top: 1px solid #ff5f2c !important;"
            class="mt-4" (click)="kickedOutUser()">YES</button>

    </div>
</div>
